/** @format */

import { createAction } from "redux-starter-kit";
import axios from "axios";

export const statisticFetching = createAction("STATISTIC_FETCHING");
export const statistics = createAction("STATISTICS");
export const statisticsError = createAction("STATISTICS_ERROR");

/** fetch contacts through websocket middleware */
export const getStatistics =
  ({ lower, upper, spSerialNumber: sp_serial_number, groupBy: group_by }) =>
  async (dispatch, getState) => {
    try {
      dispatch(statisticFetching(true));
      let newLower = lower.trim().split("-");
      let newUpper = upper.trim().split("-");
      let parsedUpper = new Date(
        newUpper[2],
        newUpper[1] - 1,
        newUpper[0]
      ).getTime();
      let parsedLower = new Date(
        newLower[2],
        newLower[1] - 1,
        newLower[0]
      ).getTime();

      console.log(parsedUpper, parsedLower);
      const { data } = await axios.get("/swap-statistics", {
        params: {
          lower: parsedLower,
          upper: parsedUpper + 86400000,
          sp_serial_number,
          group_by,
        },
      });

      console.log(data);
      let response = data.data;
      dispatch(
        statistics({
          statistics: response,
          statisticsTotal: response.length,
        })
      );
      dispatch(statisticFetching(false));
    } catch (error) {
      console.log(error);
    }

    // dispatch({
    //   type: "SEND_MESSAGE",
    //   payload: {
    //     message: "getSwapStatistic",
    //     data: {
    //       lower: parsedLower,
    //       upper: parsedUpper,
    //       sp_serial_number,
    //       group_by,
    //     },
    //   },
    // });
  };

/** receive statistics through websocket middleware */
export const getSwapStatisticResponse =
  message => (dispatch /*, getState*/) => {
    dispatch(statisticFetching(false));
    dispatch(
      statistics({
        statistics: message.content,
        statisticsTotal: message.count,
      })
    );
  };
