/** @format */

import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSession, logoutUser } from "./store/actions";

//import IndexScreen from "./screens/index";
import LandingScreen from "./screens/landing";

//import { delay } from "lodash";
import AuthenticatedApp from "./screens/AuthenticatedApp";

import "./assets/styles/main.scss";
import "./assets/styles/App.scss";

import { Menu, Dropdown, Image, Button } from "semantic-ui-react";
import { Link } from "@reach/router";
import awsconfig from "./config/awsconfig";
import Footer from "./components/Footer/index.js";
import { AxiosContext, AxiosProvider } from "./context/AxiosContext.js";

global.adminLikeGroups = [
  "overall_admin",
  "Connect44",
  "Connect44-service",
  "Connect44-swapper",
  "EMI Indonesia",
];
/**
 * @component App - main entry
 *  - returns AuthenticatedApp or LandingScreen depends on session.loggedIn state
 * @param {*} props - is just a placeholder
 * @return jsx AuthenticatedApp or LandingScreen
 */
function App(props) {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);

  const [isSignedUp, setSignedUp] = useState();
  // localStorage.getItem(
  //   `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
  // )

  const [showTopBtn, setShowTopBtn] = useState();
  const [showFooter, setShowFooter] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const timeout = useRef();

  // check authentication
  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  // set delayed in timeout
  useEffect(() => {
    if (session.loggedIn) {
      if (clearTimeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    }
    return () => {
      if (clearTimeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [session]);

  // return render SplashScreen until delayed is false
  // if (delayed === true) return <SplashScreen />;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setShowTopBtn(window.scrollY > 400);
      setShowFooter(window.scrollY > window.innerHeight);
    });
    return () => {
      window.removeEventListener("scroll");
    };
  }, []);

  return (
    <AxiosProvider>
      <AxiosWrapper {...{ setIsLoading, setSignedUp }} />
      <div className="App" data-testid="app">
        {window.innerWidth <= 425 && session.loggedIn && (
          <Menu
            className="phone-main-header"
            fixed
            size="tiny"
            fluid
            color=""
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 999,
              borderRadius: "0px",
              backgroundColor: "#eee",
            }}
          >
            <Link to="/">
              <Menu.Item name="home">
                <Image
                  style={{ height: "30px", width: "auto", borderRadius: "50%" }}
                  src="https://lh6.googleusercontent.com/-HP2SPlmXAOQ/AAAAAAAAAAI/AAAAAAAAAAA/8aPLJJ2SGEE/s44-p-k-no-ns-nd/photo.jpg"
                />
              </Menu.Item>
            </Link>
            <Menu.Menu position="right">
              <Dropdown
                item
                trigger={
                  <span>
                    <Image
                      style={{ width: "30px" }}
                      circular
                      src={`https://eu.ui-avatars.com/api/?name=${session.name}&background=ff5a00&color=fff&bold=true`}
                    />
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(logoutUser());
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu>
        )}

        {isLoading ? (
          <></>
        ) : !isSignedUp ? (
          <LandingScreen />
        ) : (
          <AuthenticatedApp />
        )}
        {isSignedUp && <Footer />}

        {showTopBtn && (
          <Button
            style={{
              position: "fixed",
              bottom: 40,
              right: 20,
            }}
            basic
            icon="arrow up"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        )}
      </div>
    </AxiosProvider>
  );
}
export default App;

const AxiosWrapper = (props) => {
  const { setSignedUp, setIsLoading } = props;
  const { setAccessToken } = useContext(AxiosContext);
  useEffect(() => {
    setIsLoading(true);
    let access_token = localStorage.getItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
    );
    setSignedUp(access_token);
    if (access_token) {
      setAccessToken(access_token);
    }

    window.addEventListener("storage", () => {
      // When local storage changes, dump the list to
      // the console.
      
      let access_token = localStorage.getItem(
        `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
      );
      setAccessToken(access_token);

      setSignedUp(
        access_token
      );
      console.log("window.addEventListener", access_token);  
    });

    setIsLoading(false);
    return () => {
      window.removeEventListener("storage");
    };
  }, []);
  return <></>;
};
