import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  Button,
  Message,
  Grid,
  Segment,
  Container,
  GridColumn,
  Header,
  FormField,
  Input,
  Image,
  Form
} from "semantic-ui-react";
import "../../components/LandingComponent/forms/LoginForm/index.scss";
import Logo from "./../../assets/images/logo_swobbee.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { loginUser, requestNewPassword } from "./../../store/actions";

/** Login form is a react-hook-form for a login form with validation
 *  @returns form with username password input
 */
const LoginForm = () => {
  const dispatch = useDispatch();
  const loginError = useSelector(state => state.session.error);
  const loginMessage = useSelector(state => state.session.message);
  const loginLoading = useSelector(state => state.session.loading);
  const [forgotPassword, setForgotPassword] = useState(false);

  // conditional validation based on useState
  const validationSchema = useMemo(() => {
    if (forgotPassword) {
      return yup.object().shape({
        username: yup.string().required("Please enter a username")
      });
    } else {
      return yup.object().shape({
        username: yup.string().required("Please enter a username"),
        password: yup.string().required("Please enter a password")
      });
    }
  }, [forgotPassword]);

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = data => {
    dispatch(loginUser(data.username, data.password));
  };
  return (
    <Container
      style={{
        marginTop: "80px",
        backgroundImage: `url(${"background-login.jpg"}) !important`,
        backgroundSize: "cover !important"
      }}
    >
      <Grid columns="1">
        <GridColumn textAlign="center">
          <Image src={Logo} style={{ float: "center" }} centered />
        </GridColumn>
        <GridColumn>
          <Segment style={{ marginTop: "150px" }}>
            <Header>Login</Header>
            <div>
              {loginError && <Message error header={loginMessage} />}
              <Form
                className="ui form form--login"
                onSubmit={handleSubmit(onSubmit)}
                aria-label="login-form"
              >
                <Controller
                  control={control}
                  name="username"
                  render={({ onChange, value }) => (
                    <FormField error={errors["username"]}>
                      <label>Username</label>
                      <Input
                        name="username"
                        type="text"
                        register={register}
                        onChange={(_, { value }) => {
                          onChange(value);
                        }}
                        defaultValue={value}
                      />
                    </FormField>
                  )}
                />

                <Controller
                  control={control}
                  name="password"
                  render={({ onChange, value }) => (
                    <FormField error={errors["password"]}>
                      <label>Password</label>
                      <Input
                        name="password"
                        type="password"
                        register={register}
                        onChange={(_, { value }) => {
                          onChange(value);
                        }}
                        defaultValue={value}
                      />
                    </FormField>
                  )}
                />

                <Grid>
                  {/* <Grid.Column width="8" textAlign="left">
                    <span
                      className="forgot-password-link"
                      onClick={e => {
                        setForgotPassword(current => !current);
                      }}
                    >
                      {forgotPassword ? "back to login" : "forgot password"}
                    </span>
                  </Grid.Column> */}
                  <Grid.Column width="16" textAlign="right">
                    <Button fluid size="large" type="submit" loading={loginLoading}>
                      {forgotPassword ? "Submit" : "Login"}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          </Segment>
        </GridColumn>

      </Grid>
      {/* <Header style={{position: "fixed", bottom: 30, left: 30, color: "#aaa"}}>www.admin.swobbee.io</Header> */}
    </Container>
  );
};

export default LoginForm;
