/** @format */

import { createAction } from "redux-starter-kit";
import axios from "axios";
import awsconfig from "../../config/awsconfig";

/** sync actions */
export const batteryFetching = createAction("BATTERY_FETCHING");
export const batteries = createAction("BATTERIES");
export const batteriesError = createAction("BATTERIES_ERROR");
export const battery = createAction("BATTERY");
export const batteryError = createAction("BATTERY_ERROR");

export const batteryTypes = createAction("BATTERY_TYPES");
export const batteryTypesFetching = createAction("BATTERY_TYPES_FETCHING");

export const batteryPools = createAction("BATTERY_POOLS");
export const batteryPoolsFetching = createAction("BATTERY_POOLS_FETCHING");

// used in battery detail/show screen
export const batteryBySerialNumber = createAction("BATTERY_BY_SERIAL_NUMBER");
export const batteryBySerialNumberFetching = createAction(
  "BATTERY_BY_SERIAL_NUMBER_FETCHING"
);
export const batteryBySerialNumberError = createAction("BATTERY_DETAIL_ERROR");
export const batteryIsUpdating = createAction("BATTERY_IS_UPDATING");

export const batteryByUuid = createAction("BATTERY_BY_UUID");

/** end sync actions */
/** async actions */
/** send websocket message to get all batteries */

const axiosInstance = () => {
  let client_id = awsconfig.REACT_APP_REST_CLIENT_ID;
    let apiUrl = awsconfig.REACT_APP_PUBLIC_API;
    

    // console.log("access_token", accessToken)
    const instance = axios.create({
      baseURL: apiUrl, // Replace with your API base URL
      timeout: 60000, // Adjust the timeout as needed
      headers: {
        access_token: localStorage.getItem(`swobbee.portal.${client_id}.token`),
        client_id: client_id,
      },
    });
    return instance
}


export const getBatteryByUuid = (newData) => async (dispatch) => {
  let params = {};
  let serial_number = newData.serial_number;
  params["battery_type"] = newData.battery_type;
  const { data } = await axiosInstance().get(
    `/batteries/${serial_number}`,
    {
      params,
    }
  );
  if (data.status_code === 200) {
    dispatch(batteryByUuid(data.data));
  }
};

export const gotBatteryUpdate = (message) => (dispatch, getState) => {
  const updateBattery = message.content;
  const state = getState();
  const existingBattery = state.batteries.batteryByUuid;
  if (
    (message.statusCode = 200 && existingBattery.uuid === updateBattery.uuid)
  ) {
    dispatch(batteryByUuid(updateBattery));
  }
};

export const getBatteries = () => (dispatch) => {
  dispatch(batteryFetching(true));
  return dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAll",
      query: "battery_table",
    },
  });
};

/** receive websocket message to get all batteries */
export const gotBatteries = (message) => (dispatch, getState) => {
  const state = getState();
  const total = message.splited_org_size;
  const existingBatteries = state.batteries.batteries || [];
  const newBatteries = [...existingBatteries, ...message.content];
  const loaded = total === newBatteries.length;
  const loadedBatteries = newBatteries.length;
  if (loaded) dispatch(batteryFetching(false));
  return dispatch(
    batteries({
      batteries: newBatteries,
      batteriesTotal: total,
      loadedBatteries: loadedBatteries,
    })
  );
};

/** send websocket message to get all batteries types */
export const getBatteryTypes = () => (dispatch) => {
  dispatch(batteryTypesFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllBatteryTypes",
    },
  });
};

/** receive websocket message to get all batteries types */
export const gotBatteryTypes = (message) => (dispatch) => {
  dispatch(
    batteryTypes({
      batteryTypes: message.content,
    })
  );
  dispatch(batteryTypesFetching(false));
};
/** send websocket message to get all batteries pools */
export const getBatteryPools = () => (dispatch) => {
  dispatch(batteryPoolsFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllBatteryPools",
    },
  });
};

/** receive websocket message to get all batteries pools */
export const gotBatteryPools = (message) => (dispatch) => {
  dispatch(
    batteryPools({
      batteryPools: message.content,
    })
  );
};
/** receive websocket message to update all batteries update existing or add */
export const batteryChanged = (message) => (dispatch, getState) => {
  let changedBattery = message.content;
  const state = getState();
  const batts = state.batteries.batteries;
  let isNew = true;
  const filtered = batts.filter((battery) => {
    const found = battery.serial_number === changedBattery.serial_number;
    if (found) isNew = false;
    return !found;
  });
  let total = state.batteries.batteriesTotal;
  if (isNew) {
    total = total + 1;
  }
  dispatch(
    batteries({
      batteries: [changedBattery, ...filtered],
      batteriesTotal: total,
    })
  );
};
/** send ws message to get Batetry detail */
export const getBatteryBySerialNumber =
  ({ serialNumber }) =>
  (dispatch) => {
    dispatch(batteryBySerialNumberFetching(true));
    dispatch(batteryBySerialNumber(null));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "getBatteryFullBySerialNumber",
        data: { serialNumber: serialNumber },
      },
    });
  };
/** received batteryDetails */
export const gotBatteryBySerialNumber = (message) => (dispatch, getState) => {
  dispatch(batteryBySerialNumberFetching(false));
  dispatch(batteryBySerialNumber(message.content));
};
/** update batteryDetail */
export const updateBatteryBySerialNumber =
  (message) => (dispatch, getState) => {
    let batteryBySN = getState().batteries.batteryBySerialNumber;
    let changedBattery = message.content;
    let firstBatteryBySN = batteryBySN ? batteryBySN[0] : undefined;
    if (
      firstBatteryBySN &&
      firstBatteryBySN.serialNumber &&
      firstBatteryBySN.serialNumber === changedBattery.serialNumber
    ) {
      let newBatteryBySN = [changedBattery, ...batteryBySN];
      dispatch(batteryBySerialNumber(newBatteryBySN));
    }
  };
/** send ws updateBattery with changed data*/
export const updateBattery = (data) => (dispatch, getState) => {
  dispatch(batteryIsUpdating(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createBattery",
      data: data,
    },
  });
  //console.log("update battery", data);
};

export const lastUserChange = (data) => (dispatch, getState) => {
  let newData = { ...data };
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "putLastUser",
      data: newData,
    },
  });
};
