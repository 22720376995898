import React from "react";
import { Button, Icon, Loader } from "semantic-ui-react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const ExportExcel = ({
  excelData,
  fileName,
  setBatteriesToExport,
  setExportCSV,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return excelData ? (
    <Button
      onClick={(e) => {
        exportToExcel(fileName);
        setExportCSV(false);
        setBatteriesToExport([]);
      }}
      color="primary"
      id="csv-export-button"
    >
      <>
        <Icon name="cloud download" /> Download
      </>
    </Button>
  ) : (
    <Button id="csv-export-button" disabled={true}>
      <Loader inline active size="mini" />
    </Button>
  );
};

export default ExportExcel;
