/** @format */

import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Grid,
  GridColumn,
  Input,
  Header,
  Loader,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from "semantic-ui-react";
import CsvGenerator from "../../components/CsvGenerator/CsvGenerator";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import { AxiosContext } from "../../context/AxiosContext";

export default ({ spSerialNumber }) => {
  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    searchResults: null,
    searchTerm: "",
  });
  const [showExportModal, setShowExportModal] = useState();
  const { column, data, direction, searchResults, searchTerm } = state;
  const { timeSpans } = useFormatTimestamp();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchSwaps = async () => {
    const { data } = await axiosInstance.get(
      "/swap?station_id=" + spSerialNumber
    );
    setState({ ...state, data: data.data, searchResults: data.data });
  };

  useEffect(() => {
    fetchSwaps();
    return () => {};
  }, []);

  const sortColumn = column => {
    if (state.column === column) {
      return setState({
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  const handleChange = event => {
    setState({ ...state, searchTerm: event.target.value });
  };

  useEffect(() => {
    if (!data) return;
    const results = data.filter(item => {
      return item.user.toLowerCase().includes(searchTerm);
    });
    setState({ ...state, searchResults: results });
  }, [searchTerm]);

  return (
    <Grid style={{ padding: "20px" }}>
      {!data && (
        <GridColumn style={{ padding: "40px" }} width="16">
          <Loader inline active size="large" />
        </GridColumn>
      )}
      {data && (
        <>
          {data.length === 0 && (
            <GridColumn
              style={{ height: "200px" }}
              textAlign="center"
              verticalAlign="middle"
              width="16"
            >
              <Header as="h4" style={{ marginTop: "190px" }}>
                No data available
              </Header>
            </GridColumn>
          )}
        </>
      )}

      {data && (
        <>
          <GridColumn
            textAlign="left"
            verticalAlign="middle"
            width="8"
            style={{ padding: "30px 0px 0px 30px" }}
          >
            <Button
              content="csv-export"
              primary
              onClick={() => setShowExportModal(true)}
            />
          </GridColumn>
          <GridColumn
            textAlign="right"
            verticalAlign="middle"
            width="8"
            style={{ padding: "30px 30px 0px 0px" }}
          >
            <Input
              placeholder="Search by Username..."
              icon="search"
              onChange={handleChange}
              value={searchTerm}
            />
          </GridColumn>
        </>
      )}

      {searchResults && (
        <>
          {searchResults.length > 0 && (
            <>
              <GridColumn width="16" style={{ padding: "30px 30px 0px 30px" }}>
                {data && (
                  <Table sortable>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell
                          className="left-header"
                          sorted={column === "hash_key" ? direction : null}
                          onClick={() => sortColumn("hash_key")}
                        >
                          Swap ID
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={column === "battery_count" ? direction : null}
                          onClick={() => sortColumn("battery_count")}
                        >
                          Requested
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={column === "battery_type" ? direction : null}
                          onClick={() => sortColumn("battery_type")}
                        >
                          Type
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            column === "battery_out_sum" ? direction : null
                          }
                          onClick={() => sortColumn("battery_out_sum")}
                        >
                          Out
                        </TableHeaderCell>

                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            column === "battery_in_sum" ? direction : null
                          }
                          onClick={() => sortColumn("battery_in_sum")}
                        >
                          In
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={column === "user" ? direction : null}
                          onClick={() => sortColumn("user")}
                        >
                          User
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={column === "timestamp" ? direction : null}
                          onClick={() => sortColumn("timestamp")}
                        >
                          Timestamp
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="right-header"
                          sorted={column === "swap_state" ? direction : null}
                          onClick={() => sortColumn("swap_state")}
                        >
                          State
                        </TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <Table.Body>
                      {searchResults.map(
                        ({
                          hash_key,
                          battery_count,
                          battery_type,
                          battery_out_sum,
                          battery_in_sum,
                          user,
                          timestamp,
                          swap_state,
                        }) => (
                          <TableRow key={hash_key}>
                            <Table.Cell>{hash_key}</Table.Cell>
                            <Table.Cell>{battery_count}</Table.Cell>
                            <Table.Cell>{battery_type}</Table.Cell>
                            <Table.Cell>{battery_out_sum}</Table.Cell>
                            <Table.Cell>{battery_in_sum}</Table.Cell>
                            <Table.Cell>{user}</Table.Cell>
                            <Table.Cell>{timeSpans(+timestamp)}</Table.Cell>
                            <Table.Cell>{swap_state}</Table.Cell>
                          </TableRow>
                        )
                      )}
                    </Table.Body>
                  </Table>
                )}
              </GridColumn>
            </>
          )}
        </>
      )}
      <Modal onClose={() => setShowExportModal(false)} open={showExportModal}>
        <ModalHeader textAlign="left">Export Csv</ModalHeader>
        <ModalContent textAlign="left">
          <p>Would you like to download a swap export?</p>
        </ModalContent>
        <ModalActions>
          <CsvGenerator
            fileToTransform={data}
            onClose={() => setShowExportModal(false)}
          />
        </ModalActions>
      </Modal>
    </Grid>
  );
};
