import { createAction } from "redux-starter-kit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import awsconfig from "../../config/awsconfig";
import * as helper from "./../../helper/sessionHelper";
import { navigate } from "@reach/router";

export const loginLoading = createAction("LOGIN_LOADING");
export const loginSuccess = createAction("LOGIN_SUCCESS");
export const loginError = createAction("LOGIN_ERROR");

export const setSocket = createAction("SET_SOCKET");

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
export const setReauthenticate = createAction("SET_REAUTHENTICATE");
export const setRefreshedToken = createAction("SET_REFRESHED_TOKEN");

let refreshTimer = null;
/**
 * getSession checks sessionData and triggers reauthentication or loginSuccess
 *
 */

export const getSession = () => async (dispatch) => {
  dispatch(loginLoading(true));

  const client_id = awsconfig.REACT_APP_REST_CLIENT_ID;
  const token = localStorage.getItem(`swobbee.portal.${client_id}.token`);
  const userName = localStorage.getItem(`swobbee.portal.${client_id}.userName`);
  const user_group = localStorage.getItem(
    `swobbee.portal.${client_id}.user_group`
  );
  const displayName = localStorage.getItem(
    `swobbee.portal.${client_id}.displayName`
  );
  const tokenExpiry = localStorage.getItem(
    `swobbee.portal.${client_id}.tokenExpiry`
  );
  const name = localStorage.getItem(`swobbee.portal.${client_id}.name`);

  // console.log("-->", user_group, userName, token, name);
  //set timer for refreshToken
  // let diff = tokenExpiry * 1000 - Date.now() - 300000;
  // refreshTimer = setTimeout(() => {
  //   dispatch(refreshToken());
  // }, diff - 300000);
  if (token === null) {
    dispatch(setReauthenticate());
  } else {
    dispatch(
      loginSuccess({
        token,
        userName,
        displayName,
        tokenExpiry,
        name,
        user_group,
      })
    );
  }
};

// export const refreshToken = () => async (dispatch) => {
//   let url = awsconfig.REACT_APP_PUBLIC_API + "/refresh_token";
//   let refreshToken = localStorage.getItem(
//     `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.refresh_token`
//   );
//   let accessToken = localStorage.getItem(
//     `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
//   );
//   const userName = localStorage.getItem(
//     `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.userName`
//   );
//   const user_group = localStorage.getItem(
//     `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.user_group`
//   );
//   const displayName = localStorage.getItem(
//     `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.displayName`
//   );
//   const name = localStorage.getItem(
//     `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`
//   );

//   if (!refreshToken || !accessToken) {
//     clearSessionStorage();
//     dispatch(loginLoading(false));
//     return;
//   }

//   try {
//     const { data } = await axios.post(url, {
//       client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
//       refresh_token: refreshToken,
//       access_token: accessToken,
//     });
//     const { token, tokenExpiry } = decodeToken(data.data, dispatch);
//     dispatch(setRefreshedToken({ token, tokenExpiry }));
//     dispatch(loginLoading(false));
//     dispatch(
//       loginSuccess({
//         token,
//         userName,
//         displayName,
//         tokenExpiry,
//         name,
//         user_group,
//       })
//     );
//     return data;
//   } catch (error) {
//     dispatch(loginLoading(false));
//     clearSessionStorage();
//     window.location = "/";
//     return error;
//   }
// };

export const requestNewPassword = (username) => async (dispatch) => {
  const url = awsconfig.REACT_APP_PUBLIC_API + "/forgot_password";
  try {
    await axios.post(url, {
      username,
      client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
    });
  } catch (error) {
    console.log("q3 error", error);
  }
};

export const loginUser = (username, password) => async (dispatch) => {
  dispatch(loginLoading(true));
  let response;
  const url = awsconfig.REACT_APP_PUBLIC_API + "/login";
  console.log(awsconfig);
  try {
    response = await axios.post(url, {
      username,
      password,
      client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
    });

    let data = response.data;
    if (data.status_code === 200) {
      const token = data.data.access_token;
      const {
        userName,
        displayName,
        tokenExpiry,
        name,
        user_group,
        client_id,
      } = decodeToken(data.data, dispatch);

      if (data.data.permissions) {
        await savePermissionsToLocalStorage(data.data.permissions);
      }
      if (data.data.language) {
        await saveLanguageToLocalStorage(data.data.language);
      }

      dispatch(
        loginSuccess({
          user_group,
          name,
          token,
          username: userName,
          userName,
          displayName,
          tokenExpiry,
          client_id,
        })
      );
      // window.location = "/";
      return data.data;
    }
  } catch (error) {
    let message = error.response.data.message;
    dispatch(loginError(message));
  }
};

export const resetPassword =
  (username, confirmationCode, newPassword) => async (dispatch) => {
    dispatch(loginLoading(true));

    const url = awsconfig.REACT_APP_PUBLIC_API + "/confirm_forgot_password";
    try {
      await axios.post(url, {
        username,
        confirmation_code: confirmationCode,
        new_password: newPassword,
        client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
      });
      navigate("/");
      dispatch(loginLoading(false));
    } catch (error) {
      dispatch(loginLoading(false));
      dispatch(loginError(error.message));
      return error;
    }
  };

export const logoutUser = () => async (dispatch) => {
  try {
    const { data } = await axios.post(
      awsconfig.REACT_APP_PUBLIC_API + "/logout",
      {
        access_token: localStorage.getItem(
          `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
        ),
        client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
      }
    );
    if (data.status_code === 200) {
      localStorage.removeItem("PERMISSIONS");
      localStorage.removeItem("LANGUAGE");
      clearSessionStorage();
      dispatch(logoutSuccess());
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
      sessionStorage.removeItem("SESSIONS");
      window.location = "/";
    }
  } catch (error) {
    console.log(error);
  }
};

/** is used by loginUser and also from refreshToken BUT dispatches ONE action
 * but HAVE TO be refactored NOT TO DISPATCH but to return async
 * localStorage should also be set elsewhere
 * */

const decodeToken = (body, dispatch) => {
  if (refreshTimer) {
    clearTimeout(refreshTimer);
  }
  const token = body.access_token;
  const idToken = body.id_token;
  const user_group_name = body.user_group_name;

  const tokenDecoded = jwtDecode(token);
  const idTokenDecoded = jwtDecode(idToken);
  const name = idTokenDecoded["name"];
  const user_group = body.user_group_name;
  const displayName = tokenDecoded["username"];
  const userName = tokenDecoded["username"];
  const tokenExpiry = tokenDecoded["exp"];
  const client_id = tokenDecoded["client_id"];

  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`,
    name
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`,
    token
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.userName`,
    userName
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.displayName`,
    displayName
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.tokenExpiry`,
    tokenExpiry
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.refresh_token`,
    body.refresh_token
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.idToken`,
    idToken
  );
  localStorage.setItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.user_group`,
    user_group_name
  );
  localStorage.setItem(`client_id`, client_id);

  let diff = tokenExpiry * 1000 - Date.now() - 300000;
  // refreshTimer = setTimeout(() => {
  //   dispatch(refreshToken());
  // }, diff - 300000);
  window.dispatchEvent(new Event("storage"));
  return {
    name,
    displayName,
    userName,
    tokenExpiry,
    token,
    tokenDecoded,
    user_group,
    user_group_name,
  };
};

const savePermissionsToLocalStorage = async (permissions) => {
  localStorage.setItem("PERMISSIONS", JSON.stringify(permissions));
};

const saveLanguageToLocalStorage = async (language) => {
  localStorage.setItem("LANGUAGE", JSON.stringify(language));
};

const clearSessionStorage = () => {
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.userName`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.displayName`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.tokenExpiry`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.refresh_token`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.idToken`
  );
  localStorage.removeItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.user_group`
  );
  localStorage.removeItem(`client_id`);
};
