import React from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Container,
  Dropdown,
  Grid,
  Loader,
  Segment,
} from "semantic-ui-react";
import * as yup from "yup";
import { navigate } from "@reach/router";

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RFHErrorMessages } from "../helpers/forms/RFHErrorMessages";
import { useDropdownOptions } from "../../hooks/use-dropdown-options";

const validationSchema = yup.object().shape({
  company: yup.string().required("manufacturers.company.validationMessage"),
});

const propTypes = {
  batteryTypes: PropTypes.array,
  companies: PropTypes.array,
  save: PropTypes.func,
  isSaving: PropTypes.bool,
};
/** ManufacturerForm - Form for manufacturer creation currently
 * @param {Array} batteryTypes - array of objects for dropdown data
 * @param {Array} companies - array of objects for dropdown data
 * @param {function} save - save handler from parent which dispatches action
 * @param {Boolean} isSaving
 * @return jsx react-hook-form with semantic dropdowns. navigates on cancel.
 */
const ManufacturerForm = ({
  batteryTypes,
  companies,
  isSaving,
  save,
  manufacturer = {},
}) => {
  const { t } = useTranslation();

  const batteryTypeOptions = useDropdownOptions(batteryTypes, {
    key: "hash_key",
    value: "hash_key",
    text: "name",
  });

  const companyOptions = useDropdownOptions(companies, {
    key: "hash_key",
    text: "name",
    value: "hash_key",
    sortKey: "name",
  });

  const { handleSubmit, control, reset, errors } = useForm({
    defaultValues: manufacturer,
    resolver: yupResolver(validationSchema),
  });
  return (
    <Container
      as={Segment}
      style={{
        width: "100%",
        marginTop: 30,
        marginBottom: 20,
        textDecoration: "none",
      }}
      className="form-wrapper--manufacturer"
    >
      <RFHErrorMessages errors={errors} title={t("errors")} />
      <Form onSubmit={handleSubmit(save)}>
        <Grid>
          <Grid.Column width="4">
            <span>{t("manufacturers.company.label")}:</span>
          </Grid.Column>
          <Grid.Column width="12">
            {companyOptions ? (
              <Controller
                control={control}
                name="company"
                render={({ onChange, value }) => {
                  return (
                    <Dropdown
                      clearable
                      style={{ marginTop: 10, marginBottom: 10 }}
                      placeholder={t("manufacturers.company.label")}
                      fluid
                      search
                      selection
                      options={companyOptions}
                      onChange={(_, { value }) => {
                        onChange(value);
                      }}
                      defaultValue={value}
                    />
                  );
                }}
              />
            ) : (
              <Loader active inline />
            )}
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width="4">
            <span>{t("manufacturers.battery_typ.label")}:</span>
          </Grid.Column>
          <Grid.Column width="12">
            {batteryTypeOptions ? (
              <Controller
                control={control}
                name="batteryTypes"
                render={({ value, onChange }) => {
                  return (
                    <Dropdown
                      clearable
                      style={{ marginTop: 10, marginBottom: 10 }}
                      placeholder={t("manufacturers.battery_typ.label")}
                      fluid
                      search
                      selection
                      multiple
                      options={batteryTypeOptions}
                      onChange={(_, { value }) => {
                        onChange(value);
                      }}
                      defaultValue={value}
                    />
                  );
                }}
              />
            ) : (
              <Loader active inline />
            )}
          </Grid.Column>
        </Grid>

        <Grid style={{ marginTop: 10 }}>
          <Grid.Row>
            <Grid.Column textAlign="right">
              {isSaving && (
                <Loader active inline>
                  Manufacturer is saving.
                </Loader>
              )}
              <Button
                color="orange"
                type="button"
                content={t("button.cancel")}
                icon="close"
                onClick={() => {
                  reset();
                  navigate("/manufacturers");
                }}
              />
              <Button
                type="submit"
                color="green"
                disabled={Object.keys(errors).length > 0 || isSaving}
                content={t("button.save")}
                icon="save"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
};
ManufacturerForm.propTypes = propTypes;

export default ManufacturerForm;
