/** @format */

import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../helper/sp-utils";

/**
 * hook to format timestamps
 * @param {Array} data array of data
 * @param {Object}
 *    @param {String} key objectKey for sorting *required
 *    @param {String|undefined} value objectKey for sorting
 *    @param {String|undefined} text text property
 *
 *    @returns {JSX} 2 divs with day and time
 */
function useFormatTimestamp(newFormat) {
  const { i18n } = useTranslation();

  const formatString = useMemo(() => {
    let format = newFormat ? newFormat : "DD.MM.YYYY HH:mm";
    // if (i18n.language === "en") format = "DD.MM.YYYY hh:mm A";
    return format;
  }, [i18n.language]);

  const formattedTimeString = useCallback(
    (timestamp) => {
      if (timestamp) {
        return formatTimestamp(+timestamp, formatString).toString();
      }
    },
    [formatString]
  );

  const timeSpans = useCallback(
    (timestamp, color, fontSize) => {
      let [day, ...time] = formatTimestamp(+timestamp, formatString).split(" ");
      if (typeof time === "object") time = time.join(" ");
      return (
        
          <div style={{color, fontSize}}>{day} - {time}</div>
        
      );
    },
    [formatString]
  );

  return { timeSpans, formattedTimeString };
}
export { useFormatTimestamp };
