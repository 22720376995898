import React, { useMemo /*, { useState }*/ } from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";

/**
 * Component for displaying react-table row with higlighted errror row and clickListener
 *
 * @param {Object} row row value from react-table contains original attribute
 * @param {Number} index array index of row position used for key attribute
 * @param {function} onRowClick used to get the clicked row passed down from parents
 */
const TableRow = ({ row, index, onRowClick = () => {} }) => {
  const classNames = useMemo(() => {
    const css = ["table-row"];
    if (row.isSelected) {
      css.push("selected");
    }
    if (row.original["Exception"]) {
      css.push("exception");
    }
    return css;
  }, [row.original, row.isSelected]);

  return (
    <Table.Row
      {...row.getRowProps()}
      className={classNames.join(" ")}
      title={row.original["Exception"] ? row.original["Exception"] : ""}
      onClick={(e) => {
        onRowClick(row);
      }}
    >
      {row.cells.map((cell, i) => {
        return (
          <td {...cell.getCellProps()} key={`${row.id}-cell-${i}`}>
            {cell.render("Cell")}
          </td>
        );
      })}

      {false && (
        <div
          className="info-panel"
          style={{ border: "solid 2px red", padding: 10 }}
        >
          <p>{row.original["Exception"]}</p>
          <p>{row.original["Exception Type"]}</p>
          <p>{row.original["args"]}</p>
        </div>
      )}
    </Table.Row>
  );
};
TableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    original: PropTypes.object,
    values: PropTypes.object,
    isSelected: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number,
  onRowClick: PropTypes.func,
};

export default TableRow;
