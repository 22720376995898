/** @format */

import { createAction } from "redux-starter-kit";
import { providerIsSaving } from "./providerActions";
import { ownerIsSaving } from "./ownerActions";
import {
  sharingPointIsSaving,
  isSavingSettings,
  commandSuccess,
} from "./sharingPointActions";
import { manufacturerIsSaving } from "./manufacturerActions";
import { locationIsSaving } from "./locationActions";
import { batteryIsUpdating } from "./batteryActions";
import { savedRegion } from "./regionActions";
import { updateNote } from "./noteActions";
import { savedContact } from "./contactActions";

export const acks = createAction("ACKS");
/** used for messages with statusCode of 500
 * the erros
 *
 */
export const addErrorAck = (error) => (dispatch, getState) => {
  dispatch(acks(error));
};

export const gotAckMessage = (message) => (dispatch, getState) => {
  //getState();
  switch (message.request_type) {
    /** 3 command cases all are handled equal */
    case "reboot":
    case "toggleFiSafetySwitch":
    case "startAndStopAirCondition":
    case "getSharingPointStatus":
      dispatch(commandSuccess(message));
      break;
    case "createRegion":
      dispatch(savedRegion(message));
      break;
    case "createCompany":
      break;
    case "createBattery":
      dispatch(batteryIsUpdating(false));
      break;
    case "createSharingPoint":
      dispatch(sharingPointIsSaving(false));
      break;
    case "createNote":
      dispatch(updateNote(message));
      break;
    case "createManufacturer":
      dispatch(manufacturerIsSaving(false));
      break;
    case "createProvider":
      console.log("create provider", message);
      dispatch(providerIsSaving(false));
      break;
    case "createOwner":
      dispatch(ownerIsSaving(false));
      break;
    case "createContact":
      dispatch(savedContact());
      break;
    case "createLocation":
      dispatch(locationIsSaving(false));
      break;
    case "putSharingPointSettings":
      dispatch(isSavingSettings(false));
      // set own attributes as the message is not in the old format for toastify use
      if (message.statusCode === 201) {
        message["display_message"] = "Setting saved successfully";
        message["description"] = "you saved settings";
        message["icon_name"] = "heart";
      } else {
        message["display_message"] = "save settings error";
        message["description"] = "there was a server error";
        message["icon_name"] = "frown";
      }
      break;
    default:
      console.log("default ack handler", message);
  }
  dispatch(acks(message));
};
