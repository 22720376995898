import { createReducer } from "redux-starter-kit";
import {
  regionsFetching,
  regions,
  isSavingRegion,
  saveSuccessMessage,
} from "../actions";

const initialState = {
  fetching: false,
  error: false,
  regions: [],
  isSaving: false,
  saveSuccessMessage: null,
  total: 0,
};

const regionsReducer = createReducer(initialState, {
  [regionsFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [regions]: (state, action) => {
    return {
      ...state,
      regions: action.payload.regions,
      total: action.payload.regions.length,
    };
  },
  [isSavingRegion]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload,
    };
  },
  [saveSuccessMessage]: (state, action) => {
    return {
      ...state,
      saveSuccessMessage: action.payload,
    };
  },
});
export default regionsReducer;
