/** @format */

import React, { useCallback, useContext, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Dropdown,
  Icon,
  Image,
  Menu,
  Flag,
} from "semantic-ui-react";
import { logoutUser, updateSideBarState } from "../../store/actions";

import "./index.scss";
import { Link } from "@reach/router";
import { useDispatch } from "react-redux";
import { isAllowedToRead } from "../../helper/permissions";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { AxiosContext } from "../../context/AxiosContext";
import awsconfig from "../../config/awsconfig";

const navLinks = [
  {
    permission: "SharingPoint",
    href: "/sharingpoints",
    label: "mainsidebar.sharingpoints.label",
    icon: "lightning",
  },
  {
    permission: "SharingPoint",
    href: "/statistics",
    label: "mainsidebar.statistics.label",
    icon: "chart bar outline",
  },
  // {
  //   permission: "SwapStatistic",
  //   href: "/battery-tracking",
  //   label: "Battery-Tracking",
  //   icon: "binoculars",
  // },
  {
    permission: "NewBattery",
    href: "/batteries",
    label: "mainsidebar.batteries.label",
    icon: "battery full",
  },
  {
    permission: "SharingPoint",
    href: "/battery-tracking",
    label: "Battery-Tracking",
    icon: "dashboard",
  },
  {
    permission: "ErrorHistory",
    href: "/locations",
    label: "mainsidebar.locations.label",
    icon: "point",
  },
  // {
  //   permission: "TechCV",
  //   href: "/techcvs",
  //   label: "mainsidebar.techcvs.label",
  //   icon: "file alternate outline",
  // },
  // {
  //   permission: "Owner",
  //   href: "/owners",
  //   label: "mainsidebar.owners.label",
  //   icon: "users",
  // },
  // {
  //   permission: "Contact",
  //   href: "/contacts",
  //   label: "mainsidebar.contacts.label",
  //   icon: "address book outline",
  // },
  // {
  //   permission: "Manufacturer",
  //   href: "/manufacturers",
  //   label: "mainsidebar.manufacturers.label",
  //   icon: "settings",
  // },
  // {
  //   permission: "Company",
  //   href: "/companies",
  //   label: "mainsidebar.companies.label",
  //   icon: "building outline",
  // },
  // {
  //   permission: "Provider",
  //   href: "/providers",
  //   label: "mainsidebar.providers.label",
  //   icon: "handshake outline",
  // },
  {
    permission: "ErrorHistory",
    href: "/",
    // label: "mainsidebar.errorHistory.label",
    label: "Error History",
    icon: "warning sign",
  },

  {
    permission: "SharingPoint",
    href: "/user-management",
    label: "User-Management",
    icon: "user plus",
  },
  {
    permission: "ErrorHistory",
    href: "/release-notes",
    label: "mainsidebar.releaseNotes.label",
    icon: "newspaper outline",
  },
];
/** NavItem helper Component
 * @param {object} item - link object
 * @param {boolean} collapsed - used for opacity animation
 */
const NavItem = ({ item, collapsed }) => {
  const { t } = useTranslation();

  let dispatch = useDispatch();

  const [styles, api] = useSpring(() => ({ opactiy: 0 }));

  useEffect(() => {
    api.start({ opacity: collapsed ? 0 : 1 });
  }, [collapsed, api]);

  const isAllowed = useCallback((permission) => {
    return isAllowedToRead(permission);
  }, []);

  return isAllowed(item.permission) ? (
    <Menu.Item>
      <Link
        to={item.href}
        onClick={() => dispatch(updateSideBarState(!collapsed))}
        className=""
      >
        <Icon name={item.icon} />
        <animated.div style={styles}>{t(item.label)}</animated.div>
      </Link>
    </Menu.Item>
  ) : (
    ""
  );
};
/** the nav Menu with all Items */
const Nav = ({ collapsed }) => {
  return (
    <aside>
      <nav>
        <Menu icon="labeled" inverted vertical>
          {navLinks.map((item) => (
            <NavItem collapsed={collapsed} item={item} key={item.href} />
          ))}
        </Menu>
      </nav>
    </aside>
  );
};
/** MainHeader -- renders burger Nav, header-portal element und login nav
 * @param { object} session - the current session from redux
 * @param { object } lang -i18n lang
 * @param {function} setLang - i18n setter
 * @param { boolean} collapsed - i NavMenu hidden
 * @return jsx with Nav, Header-Portal and Login Nav
 */
const MainHeader = ({ session, lang, setLang, collapsed }) => {
  const dispatch = useDispatch();
  const node = useRef();

  const changeSidebar = useCallback(() => {
    dispatch(updateSideBarState(!collapsed));
  }, [dispatch, collapsed]);

  /** handle click handler detects if click occurs outside component */
  const handleClick = useCallback(
    (e) => {
      if (!collapsed) {
        if (node.current.contains(e.target)) {
          return;
        } else {
          dispatch(updateSideBarState(true));
        }
      }
    },
    [dispatch, collapsed]
  );
  /** set mousedown listener for clickoutside component */
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [collapsed, handleClick]);

  /** Spring animation style with animation prop setter in useEffect*/
  const [styles, api] = useSpring(() => ({ translateX: -800, delay: 0 }));
  useEffect(() => {
    api.start({ translateX: collapsed ? -800 : 0 });
  }, [collapsed, api]);

  return (
    <div
      style={{
        backgroundColor: "#eee",
        boxShadow: "0.5px 0.5px 0.5px 0.5px #bbb",
      }}
      className={`main-header main-header--${
        collapsed ? "collapsed" : "opened"
      }`}
      ref={node}
    >
      <div style={{ backgroundColor: "#eee" }} className="left-side">
        <animated.div style={styles} className="nav-menu">
          <Nav collapsed={collapsed} />
        </animated.div>
        <div
          style={{ backgroundColor: "#eee" }}
          className="flex space-between burger-wrapper"
        >
          <Button
            style={{ color: "#ff5a00" }}
            className="burger-menu"
            icon={collapsed ? "sidebar" : "close"}
            onClick={changeSidebar}
          />

          <div className="h3 txt-logo">
            <Link to="/">
              <img
                alt=""
                width="110px"
                height="auto"
                src="https://we-are-hiring.cdn.personio.de/logos/25749/default/e8a6675690ed898b3d859a1eb3f89cf2.png"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="portal" id="header-portal"></div>
      <div className="nav-wrapper">
        <LoginNav session={session} lang={lang} setLang={setLang} />
      </div>
    </div>
  );
};
const langs = ["de", "en", "es"];

const LoginNav = ({ session, lang, setLang }) => {
  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);

  const postUserSettings = async (language) => {
    await axiosInstance.post("/users/settings", {
      username: session.userName,
      language,
    });
  };

  const name = localStorage.getItem(
    `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`
  );

  return (
    <nav
      className="nav nav--login"
      style={{ marginRight: "10px", height: "100%" }}
    >
      {name && (
        <Menu style={{ marginRight: "10px", height: "100%" }}>
          <Dropdown
            item
            text={
              <>
                <Image
                  size="mini"
                  circular
                  src={`https://eu.ui-avatars.com/api/?name=${name}&background=ff5a00&color=fff&bold=true`}
                />
                {name}
              </>
            }
          >
            <Dropdown.Menu style={{ backgroundColor: "#eee" }}>
              <Dropdown.Item>
                <Link to="/profile">
                  <div style={{ color: "#ff5a00" }}>
                    {" "}
                    <Icon name="user solid" style={{ color: "#ff5a00" }} />
                    My profile
                  </div>
                </Link>
              </Dropdown.Item>

              <Dropdown
                item
                direction="left"
                text={
                  <>
                    <Icon name="world" style={{ color: "#ff5a00" }} />
                    <span style={{ color: "#ff5a00" }}>Language</span>
                  </>
                }
              >
                <Dropdown.Menu style={{ color: "#ff5a00" }}>
                  {langs.map((l) => (
                    <Menu.Item
                      position="left"
                      key={l}
                      active={lang === l ? true : false}
                      className={lang === l ? "selected-lang" : "lang"}
                      onClick={() => {
                        localStorage.setItem("i18n-language", l);
                        setLang(l);
                        postUserSettings(l);
                      }}
                    >
                      <span>
                        <Flag name={l === "en" ? "us" : l} />
                      </span>
                      {l}
                    </Menu.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown.Item
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                <Link to="/">
                  <div style={{ color: "#ff5a00" }}>
                    <Icon name="log out" style={{ color: "#ff5a00" }} />
                    Logout
                  </div>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )}
    </nav>
  );
};

const NewLayout = ({ session, children, setLang, lang, collapsed = true }) => {
  return (
    <Container fluid className="screen-wrapper screen-wrapper--new-layout">
      {window.innerWidth > 425 && (
        <MainHeader
          session={session}
          lang={lang}
          setLang={setLang}
          collapsed={collapsed}
        />
      )}

      <div className="main-content">{children}</div>
    </Container>
  );
};
export { NewLayout };
