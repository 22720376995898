/** @format */

import axios from "axios";
import { createAction } from "redux-starter-kit";

export const filesByContextHashKey = createAction("FILES_BY_CONTEXT_HASH_KEY");

export const filesIsFetching = createAction("FILES_IS_FETCHING");

export const isSavingFile = createAction("IS_SAVING_FILE");

export const filesError = createAction("FILES_ERROR");

export const gotUploadUrl = createAction("GOT_UPLOAD_URL");

export const isUploadFile = createAction("IS_UPLOAD_FILE");

export const setContextHashKeyForFiles = createAction(
  "SET_CONTEXT_HASH_KEY_FOR_FILES"
);

export const resetFiles = createAction("RESET_FILES");

export const getFilesByContextHashKey = hash_key => (dispatch, getState) => {
  dispatch(resetFiles());
  dispatch(filesIsFetching(true));
  dispatch(setContextHashKeyForFiles(hash_key));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getFilesByContextHashKey",
      data: { context_hash_key: hash_key }
    }
  });
};

export const gotFilesByContextHashKey = message => (dispatch, getState) => {
  dispatch(filesIsFetching(false));
  dispatch(filesByContextHashKey(message.content));
  switch (message.statusCode) {
    case 200 || 204:
      dispatch(filesByContextHashKey(message.content));
      break;
    case 403:
      dispatch(
        filesError({
          key: "Authentication",
          displayMessage: "Authentication error: No right to retrieve Files"
        })
      );
      break;
    default:
      dispatch(
        filesError({
          key: "Error",
          displayMessage: "Error when loading the Files",
          statusCode: message.statusCode
        })
      );
      break;
  }
};

export const createFile = data => (dispatch, getState) => {
  let currentUser = getState().session.username;
  let newData = { ...data, creator: currentUser };
  dispatch(isSavingFile(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "CreateFile",
      data: newData
    }
  });
};

const download = dataUrl => {
  var a = document.createElement("a");
  a.href = dataUrl;
  a.click();
};

export const gotFileUrl = message => (dispatch, getState) => {
  switch (message.statusCode) {
    case 200:
      download(message.content.getUrl);
      break;
    case 403:
      dispatch(
        filesError({
          key: "Authentication",
          displayMessage: "Authentication error: No right to download Files"
        })
      );
      break;
    default:
      dispatch(
        filesError({
          key: "Error",
          displayMessage: "Error when downloading the Files",
          statusCode: message.statusCode
        })
      );
      break;
  }
};

export const gotGeneratedUploadUrl = message => (dispatch, getState) => {
  switch (message.statusCode) {
    case 200:
      dispatch(gotUploadUrl(message.content));
      break;
    default:
      dispatch(
        filesError({
          key: "Error",
          displayMessage: "Error uploading the file",
          statusCode: message.statusCode
        })
      );
      break;
  }
};

export const uploadedFile = data => (dispatch, getState) => {
  let newData = { ...data };
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "UploadedFile",
      data: newData
    }
  });
};

export const uploadFile = (
  uploadUrl,
  localFile,
  onClose,
  uploadedFileHandler,
  setUploadedProgress
) => {
  const config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadedProgress(percentCompleted);
    },
    headers: {
      "Content-Type": "multipart/form-data",
      "x-amz-acl": "private"
    }
  };
  axios
    .put(uploadUrl, localFile, config)
    .then(result => {
      uploadedFileHandler();
      onClose();
    })
    .catch(error => console.log("error with upload", error));
};

export const createFileUrl = data => (dispatch, getState) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "CreateUrlFile",
      data: data
    }
  });
};

export const deleteFile = data => (dispatch, getState) => {
  let newData = { ...data };
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "deleteFile",
      data: newData
    }
  });
};

export const updateFile = message => (dispatch, getState) => {
  let updatedFile = message.content;
  const state = getState();
  const existingFiles = state.files.files; //ByContextHashKey;
  const contextHashKey = state.files.contextHashKey;
  if (contextHashKey && contextHashKey === updatedFile.context_hash_key) {
    let newFiles;
    if (!existingFiles) {
      const existingFiles = [];
      newFiles = [updatedFile, ...existingFiles];
      dispatch(isSavingFile(false));
      dispatch(filesByContextHashKey(newFiles));
      return;
    }
    let found = existingFiles.find(
      file => file.hash_key === updatedFile.hash_key
    );
    if (!existingFiles) {
      return;
    }
    if (found && message.message_type === "UPDATE") {
      newFiles = existingFiles.map(file => {
        return file.hash_key === updatedFile.hash_key
          ? Object.assign({}, file, updatedFile)
          : file;
      });
    } else {
      newFiles = [...existingFiles, updatedFile];
    }
    dispatch(isSavingFile(false));
    dispatch(filesByContextHashKey(newFiles));
  }
};

export const deletedFile = message => (dispatch, getState) => {
  let removeFile = message.content;
  const state = getState();
  const existingFiles = state.files.files;
  if (!existingFiles) {
    return;
  }
  let newFiles;
  let found = existingFiles.find(file => file.hash_key === removeFile.hash_key);
  if (!existingFiles) {
    return;
  }
  if (message.message_type === "DELETED_ELEMENT" && found) {
    newFiles = existingFiles.filter(
      exFile => exFile.hash_key !== removeFile.hash_key
    );
    dispatch(filesByContextHashKey(newFiles));
  }
};
