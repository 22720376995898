import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  TileLayer,
  MapContainer,
  useMap,
  Popup,
  Marker,
  ZoomControl,
} from "react-leaflet";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import MarkerClusterGroup from "react-leaflet-markercluster";
import { Button } from "semantic-ui-react";
import { upperFirst } from "lodash";

delete L.Icon.Default.prototype._getIconUrl;

const openIcon = (state = "active") => {
  return L.icon({
    iconUrl: require(`./../../assets/images/Icon_active.svg`),
    iconSize: [28, 49], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [14, 50], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [0, -50],
  });
};

const mapProps = {
  latLng: PropTypes.array,
  sharingPoints: PropTypes.array,
  diameter: PropTypes.number,
  ownLocation: PropTypes.bool,
};

const minZoom = 4;
const maxZoom = 18;
const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

/** react-leaflet Map renders all public sharingpoints*/
const Map = ({
  latLng,
  sharingPoints,
  diameter,
  ownLocation,
  initialMapZoom,
  flyToGeoCode,
  hasUserLocationButton,
  hasZoomControl,
}) => {
  const [userPosition, setUserPosition] = useState(null);

  return (
    <MapContainer
      zoomControl={false}
      zoom={initialMapZoom}
      center={latLng}
      style={{ height: "100%", width: "100%" }}
    >
      {hasZoomControl && <ZoomControl position="bottomright" />}

      <TileLayer
        attribution={attribution}
        url={tileUrl}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />
      <MapChild
        sharingPoints={sharingPoints}
        diameter={diameter}
        latLng={latLng}
        ownLocation={ownLocation}
        {...{
          flyToGeoCode,
          userPosition,
          setUserPosition,
          hasUserLocationButton,
        }}
      />
    </MapContainer>
  );
};

/** Helper Component needed to access map with useMap() renders sp markers
 * @param {array} sharingPoints - all public sharingpoints
 * @param {number} diameter - the area to zoom to
 * @param {array} latLng - geolocation [latitude, longitude] to center
 * @param {bool} ownLocation - determines whether a section of a circle or the entire map is shown
 * @returns react-leaflet map layer with center-control and markercluster groups
 */
const MapChild = ({
  sharingPoints,
  diameter,
  latLng,
  ownLocation,
  flyToGeoCode,
  userPosition,
  setUserPosition,
  hasUserLocationButton,
}) => {
  const clusterGroup = useRef();
  const radiusCircle = useRef();
  const map = useMap();

  const flyToBounds = useCallback(() => {
    if (flyToGeoCode) return;
    if (!ownLocation) {
      diameter = diameter || 7;
      latLng = latLng || [52.43179590114306, 13.523359681764884];
    }
    const clusterCurrent = clusterGroup.current;
    if (!ownLocation) {
      console.log("ownLocation")
      map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });
    } else if (latLng && diameter) {
      if (radiusCircle.current) {
        map.removeLayer(radiusCircle.current);
        radiusCircle.current = undefined;
      }
      console.log(latLng);
      const circle = L.circle(latLng, {
        radius: (diameter / 2) * 1000,
        fill: false,
        stroke: false,
      }).addTo(map);
      radiusCircle.current = circle;
      // console.log("-->",circle.getBounds())
      map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });
      
    } else {
      console.log("map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });")
      map.fitBounds(clusterCurrent.getBounds(), { padding: [20, 20] });
    }
  }, [map, latLng, diameter, ownLocation]);

  const flyTo = useCallback(() => {
    map.flyTo(flyToGeoCode, 12);
  }, [map, flyToGeoCode, diameter, ownLocation]);

  useEffect(() => {
    if (sharingPoints) flyToBounds();
  }, [sharingPoints, flyToBounds, latLng]);

  useEffect(() => {
    if (!(sharingPoints && flyToGeoCode)) return;
    flyTo();
  }, [sharingPoints, flyToGeoCode]);

  return (
    <>
      {hasUserLocationButton && (
        <Button
          icon={
            <img
              style={{ height: 30, width: "auto", position: "absolute" }}
              src={require("./searchlocation.png")}
              alt=""
            />
          }
          style={{
            color: "black",
            position: "absolute",
            zIndex: 9999,
            bottom: 100,
            right: 9,
            backgroundColor: "#fff",
            boxShadow: "0 1px 5px rgba(0,0,0,0.65)",
            height: 40,
            width: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
          }}
          disabled={!userPosition}
          onClick={() => {
            map.flyTo(userPosition, 18);
          }}
        />
      )}
      <MarkerClusterGroup ref={clusterGroup}>
        <>
          {map.getZoom() && hasUserLocationButton && (
            <LocationMarker {...{ map, userPosition, setUserPosition }} />
          )}
          {sharingPoints &&
            sharingPoints.map((sp) => {
              return (
                sp &&
                sp.gps_latitude && (
                  <Marker
                    key={sp.sp_serial_number}
                    spName={sp.sp_serial_number}
                    icon={openIcon(sp.is_open)}
                    position={[sp.gps_latitude, sp.gps_longitude]}
                  >
                    <Popup>
                      <PopContent sp={sp} />
                    </Popup>
                  </Marker>
                )
              );
            })}
        </>
      </MarkerClusterGroup>
    </>
  );
};

/** popup content for sp marker popup
 * @param {object} sp - a sharingPoint object with nested location
 * @returns wrapper div with sp_serial_number as header, address row, w3w attribute and swapReady batterie row a distance is shown conditionally if ownLocation is given
 */
const PopContent = ({ sp }) => {
  const {
    w3w,
    sp_serial_number,
    nick_name,
    city,
    street,
    street_number,
    zipcode,
    box_status_check,
  } = sp;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: 250,
      }}
    >
      <img
        height="120px"
        width="auto"
        src="https://swobbee-frontend-assets.s3.eu-central-1.amazonaws.com/Swobbee_Akkus_Frontal_closed.png"
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "white !important",
          marginLeft: 16,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Sp serial number</label>
          <span>{sp_serial_number}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Nickname</label>
          <span>{nick_name}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Address</label>
          <span>{`${street} ${street_number}`}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Zip code & city </label>
          <span>{`${zipcode} ${city}`}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>w3w</label>
          <span>{w3w}</span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <label>Inside</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Object.keys(box_status_check).map((key) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span>{upperFirst(key).replaceAll("_", " ")}</span>
              <span>{box_status_check[key]}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
Map.propTypes = mapProps;
export { Map };

const LocationMarker = ({ map, userPosition, setUserPosition }) => {
  useEffect(() => {
    if (!map) return;
    map.locate().on("locationfound", function (e) {
      setUserPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    });
  }, [map]);

  return userPosition === null ? null : (
    <Marker
      position={userPosition}
      icon={L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
        shadowUrl:
          "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
      })}
    />
  );
};
