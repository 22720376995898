import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};
/**
 * an input helper for react-hook-form (rfh) with react.semantic-ui style
 * it takes register, name for rfh registering
 * an optional label and type as input-type
 */
const RHFInputRow = ({
  defaultValue,
  placeholder,
  register,
  name,
  label,
  error,
  type = "text",
}) => {
  return (
    <Grid.Row className={(error ? "error form-row--error" : "") + " form-row "}>
      {label && (
        <Grid.Column width={16} textAlign="left">
          <label htmlFor={name}>{label}</label>
        </Grid.Column>
      )}
      <Grid.Column width={16}>
        <div className="ui right icon input fluid">
          {error && <Icon name="warning" color="red" />}
          <input
            placeholder={placeholder}
            defaultValue={defaultValue}
            aria-label={name}
            type={type}
            ref={register}
            name={name}
          />
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};
RHFInputRow.propTypes = propTypes;

export { RHFInputRow };
