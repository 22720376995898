/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import {
  sharingPointsMapDataFetching,
  sharingPointsTableDataFetching,
  sharingPointsMapData,
  sharingPointsTableData,
  sharingPointDetailData,
  sharingPointsTableError,
  sharingPointIsSaving,
  sharingPointSettings,
  historyGSMData,
  historyOnlineData,
  historyTempData,
  isSavingSettings,
  fetchSettings,
  fetchHistoryOnlineData,
  fetchHistoryGSMData,
  fetchHistoryTempData,
  settingsError,
  sharingPointStatus,
  isFetchingStatus,
  fetchSharingPointBoxes,
  sharingPointBoxes,
  sharingPointBoxesError,
  swapActionsError,
  swapActions,
  isFetchingSwapActions,
  openDoorRequest,
  openDoorError,
  openDoorSuccess,
  resetSharingPoints,
  requestCommand,
  commandSuccess,
  resetSwapActions,
  sharingPointDetailDataError,
  isFetchingInteractions,
  interactionsError,
  interactions,
  resetInteractions,
  selectSharingPoint,
  updateBox,
} from "../actions";

export const initialState = {
  //data for all sharingPoints
  fetchingMapData: false,
  fetchingTableData: false,
  sharingPointsTableError: null,
  sharingPointsMapData: null,
  sharingPointsTableData: null,
  sharingPointDetailData: null,
  sharingPointDetailError: null,
  sharingPointsTotal: 0,
  // select
  selectedSharingPointHashKey: null,
  //for sharingpoint-show
  sharingPoint: null,
  error: false,
  isSaving: false,
  sharingPointStatus: null,
  isFetchingStatus: false,
  statusError: null,
  // settings data
  sharingPointSettings: null,
  isFetchingSettings: false,
  isSavingSettings: false,
  settingsError: null,
  // box status
  sharingPointBoxes: null,
  isFetchingSharingPointBoxes: false,
  sharingPointBoxesError: null,
  pendingOpenDoors: [],

  // HISTORY DATA
  isFetchingHistoryGSMData: false,
  isFetchingHistoryTempData: false,
  isFetchingHistoryOnlineData: false,
  // objects with requestParams: {start_time: timestamp, end_time: timestamp, sp_serial_number:string}, data: [],
  historyGSMData: null,
  historyTempData: null,
  historyOnlineData: null,
  //swapActions
  isFetchingSwapActions: false,
  swapActions: null,
  swapActionsMessageNumber: undefined,
  swapActionsError: null,
  //command additions no command flags implemented yet in the reducer actions are simply fired
  commands: {},
  isStartAndStoppingAirCondition: false,
  isRebootingIpc: false,
  isTogglingFiSafetySwitch: false,
  isClearingErrors: false,
  isClearingWarnings: false,
  //interactions
  isFetchingInteractions: false,
  interactions: null,
  interactionLastEvaluatedKey: null,
  interactionsError: null,
  lastEvaluatedKey: null,
};

const sharingPointsReducer = createReducer(initialState, {
  //interactions
  [isFetchingInteractions]: (state, action) => {
    state.isFetchingInteractions = action.payload;
  },
  [interactionsError]: (state, action) => {
    return {
      ...state,
      isFetchingInteractions: false,
      interactions: null,
      interactionsError: action.payload,
      interactionLastEvaluatedKey: null,
    };
  },
  [interactions]: (state, action) => {
    state.interactions = action.payload.items;
    state.interactionLastEvaluatedKey = action.payload.last_evaluated_key;
  },
  [resetInteractions]: (state, action) => {
    state.isFetchingInteractions = false;
    state.interactions = null;
    state.interactionsError = null;
    state.interactionLastEvaluatedKey = null;
  },
  //select
  [selectSharingPoint]: (state, action) => {
    console.log("q22 select", action.payload);
    state.selectedSharingPointHashKey = !action.payload
      ? null
      : state.selectedSharingPointHashKey === action.payload
      ? null
      : action.payload;
  },
  /** track commands by commandType and spSerialNumber */
  [requestCommand]: (state, action) => {
    let command = action.payload.request;
    let spSerialNumber = action.payload.data["sp_serial_number"];
    state.commands = {
      ...state.commands,
      [command]: [...(state.commands[command] || []), spSerialNumber],
    };
  },
  [commandSuccess]: (state, action) => {
    let payload = action.payload;
    let commandType = payload.request_type;
    let spSerialNumber = payload.request_parameters["sp_serial_number"];
    let sharingPointsForCommand = state.commands[commandType].filter(
      c => c !== spSerialNumber
    );
    let newCommands = {
      ...state.commands,
      [commandType]: sharingPointsForCommand,
    };
    state.commands = newCommands;
  },

  [resetSharingPoints]: (state) => {
    state = initialState;
  },
  [sharingPointsTableDataFetching]: (state, action) => {
    state.fetchingTableData = action.payload;
  },
  [sharingPointDetailData]: (state, action) => {
    state.sharingPointDetailData = action.payload;
  },
  [sharingPointDetailDataError]: (state, action) => {
    state.sharingPointDetailData = null;
    state.sharingPointDetailDataError = action.payload.error;
  },
  [sharingPointsMapDataFetching]: (state, action) => {
    state.fetchingMapData = action.payload;
  },
  [sharingPointsTableError]: (state, action) => {
    state.sharingPointsTableError = action.payload;
  },
  [sharingPointsMapData]: (state, action) => {
    state.sharingPointsMapData = action.payload.locationsMapData;
  },
  [sharingPointsTableData]: (state, action) => {
    state.sharingPointsTableData = action.payload.locationsTableData;
    state.sharingPointsTotal = action.payload.locationsTotal;
    state.fetchingTableData = action.payload.fetching;
    state.sharingPointsTableError = undefined;
  },
  [sharingPointIsSaving]: (state, action) => {
    state.isSaving = action.payload;
  },
  // settings
  [fetchSettings]: (state, action) => {
    state.isFetchingSettings = action.payload;
  },
  [isSavingSettings]: (state, action) => {
    state.isSavingSettings = action.payload;
  },

  [sharingPointSettings]: (state, action) => {
    let spSettings;
    if (!action.payload) {
      spSettings = null;
    } else {
      spSettings = {
        ...action.payload.content,
      };
    }
    state.sharingPointSettings = spSettings;
  },
  [settingsError]: (state, action) => {
    state.settingsError = action.payload;
  },
  /** status data */
  [isFetchingStatus]: (state, action) => {
    state.isFetchingStatus = action.payload;
  },
  [sharingPointStatus]: (state, action) => {
    state.sharingPointStatus = action.payload.status;
    state.statusError = action.payload.error;
  },
  //boxes
  [fetchSharingPointBoxes]: (state, action) => {
    state.isFetchingSharingPointBoxes = action.payload;
  },
  [sharingPointBoxes]: (state, action) => {
    state.sharingPointBoxes = action.payload;
  },
  [updateBox]: (state, action) => {
    const boxes = state.sharingPointBoxes.map((box) => {
      return box.box_number === action.payload.box_number
        ? action.payload
        : box;
    });
    state.sharingPointBoxes = boxes;
  },
  [sharingPointBoxesError]: (state, action) => {
    state.sharingPointBoxesError = action.payload;
  },
  // history data
  [historyGSMData]: (state, action) => {
    state.historyGSMData = action.payload;
    state.isFetchingHistoryGSMData = false;
  },
  [fetchHistoryOnlineData]: (state, action) => {
    state.isFetchingHistoryOnlineData = action.payload;
  },
  [fetchHistoryGSMData]: (state, action) => {
    state.isFetchingHistoryGSMData = action.payload;
  },
  [fetchHistoryTempData]: (state, action) => {
    state.isFetchingHistoryTempData = action.payload;
  },
  [historyOnlineData]: (state, action) => {
    state.historyOnlineData = action.payload;
    state.isFetchingHistoryOnlineData = false;
  },
  [historyTempData]: (state, action) => {
    state.historyTempData = action.payload;
    state.isFetchingHistoryTempData = false;
  },
  /**  swap actions stuff */

  [swapActionsError]: (state, action) => {
    state.swapActionsError = action.payload;
    state.swapActionsMessageNumber = undefined;
  },
  [resetSwapActions]: (state) => {
    state.swapActions = null;
    state.swapActionError = null;
    state.swapActionsMessageNumber = undefined;
  },
  [swapActions]: (state, action) => {
    let message = action.payload;
    if (!message.content) return;
    let swapActionsMessages =
      state.swapActionsMessageNumber ||
      Array.from({ length: message.count || 0 }, (v, k) => k + 1);
    let actions = [...(state.swapActions || [])];

    if (swapActionsMessages.includes(+message.message_count)) {
      actions = [...actions, ...message.content];
    }
    swapActionsMessages = swapActionsMessages.filter(
      int => int !== message.message_count
    );
    actions = orderBy(actions, ["timestamp"], ["desc"]);
    state.swapActions = actions;
    state.swapActionError = null;
    state.swapActionsMessageNumber =
      swapActionsMessages.length > 0 ? swapActionsMessages : undefined;
  },
  [isFetchingSwapActions]: (state, action) => {
    state.isFetchingSwapActions = action.payload;
    state.swapActionError = null;
    state.swapActionsMessageNumber = undefined;
  },
  [openDoorRequest]: (state, action) => {
    const pendingOpenDoors = state.pendingOpenDoors;
    state.pendingOpenDoors = [...pendingOpenDoors, action.payload];
  },
  /** no error handling yet */
  [openDoorError]: (state, action) => {
    //state
  },
  [openDoorSuccess]: (state, action) => {
    const pendingOpenDoors = state.pendingOpenDoors;
    let newPendingOpenDoors = pendingOpenDoors.filter(pendingDoor => {
      if (
        pendingDoor["sp_serial_number"] !== action.payload["sp_serial_number"]
      ) {
        return true;
      }
      return pendingDoor["charge_modul"] !== action.payload["box_number"];
    });
    state.pendingOpenDoors = newPendingOpenDoors;
  }
});

export default sharingPointsReducer;
