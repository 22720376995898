import { createReducer } from "redux-starter-kit";
import {
  registrationLoading,
  registrationSuccess,
  registrationError,
  confirmLoading,
  confirmSuccess,
  confirmError
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  message: null,
  username: null,
  needsConfirm: false,
  finished: false
};

const registrationReducer = createReducer(initialState, {
  [registrationLoading]: (state, action) => {
    state.loading = true;
  },
  [registrationSuccess]: (state, action) => {
    return {
      ...initialState,
      username: action.payload,
      needsConfirm: true
    };
  },
  [registrationError]: (state, action) => {
    state.loading = false;
    state.error = true;
    state.message = action.payload;
    state.username = null;
  },
  [confirmLoading]: (state, action) => {
    state.loading = true;
  },
  [confirmSuccess]: (state, action) => {
    return {
      ...initialState,
      username: state.username,
      finished: true
    };
  },
  [confirmError]: (state, action) => {
    state.error = true;
    state.message = action.payload;
  }
});

export default registrationReducer;
