import React from "react";
import PropTypes from "prop-types";
import { Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const propTypes = {
  errors: PropTypes.object.isRequired,
};

const RFHErrorMessages = ({ errors, title }) => {
  const { t } = useTranslation();
  return Object.keys(errors).length > 0 ? (
    <Message className="error-panel" error style={{ fontSize: "1.2em" }}>
      <Message.Header>
        <Grid>
          <Grid.Column width="16" textAlign="left">
            {title ? title : t("errors")}
          </Grid.Column>
        </Grid>
      </Message.Header>
      <Message.List>
        {Object.keys(errors).length > 0
          ? Object.keys(errors).map((key, index) => {
              return (
                <Message.Item key={`error-${index}`}>
                  {t(errors[key].message)}
                </Message.Item>
              );
            })
          : ""}
      </Message.List>
    </Message>
  ) : (
    ""
  );
};

RFHErrorMessages.propTypes = propTypes;
export { RFHErrorMessages };
