/** @format */

import { createReducer } from "redux-starter-kit";
import {
  systemErrors,
  getLastEvaluatedKey,
  errorsIsFetching,
  getStationIds,
  filteredErrorsIsFetching,
  resetFetch
} from "../actions";

const initialState = {
  errors: null,
  lastEvaluatedKey: null,
  fetching: false,
  stationIds: null,
  filteredfetching: false,
};
const errorsReducer = createReducer(initialState, {
  [systemErrors]: (state, action) => {
    const existingErrors = state.errors || [];
    let data;
    if (action.payload === null) {
      data = [];
    } else {
      data = [...existingErrors, ...action.payload.data].sort((x, y) => {
        return y.timestamp - x.timestamp;
      });
    }
    return {
      ...state,
      errors: data,
    };
  },

  [resetFetch]: (state, action) => {
    let data;
    if (action.payload === null) {
      data = [];
    } else {
      data = [...action.payload.data].sort((x, y) => {
        return y.timestamp - x.timestamp;
      });
    }
    return {
      ...state,
      errors: data,
    };
  },
  [getLastEvaluatedKey]: (state, action) => {
    return {
      ...state,
      lastEvaluatedKey: action.payload,
    };
  },
  [errorsIsFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [filteredErrorsIsFetching]: (state, action) => {
    return {
      ...state,
      filteredfetching: action.payload,
    };
  },
  [getStationIds]: (state, action) => {
    const data = action.payload.data.map(item => {
      return {
        key: item,
        value: item,
        text: item,
      };
    });
    return {
      ...state,
      stationIds: data,
    };
  },
});

export default errorsReducer;
