import { createAction } from "redux-starter-kit";

export const notesByContextHashKey = createAction("NOTES_BY_CONTEXT_HASH_KEY");
export const notesIsFetching = createAction("NOTES_IS_FETCHING");
export const isSavingNote = createAction("IS_SAVING_NOTE");
export const resetNotes = createAction("RESET_NOTES");
export const setContextHashKeyForNotes = createAction(
  "SET_CONTEXT_HASH_KEY_FOR_NOTES"
);
export const notesError = createAction("NOTES_ERROR");

export const getNotesByContextHashKey = (hash_key) => (dispatch, getState) => {
  dispatch(resetNotes());
  dispatch(notesIsFetching(true));
  dispatch(setContextHashKeyForNotes(hash_key));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getNotesByContextHashKey",
      data: { context_hash_key: hash_key },
    },
  });
};

export const gotNotesByContextHashKey = (message) => (dispatch, getState) => {
  dispatch(notesIsFetching(false));
  switch (message.statusCode) {
    case 200 || 204:
      dispatch(notesByContextHashKey(message.content));
      break;
    case 403:
      dispatch(
        notesError({
          key: "Authentication",
          displayMessage: "Authentication error: No right to retrieve Notes",
        })
      );
      break;
    default:
  }
};

export const updateNote = (message) => (dispatch, getState) => {
  let updatedNote = message.content;
  const state = getState();
  const existingNotes = state.notes.notes;
  const contextHashKey = state.notes.contextHashKey;
  if (!existingNotes) {
    return;
  }
  if (contextHashKey && contextHashKey === updatedNote.context_hash_key) {
    let newNotes;
    let found = existingNotes.find(
      (note) => note.hash_key === updatedNote.hash_key
    );
    if (!existingNotes) {
      return;
    }
    if (found) {
      newNotes = existingNotes.map((note) => {
        return note.hash_key === updatedNote.hash_key
          ? Object.assign({}, note, updatedNote)
          : note;
      });
    } else {
      newNotes = [updatedNote, ...existingNotes];
    }
    dispatch(isSavingNote(false));
    dispatch(notesByContextHashKey(newNotes));
  }
};

export const createNote = (data) => (dispatch, getState) => {
  let currentUser = getState().session.username;
  let newData = { ...data, creator: currentUser };
  dispatch(isSavingNote(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createNote",
      data: newData,
    },
  });
};
