import { navigate } from "@reach/router";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAxiosService } from "../../helper/axiosService";
import { createBrowserHistory } from "history";
import qs from "qs";
import {
  SharingPointDropdownSearch,
  TableScreenTemplate,
  SWBSqlTable,
  SWBDatePicker,
  ErrorsAckDropdownSearch,
  ErrorsContextDropdownSearch,
  ErrorsCategoryDropdownSearch,
} from "../UtilComponents";
import { useTranslation } from "react-i18next";
import { AxiosContext } from "../../context/AxiosContext";

export default () => {
  const [sp_serial_number, setSelectedStation] = useState();
  const [context, setSelectedContext] = useState();
  const [category, setSelectedCategory] = useState();
  const [hasBeenAck, setSelectedHasBeenAck] = useState("Not acknowleged");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const {axiosInstance}=useContext(AxiosContext)

  const [isInitialized, setIsInitialized] = useState();
  let { t } = useTranslation("descriptions");
  // fetch logic
  const {
    setFetchedData,
    fetchedData,
    isLoading,
    fetchData,
    sum,
    sortParams,
    setSortParams,
    sortColumn,
    setPagination,
    pagination,
  } = useAxiosService({
    backendRoute: "/error-history",
  });

  useEffect(() => {
    return () => {
      setFetchedData(null);
    };
  }, []);

  const reFetch = () => {
    fetchData(false, {
      sp_serial_number: sp_serial_number,
      startDate,
      endDate,
      context,
      category,
      has_been_ack_readable: hasBeenAck,
      sorted_by: sortParams.sorted_by,
      order: sortParams.order,
    });
  };

  const headers = useMemo(
    () => [
      {
        key: "error_source_id",
        header: "Station",
        isLink: true,
        navigateTo: "/sharingpoints/",
      },
      { key: "short_description", header: "Short Description" },
      { key: "error_count", header: "Error Count" },
      { key: "category", header: "Category" },
      { key: "context", header: "Context" },
      { key: "ack_by_username", header: "Accepted by" },
      { key: "has_been_ack_readable", header: "Acknowledged" },
      { key: "region", header: "Region" },
      { key: "created_at", header: "Datum", isDate: true },
      { key: "long_description", header: "Long Description" },
      { key: "error_hash_key", header: "Action" },
    ],
    []
  );

  const resetFilterButtonHandler = () => {
    setSelectedStation(null);
    setSelectedContext(null);
    setSelectedCategory(null);
    setSelectedHasBeenAck(null);
    setStartDate(null);
    setEndDate(null);
    navigate("/error-history");
  };

  const history = createBrowserHistory();

  useEffect(() => {
    if(!axiosInstance) return
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);

    setSortParams({
      order: filtersFromParams.order,
      sorted_by: filtersFromParams.sorted_by,
    });

    setSelectedStation(filtersFromParams.error_source_id);
    setSelectedContext(filtersFromParams.context);
    setSelectedCategory(filtersFromParams.category);
    setSelectedHasBeenAck(
      filtersFromParams.has_been_ack_readable
        ? filtersFromParams.has_been_ack_readable
        : "Not acknowleged"
    );

    setEndDate(filtersFromParams.endDate);

    setStartDate(filtersFromParams.startDate);
    setPagination(pagination);

    fetchData(false, {
      error_source_id: filtersFromParams.error_source_id,
      startDate: filtersFromParams.startDate,
      endDate: filtersFromParams.endDate,
      context: filtersFromParams.context,
      category: filtersFromParams.category,
      has_been_ack_readable: filtersFromParams.has_been_ack_readable
        ? filtersFromParams.has_been_ack_readable
        : "Not acknowleged",
      sorted_by: filtersFromParams.sorted_by,
      order: filtersFromParams.order,
      page: pagination,
    });
    setIsInitialized(true);
  }, [axiosInstance]);

  useEffect(() => {
    history.push(
      `error-history&sp_serial_number=${
        sp_serial_number ? sp_serial_number : ""
      }&context=${context ? context : ""}&category=${
        category ? category : ""
      }&startDate=${startDate ? startDate : ""}&endDate=${
        endDate ? endDate : ""
      }&has_been_ack_readable=${hasBeenAck ? hasBeenAck : ""}&sorted_by=${
        sortParams.sorted_by ? sortParams.sorted_by : ""
      }&order=${sortParams.order ? sortParams.order : ""}`
    );
    if (isInitialized) {
      fetchData(false, {
        sp_serial_number: sp_serial_number,
        startDate,
        endDate,
        context,
        category,
        has_been_ack_readable: hasBeenAck,
        sorted_by: sortParams.sorted_by,
        order: sortParams.order,
        page: pagination,
      });
    }
  }, [
    sp_serial_number,
    startDate,
    endDate,
    context,
    category,
    hasBeenAck,
    sortParams.order,
    sortParams.sorted_by,
    
  ]);

  return (
    <TableScreenTemplate
      hasFilter={true}
      hasSegment={true}
      hasFilterButton={true}
      /*actions={
        <>
          <ExportDataAsCsv
            data={() => fetchedData(null, { export: true })}
            filename={`station-usage-export-${new Date().toUTCString()}.csv`}
          />
        </>
      }*/
      {...{
        fetchData,
        sum,
        resetFilterButtonHandler,
      }}
      filter_components={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}>
          <div className="swap-table-filter-container">
            <div className="filter-components">
              <SWBDatePicker
                {...{ endDate, setEndDate, setStartDate, startDate }}
              />
            </div>

            <div className="filter-components">
              <SharingPointDropdownSearch
                {...{ setSelectedStation, sp_serial_number }}
              />
            </div>
            <div className="filter-components">
              <ErrorsAckDropdownSearch
                {...{ setSelectedHasBeenAck, hasBeenAck }}
              />
            </div>
            <div className="filter-components">
              <ErrorsCategoryDropdownSearch
                {...{ setSelectedCategory, category }}
              />
            </div>
            <div className="filter-components">
              <ErrorsContextDropdownSearch
                {...{ setSelectedContext, context }}
              />
            </div>
          </div>
        </div>
      }
      table={
        <SWBSqlTable
          {...{
            reFetch,
            sortColumn,
            data: fetchedData,
            headers,
            isLoading,
            fetchData: fetchData,
            sum,
            sortParams,
            setSortParams,

            searchParams: {
              sp_serial_number,
              startDate,
              endDate,
              context,
              has_been_ack_readable: hasBeenAck,
              sorted_by: sortParams.sorted_by,
              order: sortParams.order,
            },
          }}
        />
      }
      header_name="Error History Table"
      description={t("error_history_table")}
      data={fetchedData}
      sum_count={fetchedData && sum}
    />
  );
};
