/** @format */

import { createReducer } from "redux-starter-kit";
import {
  batteryFetching,
  batteriesError,
  batteries,
  //getBatteryTypes,
  gotBatteryTypes,
  gotBatteryPools,
  batteryPools,
  batteryTypes,
  batteryBySerialNumber,
  batteryBySerialNumberFetching,
  batteryBySerialNumberError,
  batteryTypesFetching,
  batteryPoolsFetching,
  batteryIsUpdating,
  batteryByUuid,
} from "../actions";

const initialState = {
  fetching: false,
  error: false,
  batteries: [],
  batteriesTotal: 0,
  loadedBatteries: 0,

  batteryTypes: null,
  batteryTypesFetching: false,
  batteryPools: null,
  batteryPoolsFetching: false,
  batteryByUuid: {},
  batteryBySerialNumber: null,
  batteryBySerialNumberFetching: false,
  isSaving: false,
  batteryBySerialNumberError: null,
};

const batteriesReducer = createReducer(initialState, {
  [batteryIsUpdating]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload,
    };
  },
  [batteryFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [batteriesError]: (state, action) => {
    return {
      ...state,
      error: action.payload,
    };
  },
  [batteries]: (state, action) => {
    let data = action.payload.batteries;
    return {
      ...state,
      batteries: data,
      batteriesTotal: action.payload.batteriesTotal,
      //fetching: action.payload.fetching,
      loadedBatteries: action.payload.loadedBatteries,
    };
  },

  [batteryTypesFetching]: (state, action) => {
    return {
      ...state,
      batteryTypesFetching: action.payload,
    };
  },
  [batteryTypes]: (state, action) => {
    return {
      ...state,
      batteryTypes: action.payload.batteryTypes,
    };
  },
  [batteryPoolsFetching]: (state, action) => {
    return {
      ...state,
      batteryPoolsFetching: action.payload,
    };
  },
  [batteryPools]: (state, action) => {
    return {
      ...state,
      batteryPools: action.payload.batteryPools,
      batteryPoolsFetching: false,
    };
  },

  [batteryBySerialNumber]: (state, action) => {
    return {
      ...state,
      batteryBySerialNumber: action.payload,
    };
  },

  [batteryBySerialNumberFetching]: (state, action) => {
    return {
      ...state,
      batteryBySerialNumberFetching: action.payload,
    };
  },
  [batteryBySerialNumberError]: (state, action) => {},
  [batteryByUuid]: (state, action) => {
    return {
      ...state,
      batteryByUuid: action.payload,
    };
  },
});
export default batteriesReducer;
