import { createAction } from "redux-starter-kit";

export const addressFetching = createAction("ADDRESS_FETCHING");
export const addresses = createAction("ADDRESSES");
export const addressesError = createAction("COMPANIES_ERROR");

export const getAddresses = () => (dispatch, getState) => {
  dispatch(addressFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllAddressesFull"
    }
  });
};

export const gotAddresses = message => (dispatch, getState) => {
  console.log("action got addresses", message);
  dispatch(
    addresses({
      addresses: message.content,
      total: message.count
    })
  );
};
