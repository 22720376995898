import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  GridColumn,
  Segment,
  Header,
  Label,
  Icon,
  Button,
  List,
  ListItem,
  Confirm,
  Loader,
} from "semantic-ui-react";
import { SimpleMap } from "../../components/helpers/maps/SimpleMarkerMap";
import { upperFirst, toUpper } from "lodash";
import BatteryImage from "../../components/SharingPoints/BatteryImage";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import { useDispatch } from "react-redux";
import { useSharingPoint } from "../../hooks/store/use-sharing-point";
import { AxiosContext } from "../../context/AxiosContext";

export default ({ spSerialNumber }) => {
  const [state, setState] = useState({ station: null });
  const [selectedModuleNumber, setSelectedModuleNumber] = useState(0);

  const { timeSpans } = useFormatTimestamp();
  const [showOpenDoorConfirmation, setshowOpenDoorConfirmation] =
    useState(false);

  const dispatch = useDispatch();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchSharingpoints = async (search) => {
    const { data } = await axiosInstance.get(
      "/sharingpoints/" + spSerialNumber
    );

    setState({ ...state, station: data.data });
  };

  const { station } = state;

  useEffect(() => {
    fetchSharingpoints();
  }, []);

  useEffect(() => {
    const intervalId = () => {
      setInterval(() => {
        fetchSharingpoints();
      }, 5000);
    };
    intervalId();
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Container style={{ marginTop: "80px", marginBottom: "80px" }}>
      {!station && (
        <Loader
          style={{ position: "fixed", marginLeft: "45%", marginTop: "400px" }}
          inline
          active
          size="large"
        />
      )}
      {station && (
        <Grid columns="1">
          <GridColumn>
            <Segment style={{ height: "200px", padding: "0px" }}>
              <SimpleMap
                {...{
                  latLng: [
                    state.station.gps_latitude,
                    state.station.gps_longitude,
                  ],
                }}
              />
            </Segment>
          </GridColumn>
          <GridColumn>
            <Segment>
              <Grid columns="1">
                <GridColumn>
                  <Header style={{ float: "left" }}>
                    {" "}
                    {station.nick_name}{" "}
                  </Header>{" "}
                  <Label
                    content={station.sp_serial_number}
                    circular
                    color="black"
                    size="tiny"
                    style={{ float: "left" }}
                  />
                  <Label
                    size="tiny"
                    circular
                    style={{ float: "right", verticalAlign: "middle" }}
                    content="online"
                    color="green"
                  />
                  <Label
                    size="tiny"
                    circular
                    style={{ float: "right", verticalAlign: "middle" }}
                    content={station.sharing_point_type}
                    color="blue"
                  />
                </GridColumn>
                <GridColumn style={{ padding: "0px 14px 14px 14px" }}>
                  <span style={{ color: "black" }}>
                    {station.street} {station.street_number}, {station.zipcode}{" "}
                    {station.city}
                  </span>
                </GridColumn>

                {station.status &&
                  Object.keys(station.status.box_status_check).map(
                    (key, index) => (
                      <GridColumn
                        style={{ padding: "0px 14px 14px 14px" }}
                        key={index}
                      >
                        <span style={{ color: "black" }}>
                          {upperFirst(key).replace("_", " ")} x{" "}
                          {station.status.box_status_check[key]}{" "}
                        </span>
                      </GridColumn>
                    )
                  )}
              </Grid>
            </Segment>
          </GridColumn>
          {station.modules &&
            station.modules.map((item, index) => (
              <GridColumn>
                {item.box_type === "ChargeModule" && (
                  <Segment>
                    <Grid columns="1">
                      <GridColumn>
                        <Header style={{ float: "left" }}>
                          Module {item.box_number} &middot; {item.box_type}
                        </Header>
                        <Icon
                          color={item.door_state === "locked" ? "green" : "red"}
                          name={
                            item.door_state === "locked" ? "lock" : "unlock"
                          }
                          style={{ float: "right" }}
                        />
                      </GridColumn>

                      <PironexSlots
                        {...{
                          moduleNumber: index,
                          item,
                          timeSpans,
                          station,
                          setshowOpenDoorConfirmation,
                          showOpenDoorConfirmation,
                          dispatch,
                          selectedModuleNumber,
                        }}
                      />
                      <OkaiASlots
                        {...{
                          item,
                          timeSpans,
                          station,
                          setshowOpenDoorConfirmation,
                          showOpenDoorConfirmation,
                          dispatch,
                          moduleNumber: index,
                          selectedModuleNumber,
                        }}
                      />
                      <OkaiBSlots
                        {...{
                          moduleNumber: index,
                          item,
                          timeSpans,
                          station,
                          setshowOpenDoorConfirmation,
                          showOpenDoorConfirmation,
                          dispatch,
                          selectedModuleNumber,
                        }}
                      />
                      <SegwaySlots
                        {...{
                          moduleNumber: index,
                          index,
                          item,
                          timeSpans,
                          station,
                          setshowOpenDoorConfirmation,
                          showOpenDoorConfirmation,
                          dispatch,
                          selectedModuleNumber,
                        }}
                      />

                      <GridColumn>
                        <Button
                          disabled={item.door_state !== "locked"}
                          size="big"
                          style={{ borderRadius: "10px" }}
                          circular
                          fluid
                          content="Open"
                          color="orange"
                          onClick={() => {
                            setSelectedModuleNumber(item.box_number);
                            setshowOpenDoorConfirmation(true);
                          }}
                        />
                      </GridColumn>
                    </Grid>
                  </Segment>
                )}

                {item.box_type === "PowerModule" && (
                  <Segment>
                    <Grid columns="1">
                      <GridColumn>
                        <Header style={{ float: "left" }}>
                          Module {index + 1} &middot; {item.box_type}
                        </Header>
                        <Icon
                          color={item.door_state === "locked" ? "green" : "red"}
                          name={
                            item.door_state === "locked" ? "lock" : "unlocked"
                          }
                          style={{ float: "right" }}
                        />
                      </GridColumn>
                      <GridColumn
                        textAlign="center"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          size="big"
                          style={{
                            width: "100%",
                            float: "center",
                            borderRadius: "10px",
                          }}
                          content={toUpper(item.FI_state)}
                          circular
                          color={item.FI_state !== "on" ? "grey" : "green"}
                          fluid
                          basic={item.FI_state !== "on"}
                        />
                      </GridColumn>
                      <GridColumn>
                        <Segment style={{ paddingBottom: "30px" }}>
                          <Grid columns="1">
                            <GridColumn>
                              <Header>General</Header>
                            </GridColumn>
                            {Object.keys(item)
                              .splice(0, 6)
                              .sort()
                              .map((key) => (
                                <>
                                  {key !== "errors" && (
                                    <GridColumn
                                      style={{ padding: "0px 14px 0px 14px" }}
                                    >
                                      <span style={{ color: "black" }}>
                                        {upperFirst(key.replaceAll("_", " "))}
                                      </span>
                                      <span
                                        style={{
                                          float: "right",
                                          color: "#ff5a00",
                                        }}
                                      >
                                        {key.includes("updated_at")
                                          ? timeSpans(item[key])
                                          : item[key]}
                                      </span>
                                    </GridColumn>
                                  )}
                                </>
                              ))}
                          </Grid>
                        </Segment>
                      </GridColumn>
                      {/* <GridColumn
                        textAlign="center"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        <Button
                          size="big"
                          style={{ borderRadius: "10px" }}
                          content="Reboot"
                          circular
                          color="orange"
                          fluid
                        />
                      </GridColumn> */}
                    </Grid>
                  </Segment>
                )}
                {item.box_type === "ThermalModule" && (
                  <Segment>
                    <Grid columns="1">
                      <GridColumn>
                        <Header style={{ float: "left" }}>
                          Module {index + 1} &middot; {item.box_type}
                        </Header>
                        <Icon
                          color={item.door_state === "locked" ? "green" : "red"}
                          name={
                            item.door_state === "locked" ? "lock" : "unlocked"
                          }
                          style={{ float: "right" }}
                        />
                      </GridColumn>

                      <GridColumn textAlign="center">
                        <Button
                          size="big"
                          style={{ borderRadius: "10px", width: "100%" }}
                          content={toUpper(item.thermal_module_state)}
                          basic={item.thermal_module_state === "off"}
                          circular
                          color="orange"
                        />
                      </GridColumn>

                      <GridColumn>
                        <Segment
                          style={{
                            paddingBottom: "30px",
                          }}
                        >
                          <Grid columns="1">
                            <GridColumn>
                              <Header as="h4"> General</Header>
                            </GridColumn>

                            <GridColumn
                              style={{
                                padding: "0px 14px 0px 14px",
                              }}
                            >
                              <span style={{ color: "black" }}>State:</span>{" "}
                              <span
                                style={{ float: "right", color: "#ff5a00" }}
                              >
                                {toUpper(item.thermal_module_state)}
                              </span>
                            </GridColumn>
                            <GridColumn
                              style={{
                                float: "right",

                                padding: "0px 14px 0px 14px",
                              }}
                            >
                              <span style={{ color: "black" }}>
                                Updated at:
                              </span>{" "}
                              <span
                                style={{ float: "right", color: "#ff5a00" }}
                              >
                                {timeSpans(
                                  item.thermal_module_state_updated_at
                                )}
                              </span>
                            </GridColumn>
                          </Grid>
                        </Segment>
                      </GridColumn>
                      {/* <GridColumn style={{ color: "black" }}>
                        <Button
                          content="Switch On"
                          circular
                          color="orange"
                          fluid
                          size="big"
                          style={{ borderRadius: "10px", width: "100%" }}
                        />
                      </GridColumn> */}
                    </Grid>
                  </Segment>
                )}
              </GridColumn>
            ))}

          <GridColumn>
            <Swaps {...{ spSerialNumber, timeSpans }} />
          </GridColumn>
        </Grid>
      )}
    </Container>
  );
};

const OkaiASlots = ({
  item,
  timeSpans,
  station,
  setshowOpenDoorConfirmation,
  showOpenDoorConfirmation,
  dispatch,
  index,
  selectedModuleNumber,
}) => {
  const [selected, setSelected] = useState("slot_1");
  return (
    ["Okai A"].includes(item.box_sub_type) && (
      <>
        {station && (
          <OpenDoor
            {...{
              selectedModuleNumber,
              sp_serial_number: station.sp_serial_number,
              setshowOpenDoorConfirmation,
              showOpenDoorConfirmation,
              dispatch,
            }}
          />
        )}
        <GridColumn>
          <Grid columns="3">
            <>
              <GridColumn textAlign="center">
                <Button
                  size="medium"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_1" !== selected}
                  color="orange"
                  content={item.battery_state["slot_1"].slot_state}
                  onClick={() => setSelected("slot_1")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="medium"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_3" !== selected}
                  color="orange"
                  content={item.battery_state["slot_3"].slot_state}
                  onClick={() => setSelected("slot_3")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="medium"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_2" !== selected}
                  color="orange"
                  content={item.battery_state["slot_2"].slot_state}
                  onClick={() => setSelected("slot_2")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="medium"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_4" !== selected}
                  color="orange"
                  content={item.battery_state["slot_4"].slot_state}
                  onClick={() => setSelected("slot_4")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="medium"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_5" !== selected}
                  color="orange"
                  content={item.battery_state["slot_5"].slot_state}
                  onClick={() => setSelected("slot_5")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="medium"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_6" !== selected}
                  color="orange"
                  content={item.battery_state["slot_6"].slot_state}
                  onClick={() => setSelected("slot_6")}
                />
              </GridColumn>
            </>
          </Grid>
        </GridColumn>
        {item.battery_state[selected].slot_state !== "Empty" && (
          <GridColumn>
            <Segment style={{ paddingTop: "30px" }}>
              <Grid>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="10">
                  <Header as="h3">
                    {upperFirst(selected).replace("_", " ")} &middot;{" "}
                    {item.box_sub_type}
                  </Header>
                </GridColumn>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="6">
                  <BatteryImage
                    float="right"
                    type={upperFirst(item.box_sub_type)}
                    height="auto"
                    width="100%"
                  />
                </GridColumn>

                <GridColumn width="16">
                  <Header as="h4" style={{ float: "left" }}>
                    Temperatures
                  </Header>
                </GridColumn>

                <GridColumn width="16">
                  <List>
                    {Object.keys(item.temperatures[selected])
                      .sort()
                      .map((key) => {
                        return (
                          <ListItem style={{ color: "black" }}>
                            <span>
                              {upperFirst(key).replaceAll("_", " ")}:{" "}
                            </span>
                            <span style={{ float: "right", color: "#ff5a00" }}>
                              {key.includes("updated_at")
                                ? timeSpans(item.temperatures[selected][key])
                                : item.temperatures[selected][key] + "°C"}
                            </span>
                          </ListItem>
                        );
                      })}
                  </List>
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
        )}
      </>
    )
  );
};

const OkaiBSlots = ({
  item,
  timeSpans,
  station,
  setshowOpenDoorConfirmation,
  showOpenDoorConfirmation,
  dispatch,
  selectedModuleNumber,
}) => {
  const [selected, setSelected] = useState("slot_1");
  return (
    ["Okai B"].includes(item.box_sub_type) && (
      <>
        {station && (
          <OpenDoor
            {...{
              selectedModuleNumber,
              sp_serial_number: station.sp_serial_number,
              setshowOpenDoorConfirmation,
              showOpenDoorConfirmation,
              dispatch,
            }}
          />
        )}
        <GridColumn>
          <Grid columns="2">
            <>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_1" !== selected}
                  color="orange"
                  content={item.battery_state["slot_1"].slot_state}
                  onClick={() => setSelected("slot_1")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_3" !== selected}
                  color="orange"
                  content={item.battery_state["slot_3"].slot_state}
                  onClick={() => setSelected("slot_3")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_2" !== selected}
                  color="orange"
                  content={item.battery_state["slot_2"].slot_state}
                  onClick={() => setSelected("slot_2")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_4" !== selected}
                  color="orange"
                  content={item.battery_state["slot_4"].slot_state}
                  onClick={() => setSelected("slot_4")}
                />
              </GridColumn>
            </>
          </Grid>
        </GridColumn>
        {item.battery_state[selected].slot_state !== "Empty" && (
          <GridColumn>
            <Segment style={{ paddingTop: "30px" }}>
              <Grid>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="10">
                  <Header as="h3">
                    {upperFirst(selected).replace("_", " ")} &middot;{" "}
                    {item.box_sub_type}
                  </Header>
                </GridColumn>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="6">
                  <BatteryImage
                    float="right"
                    type={upperFirst(item.box_sub_type)}
                    height="auto"
                    width="100%"
                  />
                </GridColumn>

                <GridColumn width="16">
                  <Header as="h4" style={{ float: "left" }}>
                    Temperatures
                  </Header>
                </GridColumn>

                <GridColumn width="16">
                  <List>
                    {Object.keys(item.temperatures[selected])
                      .sort()
                      .map((key) => {
                        return (
                          <ListItem style={{ color: "black" }}>
                            <span>
                              {upperFirst(key).replaceAll("_", " ")}:{" "}
                            </span>
                            <span style={{ float: "right", color: "#ff5a00" }}>
                              {key.includes("updated_at")
                                ? timeSpans(item.temperatures[selected][key])
                                : item.temperatures[selected][key] + "°C"}
                            </span>
                          </ListItem>
                        );
                      })}
                  </List>
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
        )}
      </>
    )
  );
};

const PironexSlots = ({
  item,
  timeSpans,
  station,
  setshowOpenDoorConfirmation,
  showOpenDoorConfirmation,
  dispatch,
  selectedModuleNumber,
}) => {
  const [selected, setSelected] = useState("slot_1");
  return (
    ["greenpack", "torrot", "kumpan"].includes(item.box_sub_type) && (
      <>
        {station && (
          <OpenDoor
            {...{
              selectedModuleNumber,
              sp_serial_number: station.sp_serial_number,
              setshowOpenDoorConfirmation,
              showOpenDoorConfirmation,
              dispatch,
            }}
          />
        )}
        <GridColumn>
          <Grid columns="1">
            <>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_1" !== selected}
                  color="orange"
                  content={item.battery_state["slot_1"].slot_state}
                  onClick={() => setSelected("slot_1")}
                />
              </GridColumn>
            </>
          </Grid>
        </GridColumn>
        {item.battery_state[selected].slot_state !== "Empty" && (
          <GridColumn>
            <Segment style={{ paddingTop: "30px" }}>
              <Grid>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="10">
                  <Header as="h3">
                    {upperFirst(selected).replace("_", " ")} &middot;{" "}
                    {item.box_sub_type}
                  </Header>
                </GridColumn>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="6">
                  <BatteryImage
                    float="right"
                    type={upperFirst(item.box_sub_type)}
                    height="auto"
                    width="100%"
                  />
                </GridColumn>

                <GridColumn width="16">
                  <Header as="h4" style={{ float: "left" }}>
                    Temperatures
                  </Header>
                </GridColumn>

                <GridColumn width="16">
                  <List>
                    {Object.keys(item.temperatures[selected])
                      .sort()
                      .map((key) => {
                        return (
                          <ListItem style={{ color: "black" }}>
                            <span>
                              {upperFirst(key).replaceAll("_", " ")}:{" "}
                            </span>
                            <span style={{ float: "right", color: "#ff5a00" }}>
                              {key.includes("updated_at")
                                ? timeSpans(item.temperatures[selected][key])
                                : item.temperatures[selected][key] + "°C"}
                            </span>
                          </ListItem>
                        );
                      })}
                  </List>
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
        )}
      </>
    )
  );
};

const SegwaySlots = ({
  item,
  timeSpans,
  station,
  setshowOpenDoorConfirmation,
  showOpenDoorConfirmation,
  dispatch,

  selectedModuleNumber,
}) => {
  const [selected, setSelected] = useState("slot_1");
  return (
    ["Segway"].includes(item.box_sub_type) && (
      <>
        {station && (
          <OpenDoor
            {...{
              selectedModuleNumber,
              sp_serial_number: station.sp_serial_number,
              setshowOpenDoorConfirmation,
              showOpenDoorConfirmation,
              dispatch,
            }}
          />
        )}
        <GridColumn>
          <Grid columns="2">
            <>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_1" !== selected}
                  color="orange"
                  content={item.battery_state["slot_1"].slot_state}
                  onClick={() => setSelected("slot_1")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_3" !== selected}
                  color="orange"
                  content={item.battery_state["slot_3"].slot_state}
                  onClick={() => setSelected("slot_3")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_2" !== selected}
                  color="orange"
                  content={item.battery_state["slot_2"].slot_state}
                  onClick={() => setSelected("slot_2")}
                />
              </GridColumn>
              <GridColumn textAlign="center">
                <Button
                  size="big"
                  style={{ width: "100%", textAlign: "center" }}
                  circular
                  basic={"slot_4" !== selected}
                  color="orange"
                  content={item.battery_state["slot_4"].slot_state}
                  onClick={() => setSelected("slot_4")}
                />
              </GridColumn>
            </>
          </Grid>
        </GridColumn>
        {item.battery_state[selected].slot_state !== "Empty" && (
          <GridColumn>
            <Segment style={{ paddingTop: "30px" }}>
              <Grid>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="10">
                  <Header as="h3">
                    {upperFirst(selected).replace("_", " ")} &middot;{" "}
                    {item.box_sub_type}
                  </Header>
                </GridColumn>
                <GridColumn style={{ padding: "0px 14px 0px 14px" }} width="6">
                  <BatteryImage
                    float="right"
                    type={upperFirst(item.box_sub_type)}
                    height="auto"
                    width="100%"
                  />
                </GridColumn>

                <GridColumn width="16">
                  <Header as="h4" style={{ float: "left" }}>
                    Temperatures
                  </Header>
                </GridColumn>

                <GridColumn width="16">
                  <List>
                    {Object.keys(item.temperatures[selected])
                      .sort()
                      .map((key) => {
                        return (
                          <ListItem style={{ color: "black" }}>
                            <span>
                              {upperFirst(key).replaceAll("_", " ")}:{" "}
                            </span>
                            <span style={{ float: "right", color: "#ff5a00" }}>
                              {key.includes("updated_at")
                                ? timeSpans(item.temperatures[selected][key])
                                : item.temperatures[selected][key] + "°C"}
                            </span>
                          </ListItem>
                        );
                      })}
                  </List>
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
        )}
      </>
    )
  );
};

const OpenDoor = ({
  showOpenDoorConfirmation,
  setshowOpenDoorConfirmation,
  sp_serial_number,
  selectedModuleNumber,
}) => {
  const { sendRestApiCommand } = useSharingPoint({
    spSerialNumber: sp_serial_number,
  });
  const handelConfirm = () => {
    setshowOpenDoorConfirmation(false);
    sendRestApiCommand({
      request: "doorOpen",
      data: {
        sp_serial_number: sp_serial_number,
        charge_modul: selectedModuleNumber,
      },
    });
  };
  const handelCancel = () => {
    setshowOpenDoorConfirmation(false);
  };

  return (
    <Confirm
      open={showOpenDoorConfirmation}
      header="Confirmation"
      content={`Sure you want to open this Module?`}
      onCancel={handelCancel}
      onConfirm={handelConfirm}
    />
  );
};

const Swaps = ({ spSerialNumber, timeSpans }) => {
  const [swaps, setSwaps] = useState();
  const { axiosInstance } = useContext(AxiosContext);
  const fetchSwaps = async () => {
    const { data } = await axiosInstance.get(
      "/swap?station_id=" + spSerialNumber
    );
    setSwaps(data.data);
  };

  useEffect(() => {
    fetchSwaps();
  }, []);
  return (
    <Segment>
      <Grid columns="1">
        <GridColumn>
          <Header style={{ color: "black" }}>Swaps</Header>
        </GridColumn>
        <GridColumn>
          {swaps && (
            <List divided verticalAlign="middle">
              {swaps.slice(0, 10).map((swap, index) => (
                <List.Item style={{ color: "black" }} key={index}>
                  <List.Content floated="right">
                    {upperFirst(swap.battery_type)} x {swap.battery_count}
                  </List.Content>

                  <List.Content>{swap.user.slice(0, 20)}</List.Content>
                  <List.Description>
                    {timeSpans(swap.timestamp)}
                  </List.Description>
                </List.Item>
              ))}
            </List>
          )}
        </GridColumn>
      </Grid>
    </Segment>
  );
};
