import { createReducer } from "redux-starter-kit";
import { acks } from "../actions";

const initialState = {
  acks: null,
  ack: null,
  errors: null,
  total: 0,
};

const acksReducer = createReducer(initialState, {
  [acks]: (state, action) => {
    let newAcks = [action.payload, ...(state.acks || [])];
    return {
      ...state,
      acks: newAcks,
    };
  },
});

export default acksReducer;
export { initialState };
