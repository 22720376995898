import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

import { Aside } from "./Aside";
import { Map } from "./Map";
import "./index.scss";

const diameters = [5, 15, 30, 60, 120, 240];
const diameterOptions = diameters.map((r) => ({
  value: r,
  text: `${r} km`,
  key: r,
}));

const searchPanelProps = {
  diameter: PropTypes.number,
  setDiameter: PropTypes.func.isRequired,
};
/** Helper Component handles diameter*/
const SearchPanel = ({ diameter, setDiameter }) => {
  return (
    <div className="search-panel">
      <Dropdown
        inline
        options={diameterOptions}
        defaultValue={diameter}
        onChange={(e, { value }) => {
          setDiameter(value);
        }}
      />
    </div>
  );
};
SearchPanel.propTypes = searchPanelProps;

const propTypes = {
  latLng: PropTypes.array,
  sharingPoints: PropTypes.array,
  ownLocation: PropTypes.bool,
};
/**
 *
 * @param {array} sharingPoints -
 * @param {array} latLng - geolocation from navigator or default
 * @param {boolean} ownLocation -  all sps should be shown if is false
 * @returns wrapper with Map and Aside
 */
const LandingComponent = ({ sharingPoints, latLng, ownLocation }) => {
  const [diameter, setDiameter] = useState(15);

  return (
    <div className="landing-component">
      <div
        className="map-wrapper"
        style={{ background: "#fed", height: "100vh" }}
      >
        {ownLocation && (
          <SearchPanel diameter={diameter} setDiameter={setDiameter} />
        )}
        {latLng && (
          <Map
            latLng={latLng}
            sharingPoints={sharingPoints}
            diameter={diameter}
            setdiameter={setDiameter}
            ownLocation={ownLocation}
          />
        )}
      </div>
      <div className="aside-wrapper">
        <Aside />
      </div>
    </div>
  );
};
LandingComponent.propTypes = propTypes;
export { LandingComponent };
