
import awsconfig from "../config/awsconfig";
const wsEndpoint = awsconfig.REACT_APP_WS_API;
let timer;

/** TODO implement reconnect handler which sends old connectionId
 * let connectionId
 * let reconnectCount = 0
 * let reconnectLimit = 10
 */

export const setupWebsocket = ({ token }) => {
  return new Promise((resolve, reject) => {
    const url = `${wsEndpoint}?token=${token}`;
    let websocket = new WebSocket(url);
    const receive = (onMessageCB) => {
      websocket.onmessage = (event) => {
        const parsed = JSON.parse(event.data);
        return onMessageCB(parsed);
      };
    };
    const send = (obj) => {
      if (typeof obj !== "string") {
        obj = JSON.stringify(obj);
      }
      if (websocket.readyState === 1) websocket.send(obj);
    };
    const close = () => {
      console.log("close websocket");
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
      websocket.close();
      websocket = undefined;
    };
    websocket.onopen = () => {
      timer = setInterval(() => {
        send({ message: "ping" });
      }, 60000);
      return resolve({ send, receive, close, socket: websocket });
    };
    websocket.onerror = (error) => {
      console.log("error WS websocket", error, websocket);
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
      return reject(error);
    };
  });
};
