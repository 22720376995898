export function isServer() {
  return typeof window === "undefined";
}

export function tokenExpired(timestamp) {
  return Date.now() > timestamp * 1000;
}

export const LOCAL_STORAGE_TOKEN_KEY = "token";
export const LOCAL_STORAGE_USERNAME_KEY = "username";
export const LOCAL_STORAGE_DISPLAYNAME_KEY = "displayname";
export const LOCAL_STORAGE_EXPIRATION_KEY = "tokenexp";
export const LOCAL_STORAGE_NAME = "name";
