/** @format */

import { createReducer } from "redux-starter-kit";
import { restApiResponse } from "../actions";

const initialState = {
  restApiResponse: null,
};

const restApiReducer = createReducer(initialState, {
  [restApiResponse]: (state, action) => {
    let newRestApis = [action.payload, ...(state.restApiResponse || [])];
    return {
      ...state,
      restApiResponse: newRestApis,
    };
  },
});

export default restApiReducer;
export { initialState };
