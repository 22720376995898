import { createReducer } from "redux-starter-kit";
import {
  manufacturerFetching,
  manufacturers,
  manufacturerIsSaving,
} from "../actions";

const initialState = {
  manufacturers: null,
  manufacturer: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0,
};

const manufacturersReducer = createReducer(initialState, {
  [manufacturerFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [manufacturers]: (state, action) => {
    console.log("reducer manufaacturers", action);
    return {
      ...state,
      manufacturers: action.payload.manufacturers,
      total: action.payload.manufacturersTotal,
      fetching: false,
    };
  },
  [manufacturerIsSaving]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload,
    };
  },
  /** not usable as long as there is no return beside ack */
  /*
  [addManufacturer + "x"]: (state, action) => {
    return {
      ...state,
      manufacturers: [action.payload, ...state.manufacturers],
      total: state.total + 1
    };
  }*/
});
export default manufacturersReducer;
