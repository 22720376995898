import { createReducer } from "redux-starter-kit";
import {
  errorHistoryFetching,
  errorHistory,
  errorHistoryIsSaving,
  selectErrorHistory,
} from "../actions";

const initialState = {
  errorHistory: null,
  selectedErrorHistory: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0
};

const errorHistoryReducer = createReducer(initialState, {
  [errorHistoryFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload
    };
  },
  [errorHistory]: (state, action) => {
    return {
      ...state,
      errorHistory: action.payload.errorHistory.sort((item_1, item_2) => item_2.timestamp - item_1.timestamp),
      fetching: false
    };
  },
  [errorHistoryIsSaving]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload
    };
  },
  [selectErrorHistory]: (state, action) => {
    return {
      ...state,
      selectedErrorHistory: action.payload.selectedErrorHistory
    };
  },
});

export default errorHistoryReducer;
