import React from "react";
import { BatteryImage } from "../UtilComponents";
import { upperFirst } from "lodash";

export default ({e, x, y , battery_type }) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        color: "#444",
        padding: 5,
        borderRadius: 5,
        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.75)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <BatteryImage type={battery_type} height={40} width={"auto"} />{" "}
      <span>{`${upperFirst(battery_type)}: ${y} `} </span>
      <span style={{color: "grey"}}>{`(${x})`}</span>
    </div>
  );
};
