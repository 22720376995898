import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { Loader, Container } from "semantic-ui-react";

import "./i18next";

ReactDOM.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <Container textAlign="center" fluid>
          <Loader
            size="huge"
            style={{ margin: "auto", marginTop: "400px" }}
            inline
            active
          />
        </Container>
      }
    >
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
