/** @format */

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAllowedToRead } from "../../helper/permissions";
import {
  gotSettingsForSharingPoint,
  gotSharingPointBoxes,
  locationsDetailData,
  requestOpenDoor,
  resetSwapActions,
  sendCommand,
  startAndStopCharging,
  sharingPointStatus as setSharingPointStatus,
  resetSPErrorsAction,
  resetCMErrorsAction,
  resetBattErrorsAction,
  bmsCMProtocolAction,
  restApiResponse,
} from "../../store/actions";
import { AxiosContext } from "../../context/AxiosContext";

function useSharingPoint({ spSerialNumber }) {
  const dispatch = useDispatch();
  const [commandLoading, setCommandLoading] = useState(false);
  const socket = useSelector((state) => state.session.socket);
  const { axiosInstance } = useContext(AxiosContext);

  // sp-status state
  const { statusError, isFetchingStatus, sharingPointStatus } = useSelector(
    (state) => state.sharingPoints
  );

  //boxes state
  const {
    isFetchingSharingPointBoxes,
    sharingPointBoxesError,
    sharingPointBoxes,
  } = useSelector((state) => state.sharingPoints);

  // commands
  const commands = useSelector((state) => state.sharingPoints.commands);
  //
  const pendingOpenDoors = useSelector(
    (state) => state.sharingPoints.pendingOpenDoors || []
  );

  // init data load and cleanup
  useEffect(() => {
    if (!socket) return;
    if (
      !sharingPointStatus &&
      isAllowedToRead("SharingPointStatus") === true &&
      !isFetchingStatus
    ) {
      // dispatch(fetchSharingPointStatus({ spSerialNumber }));
    }
    if (
      !sharingPointBoxes &&
      isAllowedToRead("SharingPointBox") === true &&
      !isFetchingSharingPointBoxes
    ) {
      // dispatch(getSharingPointBoxes({ spSerialNumber }));
    }
  }, [
    socket,
    spSerialNumber,
    dispatch,
    sharingPointStatus,
    sharingPointBoxes,
    isFetchingStatus,
    isFetchingSharingPointBoxes,
  ]);

  // actions

  const dispatchCommand = useCallback(
    (data) => {
      if (!data) return;
      dispatch(sendCommand(data));
    },
    [dispatch]
  );

  const fetchBoxes = useCallback(() => {
    if (
      !sharingPointBoxes &&
      !sharingPointBoxesError &&
      !isFetchingSharingPointBoxes
    ) {
      // dispatch(getSharingPointBoxes({ spSerialNumber }));
    }
  }, [
    dispatch,
    spSerialNumber,
    isFetchingSharingPointBoxes,
    sharingPointBoxes,
    sharingPointBoxesError,
  ]);

  //callback function to clear sharingPoint state
  const clearState = useCallback(() => {
    dispatch(gotSettingsForSharingPoint(null));
    dispatch(gotSharingPointBoxes(null));
    //dispatch(clearSettings());
    dispatch(setSharingPointStatus({ error: null, status: null }));
    dispatch(resetSwapActions());
    dispatch(locationsDetailData(undefined));
  }, [dispatch]);

  const openDoorHandler = useCallback(
    (data) => {
      if (socket && dispatch) dispatch(requestOpenDoor(data));
    },
    [dispatch, socket]
  );

  const stopChargingHandler = useCallback(
    (data) => {
      if (socket && dispatch) dispatch(startAndStopCharging(data));
    },
    [dispatch, socket]
  );

  const resetSPErrors = useCallback(
    (data) => {
      if (socket && dispatch) dispatch(resetSPErrorsAction(data));
    },
    [dispatch, socket]
  );

  const resetCMErrors = useCallback(
    (data) => {
      if (socket && dispatch) dispatch(resetCMErrorsAction(data));
    },
    [dispatch, socket]
  );

  const resetBattErrors = useCallback(
    (data) => {
      if (socket && dispatch) dispatch(resetBattErrorsAction(data));
    },
    [dispatch, socket]
  );

  const bmsCMProtocol = useCallback(
    (data) => {
      if (socket && dispatch) dispatch(bmsCMProtocolAction(data));
    },
    [dispatch, socket]
  );

  const sendRestApiCommand = async (data) => {
    try {
      const response = await axiosInstance.post("/commands", data);
      if (response.data.message) {
        dispatch(restApiResponse(response.data));
      }
      setCommandLoading(false);
    } catch (error) {
      let response = {
        message: error.response.data.message,
        request_parameter: "",
        status_code: error.response.data.status_code,
      };
      dispatch(restApiResponse(response));
      setCommandLoading(false);
    }
  };

  return {
    //status
    sharingPointStatus,
    statusError,
    isFetchingStatus,
    //boxes
    isFetchingSharingPointBoxes,
    sharingPointBoxesError,
    sharingPointBoxes,
    //command state
    commands,
    pendingOpenDoors,
    // handlers
    clearState,
    fetchBoxes,
    //command handlers
    dispatchCommand,
    openDoorHandler,
    stopChargingHandler,
    resetSPErrors,
    //finally command functions
    sendRestApiCommand, //new definitily
    commandLoading, //value loading for commands
    setCommandLoading, // set value loading for commands
    resetCMErrors,
    resetBattErrors,
    bmsCMProtocol,
  };
}
export { useSharingPoint };
