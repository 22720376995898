import React from "react";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import releaseNotes from "./../../assets/release.notes.md";
import { Container } from "semantic-ui-react";

export default () => {
  const [file, setFile] = useState();
  useEffect(() => {
    fetch(releaseNotes)
      .then((response) => response.text())
      .then((text) => {
        setFile(text);
      });
  }, []);
  return (
    <Container
      style={{
        width: "40%",
        margin: "auto",
        color: "black",
        marginTop: "100px",
      }}
    >
      <ReactMarkdown style={{ margin: "auto" }} source={file} />
    </Container>
  );
};
