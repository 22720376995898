import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Button, Message, Grid } from "semantic-ui-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { loginUser, requestNewPassword } from "./../../../../store/actions";
import { RHFInputRow } from "./../../../helpers/forms/RFHInputRow";
import "./index.scss";
import { navigate } from "@reach/router";

/** Login form is a react-hook-form for a login form with validation
 *  @returns form with username password input
 */
const LoginForm = () => {
  const dispatch = useDispatch();
  const loginError = useSelector((state) => state.session.error);
  const loginMessage = useSelector((state) => state.session.message);
  const loginLoading = useSelector((state) => state.session.loading);
  const [forgotPassword, setForgotPassword] = useState(false);

  // conditional validation based on useState
  const validationSchema = useMemo(() => {
    if (forgotPassword) {
      return yup.object().shape({
        username: yup.string().required("Please enter a username"),
      });
    } else {
      return yup.object().shape({
        username: yup.string().required("Please enter a username"),
        password: yup.string().required("Please enter a password"),
      });
    }
  }, [forgotPassword]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (forgotPassword) {
      dispatch(requestNewPassword(data.username));
      navigate(`/reset-password`);
    } else {
      dispatch(loginUser(data.username, data.password));
    }
  };
  return (
    <div className="form-wrapper">
      {loginError && <Message error header={loginMessage} />}
      <form
        className="ui form form--login"
        onSubmit={handleSubmit(onSubmit)}
        aria-label="login-form"
      >
        <div className="row-wrapper">
          <RHFInputRow
            label="username"
            name="username"
            type="text"
            register={register}
            error={errors["username"]}
          />
        </div>
        {!forgotPassword && (
          <div className="row-wrapper">
            <RHFInputRow
              label="password"
              name="password"
              type="password"
              register={register}
              error={errors["password"]}
            />
          </div>
        )}

        <Grid columns={"1"}>
          <Grid.Column width="8" textAlign="left">
            <span
              className="forgot-password-link"
              onClick={(e) => {
                setForgotPassword((current) => !current);
              }}
            >
              {forgotPassword ? "back to login" : "Forgot password?"}
            </span>
          </Grid.Column>
          <Grid.Column width="8" textAlign="right">
            <Button type="submit" loading={loginLoading}>
              {forgotPassword ? "Submit" : "Login"}
            </Button>
          </Grid.Column>

          {/* <GridColumn>
            <Divider horizontal inverted style={{ color: "#e8f0fe" }}>
              <span style={{ color: "#444" }}>Or</span>
            </Divider>
          </GridColumn>
          <GridColumn>
            <Button
              style={{ color: "#fff" }}
              circular
              color="facebook"
              icon="google"
              fluid
              content="Continue with Google"
              labelPosition="left"
              type="button"
              onClick={() => handleOauth2Request("Google")}
            />
          </GridColumn> */}
        </Grid>
      </form>
    </div>
  );
};

export default LoginForm;
