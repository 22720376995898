import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { QrReader } from "react-qr-reader";
import {
  Button,
  Divider,
  Form,
  FormField,
  Grid,
  GridColumn,
  Header,
  Icon,
  Image,
  List,
  ListItem,
  Loader,
  Segment,
} from "semantic-ui-react";
import awsconfig from "../config/awsconfig";
import { swobbeeColor } from "../helper/colors";
import decodeJwt from "jwt-decode";
import { useForm } from "react-hook-form";
import SocialMediaReferences from "../components/SocialMediaReferences";

export default ({ location }) => {
  const [statusCode, setStatusCode] = useState();
  const [message, setMessage] = useState();
  const [qrcodeShown, setQrcodeShown] = useState();
  const [userGroups, setUserGroups] = useState();

  const [access_token, setAccessToken] = useState();
  const [id_token, setIdToken] = useState();

  const onSubmit = async (user_group_name, e) => {
    try {
      const instance = axios.create({
        baseURL: awsconfig.REACT_APP_PUBLIC_API,
      });

      instance.defaults.headers.client_id = awsconfig.REACT_APP_REST_CLIENT_ID;

      const { data } = await instance.post(
        `${awsconfig.REACT_APP_PUBLIC_API}/oauth2-registration`,
        { user_group_name, access_token, id_token }
      );
      setStatusCode(data.status_code);
    } catch (error) {
      console.log(error);
      setStatusCode(error.response.data.status_code);
      setMessage(error.response.data.message);
    }
  };

  const getUserGroupsBySpUuid = async (text) => {
    try {
      const { data } = await axios.get(
        `${awsconfig.REACT_APP_PUBLIC_API}/user-groups/by_sp_uuid/` +
          text.split("=")[1]
      );
      setUserGroups(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    const id_token = new URLSearchParams(location.search).get("id_token");
    const access_token = new URLSearchParams(location.search).get(
      "access_token"
    );
    setAccessToken(access_token);
    setIdToken(decodeJwt(id_token));
    return () => {};
  }, [location, access_token, id_token]);

  useEffect(() => {
    if (!(access_token && id_token)) return;
    onSubmit();
    return () => {};
  }, [access_token, id_token]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      {!statusCode || statusCode === 422 ? (
        <>
          <GridColumn style={{ padding: 10 }}>
            <div
              verticalAlign="middle"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                margin: "auto",
                marginTop: 30,
              }}
              columns="1"
            >
              <GridColumn>
                <Header as="h1">
                  <Image
                    src={require("../assets/images/logo_icon_rgb_big.png")}
                    size="medium"
                  />
                  Welcome to swobbee
                </Header>

                <span
                  style={{
                    color: "#444",
                    fontSize: 20,
                    fontFamily:
                      "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                  }}
                >
                  Please follow the steps below to finish your signup
                </span>

                <List
                  ordered
                  bulleted
                  style={{
                    color: "#444",
                    fontSize: 18,
                    fontFamily:
                      "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                  }}
                >
                  <ListItem>
                    Scan qr code <Icon name="qrcode" /> at one of your company's
                    stations or fill up form
                  </ListItem>
                  <ListItem>Select your user group</ListItem>
                </List>
              </GridColumn>

              {userGroups && (
                <UserGroupSelection {...{ onSubmit, userGroups }} />
              )}

              {userGroups && userGroups.length === 0 && (
                <>
                  <GridColumn
                    width={16}
                    textAlign="center"
                    style={{
                      color: "#444",
                      fontSize: 18,
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    no user group found
                  </GridColumn>

                  <GridColumn
                    style={{
                      color: "#444",
                      textAlign: "center",
                      marginTop: 50 ,
                    }}
                  >
                    <Button
                      fluid
                      content="Try again"
                      primary
                      size="huge"
                      onClick={() => {
                        setStatusCode(null);
                        setUserGroups(null);
                      }}
                    />
                  </GridColumn>
                </>
              )}
              {!userGroups && (
                <>
                  <GridColumn style={{ margin: "20px 0 20px 0" }}>
                    {qrcodeShown ? (
                      <QrReaderComponent
                        {...{ onSubmit, getUserGroupsBySpUuid }}
                      />
                    ) : (
                      <Button
                        fluid
                        content="Scan qr code"
                        icon="qrcode"
                        primary
                        size="huge"
                        onClick={() => setQrcodeShown(true)}
                      />
                    )}
                  </GridColumn>
                  <GridColumn columns="16">
                    <Divider horizontal>Or</Divider>
                  </GridColumn>
                  <GridColumn>
                    <FormOption
                      {...{
                        location,
                        setStatusCode,
                        setMessage,
                        setUserGroups,
                      }}
                    />
                  </GridColumn>
                </>
              )}
            </div>
          </GridColumn>
        </>
      ) : (
        <Grid
          columns={1}
          style={{
            height: "100%",
            margin: "auto",
            marginTop: 50,
          }}
        >
          <GridColumn>
            <Segment>
              <Grid columns={1}>
                <GridColumn textAlign="center">
                  <Icon
                    name={statusCode !== 200 ? "warning sign" : "check circle"}
                    color={statusCode !== 200 ? "red" : "green"}
                    size="massive"
                  />
                </GridColumn>
                <GridColumn textAlign="center">
                  <Header style={{ color: "#444" }}>
                    {statusCode !== 200
                      ? "Something went wrong!"
                      : "Your Operator has been notified. The access will be granted in the next few minutes."}
                  </Header>
                </GridColumn>
                <GridColumn textAlign="center">
                  <Header as="h4" style={{ color: "#444" }}>
                    {statusCode !== 200
                      ? message
                      : "Please try to sign into the app as soon as you receive our confirmation email."}
                  </Header>
                </GridColumn>
                <GridColumn textAlign="center">
                  {statusCode === 200 ? (
                    <Button
                      fluid
                      icon="mobile"
                      content="Back to App"
                      primary
                      labelPosition="left"
                      size="big"
                      onClick={() =>
                        (window.location = new URLSearchParams(
                          location.search
                        ).get("redirect_url"))
                      }
                    />
                  ) : (
                    <Button
                      fluid
                      content="Try again"
                      primary
                      size="big"
                      onClick={() => {
                        setStatusCode(null);
                        setUserGroups(null);
                      }}
                    />
                  )}
                </GridColumn>
              </Grid>
            </Segment>
          </GridColumn>
          <GridColumn>
            <SocialMediaReferences />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

const QrReaderComponent = ({ onSubmit, userGroups, getUserGroupsBySpUuid }) => {
  const [loading, setLoading] = useState();

  return (
    <>
      {!loading && !userGroups && (
        <QrReader
          delay={300}
          constraints={{
            facingMode: "environment",
          }}
          style={{ width: "100%" }}
          onResult={(result, error) => {
            if (!!result && !loading) {
              getUserGroupsBySpUuid(result.getText());
            }

            if (!!error) {
              console.info(error);
            }
          }}
        />
      )}

      {loading && (
        <Loader inline active size="massive" style={{ color: "#444" }} />
      )}
    </>
  );
};

const FormOption = ({ location, setStatusCode, setMessage, setUserGroups }) => {
  const [isLoading, setIsLoading] = useState();
  const onSubmit = async (payload) => {
    try {
      const { data } = await axios.get(
        `${awsconfig.REACT_APP_PUBLIC_API}/user-groups`,
        {
          params: {
            search: payload.company,
          },
        }
      );
      console.log(data);
      setUserGroups(data.data);
    } catch (error) {
      console.log(error);
      // setStatusCode(error.response.data.status_code);
      // setMessage(error.response.data.message);
    }
  };
  const { errors, register, control, handleSubmit } = useForm();
  return (
    <Form size="huge" onSubmit={handleSubmit(onSubmit)}>
      <FormField>
        <label>Company</label>
        <input
          type="text"
          name="company"
          placeholder="Company"
          id="name"
          ref={register({ required: true })}
        />
      </FormField>
      <FormField>
        <label>Country</label>
        <input
          type="text"
          name="country"
          placeholder="Germany"
          id="name"
          ref={register({ required: true })}
        />
      </FormField>
      <FormField>
        <label>City</label>
        <input
          type="text"
          name="city"
          placeholder="Berlin"
          id="name"
          ref={register({ required: true })}
        />
      </FormField>
      {/* <FormField error={errors.role ? true : false}>
        <label>Role</label>
        <Controller
          control={control}
          name={`role`}
          render={({ onChange, value }, { invalid, isTouched, isDirty }) => (
            <Dropdown
              compact
              selection
              name="role"
              placeholder={"swapper"}
              clearable
              options={[
                {
                  value: "swapper",
                  text: "Swapper",
                  key: "swapper",
                },
                {
                  value: "operator",
                  text: "Operator",
                  key: "operator",
                },
              ]}
              onChange={async (e, { name, value }) => {
                onChange(value);
              }}
            />
          )}
        />
      </FormField> */}
      {/* <FormField>
        <span style={{ color: "#444" }}>Phone number (required?)</span>

        <Controller
          control={control}
          name={`phone_number`}
          render={({ onChange, value }, { invalid, isTouched, isDirty }) => (
            <ReactPhoneInput
              specialLabel={false}
              defaultCountry="de"
              placeholder="(+49)"
              onChange={(value) => {
                onChange(value);
              }}
            />
          )}
        />
      </FormField> */}
      <FormField>
        <Button
          loading={isLoading}
          content="Submit"
          size="huge"
          fluid
          style={{ backgroundColor: swobbeeColor, color: "#fff" }}
        />
      </FormField>
      <FormField>
        <Button
          content="Cancel"
          size="huge"
          fluid
          type="button"
          onClick={() => {
            window.location = window.location = new URLSearchParams(
              location.search
            ).get("redirect_url");
          }}
        />
      </FormField>
    </Form>
  );
};

const UserGroupSelection = ({ userGroups, onSubmit }) => {
  return (
    <GridColumn style={{ margin: "20px 0 20px 0" }}>
      {userGroups && (
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
          columns="1"
        >
          {userGroups.map((item) => (
            <Grid
              columns={1}
              centered
              style={{
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                margin: "10px 0 0px 0",
                borderRadius: 5,
              }}
            >
              <GridColumn style={{ color: "#444" }} textAlign="center">
                <Icon name="group" size="large" />{" "}
                <span style={{ marginLeft: 10, fontSize: 18 }}>
                  {item.user_group_name}
                </span>
              </GridColumn>
              <GridColumn>
                <Button
                  content="Choose user group"
                  primary
                  fluid
                  size="huge"
                  onClick={() => onSubmit(item.user_group_name)}
                />
              </GridColumn>
            </Grid>
          ))}
        </Grid>
      )}
    </GridColumn>
  );
};
