import { createReducer } from "redux-starter-kit";
import { addressFetching, addresses } from "../actions";

const initialState = {
  companies: null,
  company: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0
};

const addressesReducer = createReducer(initialState, {
  [addressFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload
    };
  },
  [addresses]: (state, action) => {
    return {
      ...state,
      addresses: action.payload.addresses,
      total: action.payload.total,
      fetching: false
    };
  }
});

export default addressesReducer;
