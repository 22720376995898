import { createReducer } from "redux-starter-kit";
import {
    notesByContextHashKey,
    resetNotes,
    setContextHashKeyForNotes,
    notesError,
    notesIsFetching
} from "../actions";

const initialState = {
    notes: null,
    notesIsFetching: false,
    contextHashKey: null,
    error: null
}

const notesReducer = createReducer(initialState, {
    [notesByContextHashKey]: (state, action) => {
        return {
            ...state,
            notes: action.payload
        }
    },
    [resetNotes]: () => {
        return {
            ...initialState
        }
    },
    [setContextHashKeyForNotes]: (state, action) => {
        console.log('setContextHashKeyForNotes', action)
        return {
            ...state,
            contextHashKey: action.payload
        }
    },
    [notesError]: (state, action) => {
        return {
            ...state,
            error: action.payload,
            notes: null
        }
    },
    [notesIsFetching]: (state, action) => {
        return {
            ...state,
            notesIsFetching: action.payload
        }
    },
})

export default notesReducer;