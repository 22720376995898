import { createAction } from "redux-starter-kit";
import { setContextHashKeyForNotes } from "./noteActions";
export const errorHistoryFetching = createAction("ERROR_HISTORY_FETCHING");
export const errorHistory = createAction("ERROR_HISTORY");
export const errorHistoryError = createAction("ERROR_HISTORY_ERROR");
export const errorHistoryIsSaving = createAction("ERROR_HISTORY_IS_SAVING");
export const selectErrorHistory = createAction("SELECT_ERROR_HISTORY");
export const notes = createAction("ERROR_HISTORY_NOTES");

/** fetch contacts through websocket middleware */
export const getErrorHistory = () => (dispatch, getState) => {
  dispatch(errorHistoryFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getErrorHistory",
    },
  });
};

/** receive contacts through websocket middleware */
export const gotErrorHistory = (message) => (dispatch /*, getState*/) => {
  if (message.statusCode === 200 || message.statusCode === 204) {
    dispatch(
      errorHistory({
        errorHistory: message.content,
      })
    );
  }
  dispatch(errorHistoryFetching(false));
};

export const resetErrorHistory = () => (dispatch) => {
  dispatch(
    errorHistory({
      errorHistory: [],
    })
  );
};

export const setErrorHistory = (message) => (dispatch /*, getState*/) => {
  dispatch(
    selectErrorHistory({
      selectedErrorHistory: message,
    })
  );
};

export const getNotes = (id) => (dispatch, getState) => {
  dispatch(setContextHashKeyForNotes(id));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getNotesByContextHashKey",
      data: {
        context_hash_key: id,
      },
    },
  });
};

/** receive contacts through websocket middleware */
export const gotNotes = (message) => (dispatch /*, getState*/) => {
  console.log("message", message);
  dispatch(errorHistoryFetching(false));
  dispatch(
    notes({
      notes: message.content,
    })
  );
};

export const postNote = (context, context_hash_key, note_text, title) => (
  dispatch,
  getState
) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createNote",
      data: {
        context,
        context_hash_key,
        note_text,
        title,
      },
    },
  });
};

export const postNoteResponse = (message) => (dispatch, getState) => {
  dispatch(errorHistoryFetching(false));
  dispatch(getNotes(message.content.context_hash_key));
};

export const updateErrorHistoryResponse = (message) => (dispatch, getState) => {
  dispatch(errorHistoryFetching(false));
  dispatch(getErrorHistory());
};

export const updateErrorHistoryById = (id, has_been_ack, timestamp) => (
  dispatch,
  getState
) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "updateErrorHistory",
      data: {
        hash_key: id,
        timestamp: timestamp,
        has_been_ack: has_been_ack,
      },
    },
  });
};

export const getErrorHistoryByContext = (context) => (dispatch, getState) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getErrorHistoryByContext",
      data: {
        context,
      },
    },
  });
};

export const getErrorHistoryBySourceId = (source_id) => (
  dispatch,
  getState
) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getErrorHistoryBySourceId",
      data: {
        error_source_id: source_id.toString(),
      },
    },
  });
};

export const getErrorHistoryByRegion = (region) => (dispatch, getState) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getErrorHistoryByRegion",
      data: {
        region,
      },
    },
  });
};
