import { createReducer } from "redux-starter-kit";
import {
  contactFetching,
  contacts,
  contactIsSaving,
  contactByHashKey,
} from "../actions";

const initialState = {
  contactByHashKey: null,
  contactByHashKeyIsFetching: false,
  contacts: null,
  contact: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0,
};

const contactsReducer = createReducer(initialState, {
  [contactFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [contacts]: (state, action) => {
    console.log("contacts in reducer", action.payload, state.contacts);
    return {
      ...state,
      contacts: action.payload.contacts,
      total: action.payload.contactsTotal,
      fetching: false,
    };
  },
  [contactIsSaving]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload,
    };
  },
  [contactByHashKey]: (state, action) => {
    return {
      ...state,
      contactByHashKey: action.payload,
    };
  },
});
export default contactsReducer;
