import React, { useEffect } from "react";
import { ResponsiveBarCanvas } from "@nivo/bar";
import ToolTipSwapPlot from "./ToolTipSwapPlot";
import moment from "moment";

const NivoBarPlot = ({ data, keys, xLabel, yLabel, colorMapping }) => {
  useEffect(() => {
    HTMLCanvasElement.prototype.getBBox = function () {
      return { width: "100%", height: "100%" };
    };
  }, []);

  return (
    <div style={{ height: 500 }}>
      {data && (
        <ResponsiveBarCanvas
          // layout="horizontal"

          tooltip={(e) => {
            console.log(e);
            return (
              <ToolTipSwapPlot
                {...{ battery_type: e.id, qx: e.data.label, y: e.indexValue }}
              />
            );
          }}
          enablePoints={false}
          animate={false}
          data={data}
          keys={keys}
          colors={{ scheme: "accent" }}
          indexBy="label"
          margin={{ top: 40, bottom: 150, left: 80 }}
          padding={0.3}
          groupMode="stacked"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "fries",
              },
              id: "dots",
            },
            {
              match: {
                id: "sandwich",
              },
              id: "lines",
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 4,
            tickPadding: 10,
            tickRotation: 45,
            legend: xLabel,
            legendPosition: "middle",
            legendOffset: 100,
            format: function (value) {
              return data.length < 100
                ? value
                : moment(value, "DD-MM-YYYY").day() % 2
                ? value
                : "";
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLabel,
            legendPosition: "middle",
            legendOffset: -60,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 140,
              itemsSpacing: 10,
              itemDirection: "left-to-right",
              itemWidth: 140,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
        />
      )}
    </div>
  );
};

export default NivoBarPlot;
