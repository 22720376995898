/** @format */

import React, { useEffect, useState } from "react";
import { Icon, Button } from "semantic-ui-react";
import CsvDownload from "react-json-to-csv";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";

import "./style.scss";
const CsvGenerator = ({ fileToTransform }) => {
  const [dataWithSumme, setDataWithSumme] = useState([]);
  const { timeSpans } = useFormatTimestamp();
  useEffect(() => {
    setDataWithSumme([]);
    if (fileToTransform) {
      let trans = [];
      fileToTransform.forEach((element) => {
        let items_in = element.battery_in && element.battery_in.length;
        let items_out = element.battery_out && element.battery_out.length;
        trans.push({
          booking_id: element.hash_key,
          sp_serial_number: element.sp_serial_number,
          user_name: element.user,
          battery_count: element.battery_count ? element.battery_count : "0",
          totalIn: element.sp_serial_number.includes("20M")
            ? items_in.toString()
            : element.battery_in_sum === "undefined" || !element.battery_in_sum
            ? "0"
            : element.battery_in_sum,
          totalOut: element.sp_serial_number.includes("20M")
            ? items_out.toString()
            : element.battery_out_sum === "undefined" ||
              !element.battery_out_sum
            ? "0"
            : element.battery_out_sum,
          timestamp: `${timeSpans(element.timestamp).props.children[0]}${
            timeSpans(element.timestamp).props.children[1]
          }${timeSpans(element.timestamp).props.children[2]}`,
          state: element.swap_state ? element.swap_state : "undefined",
        });
      });

      setDataWithSumme(trans);
    }
  }, [fileToTransform]);

  const csvName = `swobbee_swap_export_${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}.csv`;

  return (
    <>
      {dataWithSumme.length > 0 ? (
        <CsvDownload
          id="csv-export-button"
          filename={csvName}
          data={dataWithSumme}
        >
          <Icon name="cloud download" /> Download
        </CsvDownload>
      ) : (
        <Button style={{ width: "50%" }} loading primary>
          Loading
        </Button>
      )}
    </>
  );
};
export default CsvGenerator;
