// AxiosContext.js
import React, { createContext, useMemo, useState } from "react";
import axios from "axios";
import awsconfig from "../config/awsconfig";

export const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);

  const axiosInstance = useMemo(() => {
    if (!accessToken) return;
    let client_id = awsconfig.REACT_APP_REST_CLIENT_ID;
    let apiUrl = awsconfig.REACT_APP_PUBLIC_API;
    let refresh_token = localStorage.getItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.refresh_token`
    );

    // console.log("access_token", accessToken)
    const instance = axios.create({
      baseURL: apiUrl, // Replace with your API base URL
      timeout: 60000, // Adjust the timeout as needed
      headers: {
        access_token: accessToken,
        client_id: client_id,
      },
    });

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.log("interceptors", error.response.status);
        if ([401].includes(error.response.status) && !error.config._isRetry) {
          error.config._isRetry = true;
          try {
            console.log(refresh_token)
            if (!refresh_token) {
              clearSessionStorage()
              return
            }
            const access_token = await refreshAccessToken(refresh_token);
            if (access_token) {
              localStorage.setItem(
                `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`,
                access_token
              );
            }

            // Retry the failed request with the new access token
            error.config.headers.access_token = access_token;
            return axiosInstance(error.config);
          } catch (error) {
            console.log("error interceptors", error);
            clearSessionStorage()

            return Promise.reject(error);
          } finally {
            error.config._isRetry = false;
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [accessToken]);

  const refreshAccessToken = async (refresh_token) => {
    try {
      const { data } = await axios.post(
        `${awsconfig.REACT_APP_PUBLIC_API}/refresh_token`,
        {
          refresh_token,
          client_id: awsconfig.REACT_APP_REST_CLIENT_ID,
        }
      );
      const { access_token } = data.data;

      return access_token;
    } catch (error) {
      console.log("refresh token error", error);
      clearSessionStorage();
      window.location.reload()
    }
  };

  const clearSessionStorage = () => {
    
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.name`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.token`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.userName`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.displayName`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.tokenExpiry`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.refresh_token`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.idToken`
    );
    localStorage.removeItem(
      `swobbee.portal.${awsconfig.REACT_APP_REST_CLIENT_ID}.user_group`
    );
    localStorage.removeItem(`client_id`);
  };

  return (
    <AxiosContext.Provider
      value={{
        axiosInstance,
        setAccessToken,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};
