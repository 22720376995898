/** @format */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const Languages = ["en", "de", "es"];

i18n
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: "de",
    // debug: process.env.NODE_ENV === "production" ? false : true,
    debug: false,
    whitelist: Languages,
    ns: [
      "sharingPoints",
      "batteries",
      "sharingPointOverview",
      "translation",
      "sharingPointTable",
      "descriptions",
    ],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
