import { createReducer } from "redux-starter-kit";

import {
  loginLoading,
  loginSuccess,
  loginError,
  logoutSuccess,
  setReauthenticate,
  setSocket,
  setRefreshedToken,
} from "../actions";

const initialState = {
  loading: false,
  loggedIn: false,
  error: false,
  needsReauth: false,
  message: null,
  token: null,
  name: null,
  username: null,
  displayname: null,
  tokenExpiry: null,
  socket: null,
  user_group: null,
};

const sessionReducer = createReducer(initialState, {
  [setSocket]: (state, action) => {
    return { ...state, socket: action.payload };
  },
  [loginLoading]: (state, action) => {
    return {
      ...state,
      loading: action.payload || false,
      error: false,
      message: undefined,
    };
  },
  [loginSuccess]: (state, action) => {
    return {
      ...action.payload,
      loading: false,
      loggedIn: true,
      error: false,
      message: null,
    };
  },
  [loginError]: (state, action) => {
    return {
      ...initialState,
      error: true,
      message: action.payload,
    };
  },
  [logoutSuccess]: (state, action) => {
    return {
      ...initialState,
    };
  },
  [setReauthenticate]: (state, action) => {
    return {
      ...initialState,
      needsReauth: true,
    };
  },
  [setRefreshedToken]: (state, action) => {
    return {
      ...state,
      token: action.payload.token,
      tokenExpiry: action.payload.tokenExpiry,
    };
  },
});

export default sessionReducer;
