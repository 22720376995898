import { createReducer } from "redux-starter-kit";
import { providerFetching, providers, providerIsSaving } from "../actions";

const initialState = {
  providers: null,
  fetching: false,
  isSaving: false,
  needsReload: true,
  error: null,
  total: 0
};

const providersReducer = createReducer(initialState, {
  [providerFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload
    };
  },
  [providers]: (state, action) => {
    return {
      ...state,
      providers: action.payload.providers,
      total: action.payload.providersTotal,
      fetching: false,
      needsReload: false
    };
  },
  [providerIsSaving]: (state, action) => {
    console.log('provider is saving in reducer', action.payload)
    return {
      ...state,
      isSaving: action.payload,
      needsReload: true
    };
  }
});

export default providersReducer;
