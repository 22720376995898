import { createAction } from "redux-starter-kit";

const STORAGE_ITEM = "appSettings";

export const setAppSettings = createAction("SET_APP_SETTINGS");
export const setSideBarState = createAction("SET_SIDE_BAR_STATE");

export const loadAppSettings = () => (dispatch, getState) => {
  try {
    const savedSettings = localStorage.getItem(STORAGE_ITEM);
    if (savedSettings === null) {
      return undefined;
    }
    dispatch(setAppSettings(JSON.parse(savedSettings)));
  } catch (error) {
    return undefined;
  }
};

export const saveAppSettings = (settings) => (dispatch, getState) => {
  try {
    console.log(settings)
    const serialized = JSON.stringify(settings);
    localStorage.setItem(STORAGE_ITEM, serialized);
  } catch (error) {}
};

export const updateSetting = (setting) => (dispatch, getState) => {
  const oldSettings = getState().appSettings;
  const newSettings = { ...oldSettings, ...setting };
  dispatch(setAppSettings(newSettings));
  dispatch(saveAppSettings(newSettings));
};

export const updateSideBarState = (state) => (dispatch, getState) => {
  const oldSettings = getState().appSettings;
  dispatch(saveAppSettings({ ...oldSettings, sidebarIsCollapsed: state }));
  dispatch(setSideBarState(state));
};
