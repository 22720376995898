/** @format */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Router, Redirect, Location } from "@reach/router";
import loadable from "@loadable/component";

import ErrorBoundary from "./../components/helpers/ErrorBoundary";
import { isAllowedToRead } from "../helper/permissions";

import MainScreen from "./index";

import CompaniesScreen from "./companies";
import CreateCompanyScreen from "./companies/create";

import ManufacturersScreen from "./manufacturers";
import ManufacturersForm from "./../components/Manufacturers/Form";

import ProvidersScreen from "./providers";
import CreateProviderScreen from "./providers/create";
import SharingPointsScreenMobile from "./SharingPointsScreenMobile";
import SharingPointDetailScreen from "./SharingPointDetailScreen";

import AckMessenger from "./../components/AckMessenger";

import OwnersScreen from "./owners";
import OwnerForm from "./../components/OwnerForm";
import ReleaseNotes from "./../components/ReleaseNotes";
import SwapDetailView from "./../components/SwapDetailView";

import { isAllowedToWrite } from "./../helper/permissions";

import NewLayout from "../components/NewLayout";
import { useAppSettings } from "../hooks/store/use-app-settings";
import SwapScreen from "./swapScreen";
import { RestApiMessenger } from "../components/RestApiMessenger/RestApiMessenger";
import { UserGroupDetailScreen } from "./UserGroupDetailScreen";
import { Grid, GridColumn, Header, Icon } from "semantic-ui-react";
import BatteryTracking from "./BatteryTracking";
import { CheckSwapReport } from "./CheckSwapReport";
import SwobbeePublicLocationMap from "../components/SwobbeePublicLocationMap";
import TestChart from "../components/SwapStatistics/NivopBarPlot";

const SwapStatisticScreen = loadable(() =>
  import("./../components/SwapStatistics")
);

//const ErrorsHistory = loadable(() => import("./../components/ErrorsHistory"));

const ShowCompaniesScreen = loadable(() => import("./companies/show"));
const ContactsScreen = loadable(() => import("./contacts"));
const ShowContactsScreen = loadable(() => import("./contacts/show"));

const LocationsScreen = loadable(() => import("./locations"));
const LocationsCreateScreen = loadable(() => import("./locations/create"));
const ShowLocationsScreen = loadable(() => import("./locations/show"));

const BatteriesIndexScreen = loadable(() => import("./batteries/index"));
//const CreateBatteryScreen = loadable(() => import("./batteries/create"));
const BatteriesScreen = loadable(() => import("./batteries"));
const ShowBatteryScreen = loadable(() => import("./batteries/show"));

const SharingPointsIndexScreen = loadable(() =>
  import("./sharingPoints/index")
);
const SharingPointsScreen = loadable(() => import("./sharingPoints"));
const ShowSharingPointScreen = loadable(() => import("./sharingPoints/show"));

const UserManagementScreen = loadable(() => import("./UserManagementScreen"));

const ProfileScreen = loadable(() => import("./Profile"));

const AuthenticatedApp = () => {
  const session = useSelector((state) => state.session);
  const acks = useSelector((state) => state.acks.acks);

  const collapsed = useSelector((state) => {
    return state.appSettings.sidebarIsCollapsed;
  });
  const { restApiResponse } = useSelector((state) => state.restApi);
  const [lang, setLang] = useState();
  const { i18n } = useTranslation();

  const LANGUAGE = JSON.parse(localStorage.getItem("LANGUAGE"));

  useEffect(() => {
    if (!lang && LANGUAGE) {
      setLang(LANGUAGE);
    }
  }, [LANGUAGE]);

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <>
      <Location>
        {() => (
          <>
            {window.innerWidth > 425 && (
              <NewLayout
                setLang={setLang}
                lang={lang}
                collapsed={Boolean(collapsed)}
                session={session}
              >
                <ErrorBoundary>
                  <Router style={{ marginTop: "20px" }}>
                    {!isAllowedToRead("ErrorHistory") && (
                      <SharingPointsScreen path="/">
                        <SharingPointsIndexScreen path="/" />
                        <ShowSharingPointScreen path="/:sharingPointId"></ShowSharingPointScreen>
                      </SharingPointsScreen>
                    )}

                    {isAllowedToRead("ErrorHistory") && (
                      <SharingPointsScreen path="/sharingpoints">
                        <SharingPointsIndexScreen path="/" className="xx" />
                        <ShowSharingPointScreen path="/:sharingPointId"></ShowSharingPointScreen>
                      </SharingPointsScreen>
                    )}
                    <SharingPointsScreen path="/sharingpoints">
                      <SharingPointsIndexScreen path="/" className="xx" />
                      <ShowSharingPointScreen path="/:sharingPointId"></ShowSharingPointScreen>
                    </SharingPointsScreen>
                    <SwapDetailView path="/swaps/:swapId" />

                    {isAllowedToRead("ErrorHistory") && (
                      <MainScreen path="/" session={session} />
                    )}

                    {isAllowedToRead("Location") && (
                      <>
                        <LocationsScreen
                          lang={lang}
                          path="/locations"
                        ></LocationsScreen>
                        <LocationsCreateScreen
                          lang={lang}
                          path="/locations/create/*"
                        />
                        <LocationsCreateScreen
                          lang={lang}
                          path="/locations/edit/:locationId/*"
                        />
                        <ShowLocationsScreen
                          lang={lang}
                          path="/locations/:locationId"
                        />
                      </>
                    )}
                    {isAllowedToRead("NewBattery") && (
                      <BatteriesScreen lang={lang} path="/batteries">
                        <BatteriesIndexScreen lang={lang} path="/" />
                        <ShowBatteryScreen lang={lang} path="/:serial_number" />
                      </BatteriesScreen>
                    )}
                    {isAllowedToRead("SharingPoint") && (
                      <SwapStatisticScreen lang={lang} path="/statistics" />
                    )}
                    {isAllowedToWrite("SharingPoint") && (
                      <ReleaseNotes lang={lang} path="/release-notes" />
                    )}
                    <UserManagementScreen path="/user-management" />
                    <ProfileScreen path="/profile" />
                    <ProfileScreen path="/profile/:userId" />
                    <SwapScreen path="/swaps" />
                    <UserGroupDetailScreen path="/user-groups/:userGroupName" />
                    <BatteryTracking path="/battery-tracking" />
                    <SwobbeePublicLocationMap path="/swobbee-locations" />
                    <Redirect noThrow from="*" to="/" />
                  </Router>
                </ErrorBoundary>
              </NewLayout>
            )}

            {window.innerWidth <= 425 && (
              <>
                <Router>
                  <SharingPointsScreenMobile path="/" />
                  <SharingPointDetailScreen path="/:spSerialNumber" />
                  <Redirect noThrow from="*" to="/" />
                  <SignupScreenError path="/oauth2-signup" />
                </Router>
              </>
            )}
          </>
        )}
      </Location>
      {/* <AckMessenger acks={acks} /> */}
      <RestApiMessenger acks={restApiResponse} />
    </>
  );
};
export default AuthenticatedApp;

const SignupScreenError = () => (
  <Grid
    centered
    style={{ padding: 20, margin: "auto", marginTop: 100 }}
    columns="1"
  >
    <GridColumn textAlign="center">
      <Icon name="warning sign" size="massive" color="yellow" />
    </GridColumn>

    <GridColumn textAlign="center">
      <Header>
        You are currently logged in with another account. Please logout and
        start the Login over.
      </Header>
    </GridColumn>
  </Grid>
);
