/** @format */
import rest_api_connector from "../../rest_api_connector";
import { createAction } from "redux-starter-kit";

export const systemErrors = createAction("SYSTEM_ERRORS");
export const resetFetch = createAction("RESET_FETCH");

export const getLastEvaluatedKey = createAction("GET_LAST_EVALUATED_KEY");

export const errorsIsFetching = createAction("ERRORS_IS_FETCHING");

export const getStationIds = createAction("GET_STATION_IDS");

export const filteredErrorsIsFetching = createAction(
  "FILTERED_ERRORS_IS_FETCHING"
);

export const fetchSystemErrors = () => async dispatch => {
  dispatch(errorsIsFetching(true));
  try {
    const { data } = await rest_api_connector.get(`/system_errors`);
    dispatch(systemErrors(data));
    if (data.last_evaluated_key) {
      dispatch(getLastEvaluatedKey(data.last_evaluated_key));
    }
  } catch (error) {
    console.log("error", error);
  }
};


export const fetch = () => async dispatch => {
  dispatch(errorsIsFetching(true));
  try {
    const { data } = await rest_api_connector.get(`/system_errors`);
    dispatch(resetFetch(data));
  } catch (error) {
    console.log("error", error);
  }
};

export const fetchErrorsLastEvaluatedKey =
  lastEvaluatedKey => async dispatch => {
    try {
      const { data } = await rest_api_connector.get(
        `/system_errors?last_evaluated_key=${lastEvaluatedKey}`
      );
      dispatch(systemErrors(data));
      if (data.last_evaluated_key !== "None") {
        dispatch(getLastEvaluatedKey(data.last_evaluated_key));
      } else {
        dispatch(getLastEvaluatedKey(null));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

export const fetchStationIds = async dispatch => {
  try {
    const { data } = await rest_api_connector.get(`/sharing_point_ids`);
    dispatch(getStationIds(data));
  } catch (error) {
    console.log("error", error);
  }
};

export const fetchFilteredErrors = (stationId, reset) => async dispatch => {
  if (reset) {
    dispatch(systemErrors(null));
    dispatch(getLastEvaluatedKey(null));
  }
  try {
    const { data } = await rest_api_connector.post(
      `/system_errors/station/${stationId}`
    );
    dispatch(systemErrors(data));
    if (data.last_evaluated_key !== "None") {
      dispatch(getLastEvaluatedKey(data.last_evaluated_key));
    } else {
      dispatch(getLastEvaluatedKey(null));
    }
  } catch (error) {
    console.log(error);
  }
  dispatch(filteredErrorsIsFetching(true));
};

export const fetchFilteredErrorsLastEvaluatedKey =
  (stationId, lastEvaluatedKey) => async dispatch => {
    try {
      const data = await rest_api_connector.post(
        `/system_errors/station/${stationId}`,

        { last_evaluated_key: lastEvaluatedKey }
      );
      dispatch(systemErrors(data.data));
      if (data.data.last_evaluated_key !== "None") {
        dispatch(getLastEvaluatedKey(data.data.last_evaluated_key));
      } else {
        dispatch(getLastEvaluatedKey(null));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
