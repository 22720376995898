import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Link, useLocation } from "@reach/router";
import queryString from "query-string";

import {
  Grid,
  Button,
  Container,
  Card,
  GridRow,
  Dropdown,
  Header,
} from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { RFHErrorMessages } from "../helpers/forms/RFHErrorMessages";
import { RHFInputRow } from "../helpers/forms/RFHInputRow";
import { useDropdownOptions } from "../../hooks/use-dropdown-options";
import { yupResolver } from "@hookform/resolvers/yup";
import InputSearchAwsLocation from "../Locations/InputSearchAwsLocation";

const validationSchema = yup.object().shape({
  companyName: yup.string().required("companys.company_name.validationMessage"),
  addressStreetName: yup
    .string()
    .min(3)
    .required("companys.address_street.validationMessage"),
  addressStreetNumber: yup
    .string()
    .required("companys.address_street_number.validationMessage"),
  addressZipcode: yup
    .string()
    .required("companys.address_zipcode.validationMessage"),
  addressCity: yup
    .string()
    .min(3)
    .required("companys.address_city.validationMessage"),
  addressCountry: yup
    .string()
    .min(2)
    .required("companys.address_country.validationMessage"),
  contactEmail: yup
    .string()
    .email()
    .required("companys.contact_mail.validationMessage"),

  contactFirstName: yup
    .string()
    .min(2)
    .required("companys.contact_first_name.validationMessage"),
  contactFamilyName: yup
    .string()
    .min(2)
    .required("companys.contact_family_name.validationMessage"),
});

const types = ["provider", "owner", "manufacturer"];

const CompanyForm = ({ saveHandler, isSaving, company, batteryTypes }) => {
  const { t } = useTranslation();
  // handle query params to set companyType as fixed
  // if this component is instantiated in a screen with url ../?companyType=provider
  // the companyType will be set to provider without opion to change
  // a maybe more suitable place would be to use the access of location into the parent screen??
  const useLocationInstance = useLocation();
  const [companyType, setCompanyType] = useState();
  const [location, setSelectedLocation] = useState();

  const batteryTypeOptions = useDropdownOptions(batteryTypes, {
    key: "name",
    value: "hash_key",
    text: "name",
  });

  useEffect(() => {
    if (useLocationInstance.search) {
      let query = queryString.parse(useLocation.search);
      let type = query["companyType"];
      if (type) {
        type = type.trim();
        if (types.includes(type)) {
          setCompanyType(type);
          setValue("companyTypes", type);
        }
      }
    }
  }, [useLocationInstance.search]);

  const { register, handleSubmit, setValue, errors, control, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: company,
  });

  const companyTypes = watch("companyTypes");

  const companyOptions = useMemo(() => {
    return types.map((type) => {
      return {
        text: type,
        value: type,
        key: type,
      };
    });
  }, []);

  const algoliaChange = () => {
    let { item } = location;
    let { Place } = item;
    setValue("addressStreetName", Place.Street);
    setValue("addressStreetNumber", Place.AddressNumber);
    setValue("addressCountry", Place.Country);
    setValue("addressCity", Place.Municipality);
    setValue("country", Place.Country);
    setValue("addressZipcode", Place.PostalCode);
    setValue("gpsLongitude", Place.Geometry.Point[0]);
    setValue("gpsLatitude", Place.Geometry.Point[1]);
  };
  useEffect(() => {
    if (!location) return;
    algoliaChange();
    return () => {};
  }, [location]);

  return (
    <Container style={{ color: "black" }}>
      <RFHErrorMessages errors={errors} title={t("errors")} />
      <form className="ui form" onSubmit={handleSubmit(saveHandler)}>
        <Card fluid>
          {!companyType && (
            <Card.Header style={{ padding: 10 }}>
              <Grid>
                <Grid.Column width="4" verticalAlign="middle">
                  <Header as="h4">{t("chooseCompanyType")}</Header>
                </Grid.Column>
                <Grid.Column width="12">
                  {companyType ? (
                    <Header as="h3">{companyType}</Header>
                  ) : (
                    <Controller
                      control={control}
                      name="companyTypes"
                      render={({ onChange, onBlur, value }) => {
                        return (
                          <Dropdown
                            fluid
                            additionLabel="company type"
                            labeled={true}
                            multiple
                            defaultValue={value}
                            clearable
                            selection
                            options={companyOptions}
                            placeholder={t("chooseCompanyType")}
                            onChange={(e, { value }) => {
                              onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Card.Header>
          )}
          <Card.Content style={{ textAlign: "left" }}>
            <Grid>
              <Grid.Column width="16">
                <RHFInputRow
                  label={t("companys.company_name.label")}
                  register={register}
                  name={"companyName"}
                  error={errors["companyName"]}
                />
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Header style={{ textAlign: "left", padding: "10px" }}>
            <Grid>
              <Grid.Column width="8">
                <Header as="h4">{t("companys.company_address.label")}</Header>
              </Grid.Column>
              <Grid.Column width="8"></Grid.Column>
            </Grid>
          </Card.Header>
          <Card.Content style={{ textAlign: "left" }}>
            <Grid>
              <Grid.Column width="16">
                <InputSearchAwsLocation
                  {...{ location, setSelectedLocation }}
                  style={{ marginBottom: "4px" }}
                  id="search"
                />
                <Grid>
                  <Grid.Column width="8">
                    <RHFInputRow
                      register={register}
                      label={t("companys.address_street.label")}
                      name="addressStreetName"
                      error={errors["addressStreetName"]}
                    />
                  </Grid.Column>
                  <Grid.Column width="2">
                    <RHFInputRow
                      register={register}
                      label={t("companys.address_street_number.label")}
                      name="addressStreetNumber"
                      error={errors["addressStreetNumber"]}
                    />
                  </Grid.Column>
                  <Grid.Column width="6">
                    <RHFInputRow
                      register={register}
                      label={t("companys.address_street_additional.label")}
                      name="addressAdditional"
                    />
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column width="4">
                    <RHFInputRow
                      register={register}
                      label={t("companys.address_zipcode.label")}
                      name="addressZipcode"
                      error={errors["addressZipcode"]}
                    />
                  </Grid.Column>
                  <Grid.Column width="6">
                    <RHFInputRow
                      register={register}
                      label={t("companys.address_city.label")}
                      name="addressCity"
                      error={errors["addressCity"]}
                    />
                  </Grid.Column>
                  <Grid.Column width="6">
                    <RHFInputRow
                      register={register}
                      label={t("companys.country.label")}
                      name="addressCountry"
                      error={errors["addressCountry"]}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
        <Card fluid>
          <Card.Header style={{ textAlign: "left", padding: "10px" }}>
            <Grid>
              <Grid.Column width="8">
                <Header as="h4">{t("contacts.label")}</Header>
              </Grid.Column>
            </Grid>
          </Card.Header>
          <Card.Content style={{ textAlign: "left" }}>
            <Grid>
              <Grid.Column width="8">
                <RHFInputRow
                  register={register}
                  label={t("companys.contact_first_name.label")}
                  name="contactFirstName"
                  error={errors["contactFirstName"]}
                />
              </Grid.Column>

              <Grid.Column width="8">
                <RHFInputRow
                  register={register}
                  label={t("companys.contact_family_name.label")}
                  name="contactFamilyName"
                  error={errors["contactFamilyName"]}
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width="8">
                <RHFInputRow
                  register={register}
                  label={t("companys.contact_mail.label")}
                  name="contactEmail"
                  type="email"
                  error={errors["contactEmail"]}
                />
              </Grid.Column>
              <Grid.Column width="8"></Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
        {companyTypes && companyTypes.includes("manufacturer") && (
          <Card fluid>
            <Card.Header style={{ textAlign: "left", padding: "10px" }}>
              <Header as="h4">Manufacturer</Header>
            </Card.Header>
            <Card.Content>
              <Grid>
                <Grid.Column width="16">
                  {batteryTypeOptions && (
                    <Controller
                      name="batteryTypes"
                      control={control}
                      render={({ onChange, value }) => {
                        return (
                          <Dropdown
                            options={batteryTypeOptions}
                            selection
                            multiple
                            clearable
                            fluid
                            defaultValue={value}
                            onChange={(e, { value }) => {
                              onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Card.Content>
          </Card>
        )}

        <Card fluid>
          <Card.Content>
            <GridRow textAlign="right">
              <Grid.Column textAlign="right">
                <Button
                  as={Link}
                  to="/companies"
                  icon="close"
                  basic
                  content={t("button.cancel")}
                />
                <Button
                  type="submit"
                  icon="save"
                  loading={isSaving}
                  primary
                  content={t("button.save")}
                />
              </Grid.Column>
            </GridRow>
          </Card.Content>
        </Card>
      </form>
    </Container>
  );
};
CompanyForm.propTypes = {
  saveHandler: PropTypes.func,
  isSaving: PropTypes.bool,
  company: PropTypes.shape({
    companyName: "",
    addressStreetName: "",
    addressStreetNumber: "",
    addressCity: "",
    addressZipcode: "",
    addressCountry: "",
    contactEmail: "",
    contactTitle: "",
    contactFirstName: "",
    contactFamilyName: "",
    contactPhone: "",
    addressAdditional: "",
  }),
};
CompanyForm.defaultProps = {
  isSaving: false,
  company: {
    companyName: "",
    addressStreetName: "",
    addressStreetNumber: "",
    addressCity: "",
    addressZipcode: "",
    addressCountry: "",
    contactEmail: "",
    contactTitle: "",
    contactFirstName: "",
    contactFamilyName: "",
    contactPhone: "",
    addressAdditional: "",
  },
};
export default CompanyForm;
