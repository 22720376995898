import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Header,
  Button,
  Modal,
  ModalHeader
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, navigate } from "@reach/router";

import ScreenHeader from "./../../components/helpers/ScreenHeader3";
import CompanyForm from "../../components/CompanyForm";
import { getBatteryTypes, saveCompany, restApiResponse } from "../../store/actions";
import "./index.scss";

function CreateCompanyScreen({ location }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSaving = useSelector(state => state.companies.isSaving);
  const error = useSelector(state => state.companies.error);
  const [sendSave, setSendSave] = useState(false);

  const socket = useSelector(state => state.session.socket);
  const batteryTypes = useSelector(state => state.batteries.batteryTypes);

  useEffect(() => {
    if (socket && !batteryTypes) dispatch(getBatteryTypes());
  }, [socket, dispatch, batteryTypes]);

  useEffect(() => {
    if (sendSave && !isSaving) {
      // navigate to previously visited route since createCompany can be called from different screens
      navigate(-1);
    }
  }, [sendSave, isSaving]);

  const saveHandler = companyData => {
    dispatch(saveCompany(companyData));
    setSendSave(true);
  };
  const heading = <Header as="h3">{t("create.company")}</Header>;
  const navi = <Button icon="close" as={Link} to="/companies" circular />;

  return (
    <Container fluid className="screen--companies-create screen">
      <ScreenHeader heading={heading} navi={navi} />
      <Modal open={true} closeIcon onClose={()=> navigate("/companies")}>
        <ModalHeader>Create Company</ModalHeader>
        <ModalHeader>
          <CompanyForm
            saveHandler={saveHandler}
            isSaving={isSaving}
            error={error}
            batteryTypes={batteryTypes}
          />
        </ModalHeader>
      </Modal>
    </Container>
  );
}

export default CreateCompanyScreen;
