/** @format */

import { setupWebsocket } from "../helper/websocketService";
import {
  gotBatteries,
  gotBatteryUpdate,
  gotBatteryTypes,
  gotBatteryPools,
  gotContacts,
  gotCompanies,
  gotLocationsMapData,
  gotLocationsTableData,
  gotLocationStates,
  gotManufacturers,
  gotProviders,
  gotRegions,
  gotSharingPointsMapData,
  gotSharingPointsTableData,
  gotTechCVs,
  gotOwners,
  gotAckMessage,
  batteryChanged,
  techCVChanged,
  companyChanged,
  gotTechCVsByBatterySN,
  gotBatteryBySerialNumber,
  refreshToken,
  updateBatteryBySerialNumber,
  gotSettingsForSharingPoint,
  getSharingPointGsmHistory,
  getSharingPointTempHistory,
  getSharingPointOnlineHistory,
  gotSharingPointStatus,
  gotSharingPointBoxes,
  updateSharingPointBox,
  gotSwapActions,
  gotLocationsDetailData,
  updateSharingPointStatus,
  updateSharingPointStatuses,
  gotGeneratedUploadUrl,
  gotFileUrl,
  updateTechCVTable,
  gotNotesByContextHashKey,
  gotFilesByContextHashKey,
  gotCompanyByHashKey,
  gotContactByHashKey,
  updateNote,
  updateFile,
  deletedFile,
  gotErrorHistory,
  updateErrorHistoryResponse,
  getSwapStatisticResponse,
  savedCompany,
  gotSharingPointDetailData,
  addErrorAck,
  gotInteractions,
  gotTechcvUpdate,
} from "../store/actions";
import { tokenExpired } from "./../helper/sessionHelper";

let socket;
let token;
const createSocketMiddleware = store => next => async action => {
  let state = store.getState();
  let tokenExpiry = state.session.tokenExpiry;
  //console.log("action", action);
  if (!action || !action.type) return;
  switch (action.type) {
    /** on automated refresh-token restart a new socket connection*/
    case "SET_REFRESHED_TOKEN":
    case "LOGIN_SUCCESS":
      token = action.payload.token;
      if (socket && socket.close) {
        socket.close();
        socket = undefined;
      }
      setupWebsocket({ token })
        .then(s => {
          socket = s;
          store.dispatch({
            type: "SET_SOCKET",
            payload: true,
          });
        })
        .catch(e => console.log("WS ERROR after login", e));
      break;

    /**
     * is responsible to pass messages to the correct actionHandler to update the store
     * currently much server response logic is done poorly through ack messages
     */
    case "SET_SOCKET":
      if (action.payload && socket.receive) {
        socket.receive(message => {
          if (message.statusCode === 500) {
            let ackObject = {
              display_message: "There was a server error.",
              description: "Please reload the page.",
              statusCode: 500,
            };
            store.dispatch(addErrorAck(ackObject));
            return;
          } else if (message.statusCode === 409) {
            store.dispatch(
              addErrorAck({
                display_message: "there is a file with same name",
                description: "Please reload the page.",
                statusCode: message.statusCode,
              })
            );
          }
          switch (message.message_type) {
            case "getSharingPointInteractions":
              store.dispatch(gotInteractions(message));
              break;
            case "battery_table":
              store.dispatch(gotBatteries(message));
              break;
            case "getAllBatteryTypes":
              store.dispatch(gotBatteryTypes(message));
              break;
            case "getAllBatteryPools":
              store.dispatch(gotBatteryPools(message));
              break;
            case "getAllRegions":
              store.dispatch(gotRegions(message));
              break;
            case "locations_map_data":
              store.dispatch(gotLocationsMapData(message));
              break;
            case "locations_table":
              store.dispatch(gotLocationsTableData(message));
              break;
            case "sharingpoint_table":
              store.dispatch(gotSharingPointsTableData(message));
              break;
            case "sharing_point_map_data":
              store.dispatch(gotSharingPointsMapData(message));
              break;
            case "getAllLocationStates":
              store.dispatch(gotLocationStates(message));
              break;
            case "getAllOwnersFull":
              store.dispatch(gotOwners(message));
              break;
            case "getAllProvidersFull":
              store.dispatch(gotProviders(message));
              break;
            case "getAllCompaniesFull":
              store.dispatch(gotCompanies(message));
              break;
            case "getAllManufacturersFull":
              store.dispatch(gotManufacturers(message));
              break;
            case "getAllContactsFull":
              store.dispatch(gotContacts(message));
              break;
            case "techcv_table":
              store.dispatch(gotTechCVs(message));
              break;
            case "getSwapStatistic":
              store.dispatch(getSwapStatisticResponse(message));
              break;

            case "ack":
            case "ACK":
              store.dispatch(gotAckMessage(message));
              if (message.request_type === "updateErrorHistory") {
                store.dispatch(updateErrorHistoryResponse(message));
              } else if (message.request_type === "createCompany") {
                store.dispatch(savedCompany(message));
              }
              break;

            //following cases handle server respond and extend the current state
            /** outdated 
            case "battery_changed":
              store.dispatch(batteryChanged(message));
              break;
            */
            case "DELETED_ELEMENT":
              if (message.request_type === "newFiles") {
                store.dispatch(deletedFile(message));
              }
              break;
            /** new update messages currently only for batteries maybe used later for all changes */
            case "UPDATE":
              if (message.request_type === "battery_table") {
                store.dispatch(batteryChanged(message));
              }
              if (message.request_type === "getAllBatteriesFull") {
                //let battBySN = store.getState();
                store.dispatch(updateBatteryBySerialNumber(message));
              }
              /** backend seems buggy, not every change arives in the frontend*/
              /** updates single opened status for sharing_point */
              if (message.request_type === "getSharingPointStatus") {
                store.dispatch(updateSharingPointStatus(message));
              }
              /** updates sharing_point from sharing */
              if (message.request_type === "sharingpoint_status_table") {
                store.dispatch(updateSharingPointStatuses(message));
              }
              if (message.request_type === "getAllTechCVFull") {
                store.dispatch(updateTechCVTable(message));
              }
              if (message.request_type === "getSharingPointBoxes") {
                store.dispatch(updateSharingPointBox(message));
              }

              if (message.request_type === "newNote") {
                store.dispatch(updateNote(message));
              }
              if (message.request_type === "newFiles") {
                store.dispatch(updateFile(message));
              }
              if (message.request_type === "new_battery_table") {
                store.dispatch(gotBatteryUpdate(message));
              }
              if (message.request_type === "last_techcv_table") {
                store.dispatch(gotTechcvUpdate(message));
              }
              break;

            case "answer":
            case "ANSWER":
              switch (message.request_type) {
                case "getSharingPointInteractions":
                  store.dispatch(gotInteractions(message));
                  break;
                case "getSharingPointSettings":
                  store.dispatch(gotSettingsForSharingPoint(message));
                  break;
                case "getNotesByContextHashKey":
                  store.dispatch(gotNotesByContextHashKey(message));
                  break;
                case "getFilesByContextHashKey":
                  store.dispatch(gotFilesByContextHashKey(message));
                  break;
                case "getSharingPointDetailData":
                  store.dispatch(gotSharingPointDetailData(message));
                  break;
                case "getContactByHashKey":
                  store.dispatch(gotContactByHashKey(message));
                  break;
                case "getCompanyByHashKey":
                  store.dispatch(gotCompanyByHashKey(message));
                  break;
                case "getSharingPointOnlineHistory":
                  store.dispatch(getSharingPointOnlineHistory(message));
                  break;
                case "getSharingPointGsmHistory":
                  store.dispatch(getSharingPointGsmHistory(message));
                  break;
                case "getSharingPointTemperatureHistory":
                  store.dispatch(getSharingPointTempHistory(message));
                  break;
                case "getSharingPointStatus":
                  store.dispatch(gotSharingPointStatus(message));
                  break;
                case "getSharingPointBoxes":
                  store.dispatch(gotSharingPointBoxes(message));
                  break;
                case "getSwapActionsBySpId":
                  store.dispatch(gotSwapActions(message));
                  break;
                case "getLocationDetailData":
                  store.dispatch(gotLocationsDetailData(message));
                  break;
                case "generatedUploadUrl":
                  store.dispatch(gotGeneratedUploadUrl(message));
                  break;
                case "getUrl":
                  store.dispatch(gotFileUrl(message));
                  break;
                case "getErrorHistory":
                case "getErrorHistoryByContext":
                case "getErrorHistoryBySourceId":
                case "getErrorHistoryByRegion":
                  store.dispatch(gotErrorHistory(message));
                  break;
                /*
                case "getNotesByContextHashKey":
                  store.dispatch(gotNotes(message));
                  break;*/
                //case "uploadedFile":
                //  console.log("uploadedFile", message);
                //  break;

                default:
                  console.log("default switch handler for ws type ANSWER");
              }
              break;
            /** new message_type UPDATE_INCRE used for updating sharingpoint details */
            case "company_changed":
              store.dispatch(companyChanged(message));
              break;
            case "techCV_changed":
              store.dispatch(techCVChanged(message));
              break;
            case "getBatteryFullBySerialNumber":
              store.dispatch(gotBatteryBySerialNumber(message));
              break;
            case "getTechCVFullByBatterySN":
              store.dispatch(gotTechCVsByBatterySN(message));
              break;
            default:
            //.log("default websocket", message);
          }
        });
      }
      break;

    case "SEND_MESSAGE":
      // console.log("send message", action.payload);
      try {
        if (tokenExpired(tokenExpiry)) {
          if (socket && socket.close) {
            store.dispatch({
              type: "SET_SOCKET",
              payload: false,
            });
            socket.close();
            socket = undefined;
            // store.dispatch(refreshToken());
            setTimeout(
              () => socket && socket.send(JSON.stringify(action.payload)),
              3000
            );
            tokenExpiry = store.getState().session.tokenExpiry();

            console.log("after token is expired and socket is closed", store);
          }
        } else {
          socket && socket.send(JSON.stringify(action.payload));
        }
      } catch (error) {
        console.log(
          "middleware  WEBSOCKET ERROR: cant send websocket action",
          error,
          action.payload
        );
      }
      break;

    default:
      break;
  }
  return next(action);
};

export default createSocketMiddleware;
