import { createReducer } from "redux-starter-kit"
import { toggleSidebar } from "../actions"

const initialState = {
  sidebarCollapsed: false
}

const uiReducer = createReducer(initialState, {
  [toggleSidebar]: (state, action) => {
    state.sidebarCollapsed = !state.sidebarCollapsed
  }
})

export default uiReducer
