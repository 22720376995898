import { createReducer } from "redux-starter-kit";
import { ownerFetching, owners, ownerIsSaving } from "../actions";

const initialState = {
  owners: null,
  owner: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0,
};

const ownersReducer = createReducer(initialState, {
  [ownerFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [owners]: (state, action) => {
    return {
      ...state,
      owners: action.payload.owners,
      total: action.payload.total,
      fetching: false,
    };
  },
  /**used as the only indicator about
   * saving state as there is no returns beside ack*/
  [ownerIsSaving]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload,
    };
  },
});
export default ownersReducer;
