import React from "react";
import { Button } from "semantic-ui-react";
import { swobbeeColor } from "../helper/colors";

export default function SocialMediaReferences() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: 40,
      }}
    >
      <Button
        icon="linkedin"
        color="linkedin"
        circular
        size="large"
        onClick={() =>
          (window.location =
            "https://www.linkedin.com/company/swobbee/mycompany/")
        }
      />
      <Button
        icon="wordpress"
        style={{ backgroundColor: swobbeeColor, color: "#fff" }}
        circular
        size="large"
        onClick={() => (window.location = "https://swobbee.de/")}
      />
      <Button
        icon="instagram"
        color="instagram"
        circular
        size="large"
        onClick={() =>
          (window.location = "https://www.instagram.com/swobbee/?hl=en")
        }
      />
      <Button
        icon="apple"
        color="black"
        circular
        size="large"
        onClick={() =>
          (window.location =
            "https://apps.apple.com/mn/app/swobbee/id1560583056")
        }
      />
      <Button
        icon="android"
        color="green"
        circular
        size="large"
        onClick={() =>
          (window.location =
            "https://play.google.com/store/apps/details?id=com.swobbee")
        }
      />
    </div>
  );
}
