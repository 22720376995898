/** @format */

const permissions = {
  Provider: "permissionsProvider",
  Notes: "permissionsNotes",
  Company: "permissionsCompany",
  Region: "permissionsRegion",
  Files: "permissionsFiles",
  SharingPointTempHist: "permissionsSharingPointTempHist",
  SharingPointStatus: "permissionsSharingPointStatus",
  Location: "permissionsLocation",
  Manufacturer: "permissionsManufacturer",
  Contact: "permissionsContact",
  UserData: "permissionsUserData",
  Owner: "permissionsOwner",
  SwapAction: "permissionsSwapAction",
  SharingPointOnlineHist: "permissionsSharingPointOnlineHist",
  SharingPointData: "permissionsSharingPointData",
  SharingPoint: "permissionsSharingPoint",
  SharingPointBox: "permissionsSharingPointBox",
  SwapOrder: "permissionsSwapOrder",
  SharingPointGSMHist: "permissionsSharingPointGSMHist",
  ErrorHistory: "permissionsErrorHistory",
  GroupDecoding: "permissionsGroupDecoding",
  SwapStatistic: "permissionsSwapStatistic",
  TechCV: "permissionsTechCV",
  Battery: "permissionsBattery",
  NewBattery: "permissionsNewBattery",
};

const isAllowedToRead = data => {
  let perms = JSON.parse(localStorage.getItem("PERMISSIONS"));
  if (perms) {
    return perms[permissions[data]]
      ? perms[permissions[data]].includes("READ")
      : false;
  } else {
    return false;
  }
};

const isAllowedToWrite = data => {
  let perms = JSON.parse(localStorage.getItem("PERMISSIONS"));
  if (perms) {
    return perms[permissions[data]]
      ? perms[permissions[data]].includes("WRITE")
      : false;
  } else {
    return false;
  }
};

const isAllowedToEdit = data => {
  let perms = JSON.parse(localStorage.getItem("PERMISSIONS"));
  if (perms) {
    return perms[permissions[data]]
      ? perms[permissions[data]].includes("EDIT")
      : false;
  } else {
    return false;
  }
};

const isAllowedToDelete = data => {
  let perms = JSON.parse(localStorage.getItem("PERMISSIONS"));
  if (perms) {
    return perms[permissions[data]]
      ? perms[permissions[data]].includes("DELETE")
      : false;
  } else {
    return false;
  }
};

/** check if the logged user has rights to execute commands
 */
const isAllowedToExecute = data => {
  let perms = JSON.parse(localStorage.getItem("PERMISSIONS"));
  let commandKey = "permissionsWs_command";
  if (perms) {
    return perms[commandKey] ? perms[commandKey].includes("EXECUTE") : false;
  } else {
    return false;
  }
};

export {
  isAllowedToExecute,
  isAllowedToRead,
  isAllowedToWrite,
  isAllowedToEdit,
  isAllowedToDelete,
};
