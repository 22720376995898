import { Link } from "@reach/router";
import React from "react";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { Button, Grid, Segment, Image } from "semantic-ui-react";
import { RHFInputRow } from "../../../helpers/forms/RFHInputRow";
import "./../LoginForm/index.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { RFHErrorMessages } from "../../../helpers/forms/RFHErrorMessages";
import { resetPassword } from "./../../../../store/actions";
import { useDispatch } from "react-redux";

const inputs = [
  { name: "username", type: "text" },
  { name: "confirmation_code", type: "text" },
  { name: "new_password", type: "password" },
  { name: "password_confirmation", type: "password" },
];

const validationSchema = yup.object().shape({
  username: yup.string().required().min(4),
  confirmation_code: yup.string().required().min(4),
  new_password: yup
    .string()
    .required("A password is required.")
    .min(8, "Password must be at least 8 characters long.")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character"
    ),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Please confirm your password."),
});

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    dispatch(
      resetPassword(data.username, data.confirmation_code, data.new_password)
    );
  };
  return (
    <Segment
      style={{
        width: "600px",
        color: "#444",
        margin: "auto",
        marginTop: "80px",
        borderRadius: 15
      }}

    >
      <div className="form-wrapper form-wrapper--reset-password">
        <div style={{ display: "flex", flexDirection: "column" }} >
        <Image src="https://swobbee-frontend-assets.s3.eu-central-1.amazonaws.com/swobbee-logo-moto.png" size='small' style={{margin: "auto"}}/> 
          <span style={{ fontWeight: "300", fontSize: "25px", marginTop: 20 }}>
            Reset password
          </span>

          <span
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: 14,
              color: "gray",
            }}
          >
            The Confirmation Code will be sent to you via email
          </span>
        </div>

        <RFHErrorMessages errors={errors} title=" " />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="ui form form--reset-password"
        >
          {inputs.map((inp) => {
            return (
              <div className="row-wrapper" key={inp.name}>
                <RHFInputRow
                  register={register}
                  label={inp.name.replace("_", " ")}
                  type={inp.type}
                  name={inp.name}
                  key={inp.key}
                  error={errors[inp.name]}
                />
              </div>
            );
          })}
          <Grid>
            <Grid.Column width="8" textAlign="left">
              <Link to="/">login</Link>
            </Grid.Column>
            <Grid.Column width="8" textAlign="right">
              <Button type="submit">Reset password</Button>
            </Grid.Column>
          </Grid>
        </form>
      </div>
    </Segment>
  );
};

export { ResetPasswordForm };
