import { createAction } from "redux-starter-kit";

export const providerFetching = createAction("PROVIDER_FETCHING");
export const providers = createAction("PROVIDERS");
export const providersError = createAction("PROVIDERS_ERROR");

export const providerIsSaving = createAction("PROVIDER_IS_SAVING");
//export const addProvider = createAction("ADD_PROVIDER");

export const getProviders = () => (dispatch, getState) => {
  dispatch(providerFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllProvidersFull",
    },
  });
};

export const gotProviders = (message) => (dispatch) => {
  dispatch(
    providers({
      providers: message.content,
      providersTotal: message.content.length, //message.count || message.splited_org_size,
    })
  );
};

export const addProvider = (provider) => (dispatch, getState) => {
  const providerState = getState().providers;
  const existingProviders = providerState.providers;
  if (!existingProviders || !existingProviders.length) {
    dispatch(getProviders());
  } else {
    //existingProviders.push(provider);
    let newP = [...existingProviders, provider];
    console.log("new P", newP);
    dispatch(
      providers({
        providers: newP,
        providersTotal: newP.length,
      })
    );
  }
};

export const saveProvider = (providerProps) => (dispatch) => {
  dispatch(providerIsSaving(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createProvider",
      data: providerProps,
    },
  });
};
export const savedProvider = () => (dispatch) => {
  dispatch(providerIsSaving(false));
};
