import axios from "axios";
import { countBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import awsconfig from "../../config/awsconfig";

// replaces algolia api with aws location service
// because algolia places was shut down on may 2022

function InputSearchAwsLocation({
  location,
  setSelectedLocation,
  style,
  city_search,
  disabled,
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const [options, setOptions] = useState();

  const searchLocation = async () => {
    try {
      const { data } = await axios.get(
        `${awsconfig.REACT_APP_PUBLIC_API}/${
          city_search ? "cities" : "aws-locations"
        }`,
        {
          params: { search },
        }
      );

      if (!city_search) {
        setOptions(
          data.data.map((item, index) => {
            return {
              text: item.Place.Label,
              key: index,
              value: item.Place.Label,
              item,
            };
          })
        );
      } else {
        setOptions(
          data.data.map((item, index) => {
            return {
              text: `${item.city} (${item.country})`,
              key: index,
              value: index,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!search) return;
    searchLocation();
  }, [search]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className={style}>
      <Input
        placeholder={"Search..."}
        value={search ? search : location?.value}
        disabled={disabled}
        onChange={(event) => {
          console.log(event);
          setSearch(event.target.value);
          setSelectedLocation(null);
        }}
        icon="search"
      />
      {options && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 300,
            border: "1px solid rgba(34,36,38,.15)",
            overflowY: "auto",
            borderRadius: 15,
            backgroundColor: "#fff",
          }}>
          {options.map((item, index) => (
            <div
              className="dropdown-item"
              onClick={() => {
                setSelectedLocation(item);
                setSearch(null);
                setOptions(null);
              }}
              key={index}>
              {item.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default InputSearchAwsLocation;
