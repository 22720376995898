import { createReducer } from "redux-starter-kit";
import {
  companyFetching,
  companies,
  companyIsSaving,
  addCompany,
  companyByHashKey,
} from "../actions";

const initialState = {
  companyByHashKey: null,
  companyByHashKeyIsFetching: false,
  companies: null,
  company: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0
};

const companiesReducer = createReducer(initialState, {
  [companyFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload
    };
  },
  [companies]: (state, action) => {
    return {
      ...state,
      companies: action.payload.companies,
      total: action.payload.companiesTotal,
      fetching: false
    };
  },
  [companyIsSaving]: (state, action) => {
    console.log(action.payload);
    
    return {
      ...state,
      isSaving: action.payload
    };
  },
  [addCompany]: (state, action) => {
    return {
      ...state,
      companies: [action.payload, ...state.companies],
      total: state.total + 1
    };
  },
  [companyByHashKey]: (state, action) => {
    return {
      ...state,
      companyByHashKey: action.payload
    }
  }
});
export default companiesReducer;
