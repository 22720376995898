import { createReducer } from "redux-starter-kit";
import {
  locationsMapDataFetching,
  locationsTableDataFetching,
  locationsMapData,
  locationsTableData,
  locationStates,
  locationIsSaving,
  locationsDetailData,
  isSavingNote,
  gotUploadUrl,
  locationsDetailDataFetching,
  //locationsMapError,
  //locationsTableError
} from "../actions";

const initialState = {
  locationsDetailData: null,
  isFetchingLocationsDetailData: false,
  isSavingNote: false,
  fetchingMapData: false,
  fetchingTableData: false,
  error: false,
  locationsMapData: null,
  locationsTableData: null,
  location: null,
  locationsTotal: 0,
  locationStates: null,
  isSaving: false,
  uploadUrl: null,
  fileHashKey: null,
};

const locationsReducer = createReducer(initialState, {
  [locationsTableDataFetching]: (state, action) => {
    
    return {
      ...state,
      fetchingTableData: action.payload,
    };
  },
  [locationsMapDataFetching]: (state, action) => {
    return {
      ...state,
      fetchingMapData: action.payload,
    };
  },
  [locationsMapData]: (state, action) => {
    return {
      ...state,
      locationsMapData: action.payload.locationsMapData,
      fetchingMapData: action.payload.fetching,
    };
  },
  [locationsTableData]: (state, action) => {
    return {
      ...state,
      locationsTableData: action.payload.locationsTableData,
      locationsTotal: action.payload.locationsTotal,
      fetchingTableData: action.payload.fetching,
    };
  },
  [locationStates]: (state, action) => {
    console.log("location states", action);
    return {
      ...state,
      locationStates: action.payload.locationStates,
    };
  },
  [gotUploadUrl]: (state, action) => {
    console.log("upload url", action);
    return {
      ...state,
      uploadUrl: action.payload.uploadUrl,
      fileHashKey: action.payload.fileHashKey,
    };
  },
  [locationIsSaving]: (state, action) => {
    return {
      ...state,
      isSaving: action.payload,
    };
  },
  [locationsDetailDataFetching]: (state, action) => {
    return {
      ...state,
      isFetchingLocationsDetailData: action.payload,
    };
  },
  [locationsDetailData]: (state, action) => {
    initialState.locationsDetailData = null;
    return {

      ...state,
      locationsDetailData: action.payload,
    };
  },
  [isSavingNote]: (state, action) => {
    return {
      ...state,
      isSavingNote: action.payload,
    };
  },
});

export default locationsReducer;
