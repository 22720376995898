import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  GridColumn,
  Button,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  Label,
  TableCell,
  Segment,
} from "semantic-ui-react";
import _ from "lodash";
import { Link } from "@reach/router";
import { AxiosContext } from "../context/AxiosContext";

export const UserGroupDetailScreen = ({ userGroupName }) => {
  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    last_evaluated_key: null,
    loading: false,
  });
  const { axiosInstance } = useContext(AxiosContext);

  const fetchMyAPI = async () => {
    setState({
      ...state,
      loading: true,
    });
    let { data } = await axiosInstance.get(`/user-groups/${userGroupName}`);
    setState({
      ...state,
      data: data.data,
      last_evaluated_key: data.last_evaluated_key,
      loading: false,
    });
  };

  useEffect(() => {
    fetchMyAPI();
  }, []);

  const { column, data, direction, loading } = state;

  const sortColumn = (column) => {
    if (state.column === column) {
      return setState({
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
    }
    return setState({
      ...state,
      column,
      data: _.sortBy(state.data, [column]),
      direction: "ascending",
    });
  };

  return (
    <Grid columns={1} style={{ width: "90%", margin: "auto" }}>
      {data && (
        <>
          <GridColumn>{JSON.stringify(data)}</GridColumn>
          <GridColumn
            as={Segment}
            width="16"
            style={{ padding: "30px 30px 0px 30px" }}
          >
            {data && (
              <Table fixed sortable style={{ marginBottom: 20 }}>
                <TableHeader style={{ borderRadius: "150px !important" }}>
                  <TableRow>
                    <TableHeaderCell
                      className="left-header"
                      sorted={column === "Username" ? direction : null}
                      onClick={() => sortColumn("Username")}
                    >
                      Username
                    </TableHeaderCell>
                    <TableHeaderCell className="inner-header">
                      Name
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={
                        column === "permissions[0].user_group"
                          ? direction
                          : null
                      }
                      onClick={() => sortColumn("permissions[0].user_group")}
                    >
                      User group
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "UserStatus" ? direction : null}
                      onClick={() => sortColumn("UserStatus")}
                    >
                      Service Password
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "Enabled" ? direction : null}
                      onClick={() => sortColumn("Enabled")}
                    >
                      Email State
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "UserStatus" ? direction : null}
                      onClick={() => sortColumn("UserStatus")}
                    >
                      Account
                    </TableHeaderCell>
                    <TableHeaderCell
                      className="inner-header"
                      sorted={column === "UserCreateDate" ? direction : null}
                      onClick={() => sortColumn("UserCreateDate")}
                    >
                      Created At
                    </TableHeaderCell>

                    <TableHeaderCell
                      className="right-header"
                      sorted={column === "created_at" ? direction : null}
                      onClick={() => sortColumn("created_at")}
                    >
                      Updated At
                    </TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <Table.Body>
                  {!loading &&
                    state.data &&
                    data.users.map(
                      (
                        {
                          user,
                          permissions,
                          UserStatus,
                          Attributes,
                          Enabled,
                          created_at,
                          user_service_password,
                          updated_at,
                          user_group_name,
                          UserAttributes,
                          name,
                        },
                        index
                      ) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Link to={`/profile/${user}`}>{user} </Link>
                          </TableCell>

                          <TableCell>{user}</TableCell>
                          <TableCell>{user_group_name}</TableCell>
                          <TableCell>{user_service_password}</TableCell>
                          <TableCell>
                            <Label
                              color={
                                UserStatus === "CONFIRMED" ? "green" : "grey"
                              }
                              content={UserStatus}
                              circular
                            />
                          </TableCell>
                          <TableCell>
                            <Label
                              color={Enabled ? "green" : "grey"}
                              content={Enabled ? "ENABLED" : "DISABLED"}
                              circular
                            />
                          </TableCell>

                          <TableCell>{created_at}</TableCell>

                          <TableCell>{updated_at}</TableCell>
                        </TableRow>
                      )
                    )}
                </Table.Body>
              </Table>
            )}
          </GridColumn>
          <GridColumn textAlign="center" width="16" style={{ color: "#444" }}>
            {state.data && state.data.length === 0 && "no data available"}
            {state.data && state.data.length > 0 && (
              <Button
                disabled={!state.last_evaluated_key}
                content="Load More"
                basic
                circular
                loading={state.loading}
              />
            )}
          </GridColumn>
        </>
      )}
    </Grid>
  );
};
