import React from "react";
import { Grid, Button } from "semantic-ui-react";
import { Link } from "@reach/router";

const ScreenHeader = ({
  permission,
  heading = "No Header",
  linkHref = "/",
  linkTitle = "back to root!",
  iconName = "plus",
  navi
}) => {
  return (
    <Grid
      className="screen-header-wrapper"
      data-testid="screen-header"
      aria-label="screen header"
    >
      <Grid.Row as="header" className="page-header screen-header">
        <Grid.Column width={9} className="title" verticalAlign="middle">
          {heading}
        </Grid.Column>
        <Grid.Column width={7} textAlign="right" className="right">
          {navi
            ? navi
            : permission && (
                <Link to={linkHref} title={linkTitle} data-testid="add-link">
                  <Button circular icon={iconName} />
                </Link>
              )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ScreenHeader;
