import { Link, Router } from "@reach/router";
import React from "react";
import LoginForm from "./forms/LoginForm/LoginForm";
import ResetPasswordForm from "./forms/ResetPasswordForm";
import ConfirmationForm from "./forms/ConfirmationForm";
import Logo from "./../../assets/images/logo_swobbee.svg";
import pjson from "../../../package.json";
import awsconfig from "./../../config/awsconfig";

const urlPrefix = awsconfig.ADMIN_URL_PREFIX;

const versionNr = pjson.version;
/**
 * Aside react component with nested routes for login, confirmation and reset-password.
 * @returns aside with swobbee-logo, nested views and version number
 */
const Aside = () => {
  return (
    <aside className="aside aside--landing">
      <header>
        <Link to="/" className="logo__wrapper">
          <img
            style={{ margin: "auto" }}
            width="100%"
            height="auto"
            src={Logo}
            alt="swobbee-logo"
          />
        </Link>
      </header>
      <div className="content">
        
          <LoginForm  />
        
      </div>
      <footer>
        <div className="swobbee-url">
          <Link to="/">
            <span>{urlPrefix ? urlPrefix : "admin."}</span>
            <span className="bold">swobbee</span>
            <span>.io</span>
          </Link>
        </div>
        <div className="version">
          <span>{versionNr}</span>
        </div>
      </footer>
    </aside>
  );
};

export { Aside };
