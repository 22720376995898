/** @format */

import { createAction } from "@reduxjs/toolkit";
import _ from "lodash";
import dayjs from "dayjs";
import { getTimeline } from "../../helper/sp-utils";

// for fetching histories with start and end if none is set
const end = Date.now();
const start = end - 1000 * 60 * 60 * 24 * 30;

const timeoutMS = 20000;
let spTableTimeoutRef;

export const sharingPointsMapDataFetching = createAction(
  "SHARING_POINTS_MAP_DATA_FETCHING"
);
export const sharingPointsTableDataFetching = createAction(
  "SHARING_POINTS_TABLE_DATA_FETCHING"
);
export const sharingPointDetailDataFetching = createAction(
  "SHARING_POINT_DETAIL_DATA_FETCHING"
);

export const resetSharingPoints = createAction("RESET_SHARING_POINTS");

export const sharingPointsTableData = createAction("SHARING_POINTS_TABLE_DATA");
export const sharingPointsMapData = createAction("SHARING_POINTS_MAP_DATA");
export const sharingPointDetailData = createAction("SHARING_POINT_DETAIL_DATA");
export const sharingPointDetailDataError = createAction(
  "SHARING_POINT_DETAIL_DATA_ERROR"
);

export const selectSharingPoint = createAction("SELECT_SHARING_POINT");

export const sharingPointsTableError = createAction("SHARING_POINTS_MAP_ERROR");
export const sharingPointsMapError = createAction("SHARING_POINTS_MAP_ERROR");

export const sharingPointIsSaving = createAction("SHARING_POINT_IS_SAVING");
export const sharingPointSaveError = createAction("SHARIN_POINT_SAVE_ERROR");
// status
export const sharingPointStatus = createAction("SHARING_POINT_STATUS");
export const isFetchingStatus = createAction("IS_FETCHING_STATUS");
// boxes
export const sharingPointBoxes = createAction("SHARING_POINT_BOXES");
export const fetchSharingPointBoxes = createAction("FETCH_SHARING_POINT_BOXES");
export const sharingPointBoxesError = createAction("SHARING_POINT_BOXES_ERROR");
export const updateBox = createAction("UPDATE_BOX");
//settings
export const fetchSettings = createAction("FETCH_SETTINGS");
export const isSavingSettings = createAction("IS_SAVING_SETTINGS");
export const settingsError = createAction("SETTINGS_ERROR");
export const sharingPointSettings = createAction("SHARING_POINT_SETTINGS");

// history fetch flags
export const fetchHistoryGSMData = createAction("FETCH_HISTORY_GSM_DATA");
export const fetchHistoryTempData = createAction("FETCH_HISTORY_TEMP_DATA");
export const fetchHistoryOnlineData = createAction("FETCH_HISTORY_ONLINE_DATA");
// history
export const historyGSMData = createAction("HISTORY_GSM_DATA");
export const historyTempData = createAction("HISTORY_TEMP_DATA");
export const historyOnlineData = createAction("HISTORY_ONLINE_DATA");

// swapActions+
export const isFetchingSwapActions = createAction("IS_FETCHING_SWAP_ACTIONS");
export const swapActions = createAction("SWAP_ACTIONS");
export const swapActionsError = createAction("SWAP_ACTIONS_ERROR");
export const resetSwapActions = createAction("RESET_SWAP_ACTIONS");
export const openDoorRequest = createAction("OPEN_DOOR_REQUEST");
export const openDoorSuccess = createAction("OPEN_DOOR_SUCCESS");
export const openDoorError = createAction("OPEN_DOOR_ERROR");

export const requestCommand = createAction("REQUEST_COMMAND");
export const commandSuccess = createAction("COMMAND_SUCCESS");

// interactions
export const isFetchingInteractions = createAction("IS_FETCHING_INTERACTIONS");
export const interactionsError = createAction("INTERACTIONS_ERROR");
export const interactions = createAction("INTERACTIONS");
export const resetInteractions = createAction("RESET_INTERACTIONS");
export const spCleanUp = createAction("SP_CLEAN_UP");

/*command additions 
 isStartAndStoppingAirCondition: false,
 isRebootingIpc: false,
 isTogglingFiSafetySwitch: false,
 isClearingErrors: false,
 isClearingWarnings: false
*/
const commandRequestTypes = [
  "startAndStopAirCondition",
  "powerDCEnable",
  "restartChromiumForce",
  "logicDCEnable",
  "switchLighting",
  "reboot",
  "toggleFiSafetySwitch",
  "switchTMVentilation",
  "switchTMVentilationForce",
  "switchTMHeater",
  "switchTMHeaterForce",
  "switchTMAirConditioner",
  "switchTMAirConditionerForce",
  "clearErrors",
  "clearWarnings",
  "getSharingPointStatus",
  "resyncSPConfig",
];
//RELOAD,DOOR_OPEN,START_AND_STOP_CHARGING
export const getInteractions =
  (data = {}) =>
  (dispatch, getState) => {
    if (Object.keys(data).length === 0) return;
    dispatch(isFetchingInteractions(true));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "getSharingPointInteractions",
        data,
      },
    });
  };

export const gotInteractions = message => dispatch => {
  dispatch(isFetchingInteractions(false));
  const { statusCode, content } = message;

  switch (statusCode) {
    case 200:
    case 204:
      dispatch(interactions(content));
      break;
    case 400:
    case 422:
      dispatch(
        interactionsError({
          type: statusCode,
          message: "422 unprocessible request",
        })
      );
      break;
    default:
      break;
  }
};

export const sendCommand =
  ({ data }) =>
  dispatch => {
    if (!commandRequestTypes.includes(data.command)) return;
    const payload = {
      message: "command",
      request: data.command,
      data: data.data,
    };
    dispatch(requestCommand(payload));
    dispatch({
      type: "SEND_MESSAGE",
      payload: payload,
    });
  };

/** end addition commands */

export const requestOpenDoor = data => (dispatch, getState) => {
  console.log("request open door data", data);
  dispatch(openDoorRequest(data));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "command",
      request: "doorOpen",
      data,
    },
  });
};

export const startAndStopCharging = data => (dispatch, getState) => {
  console.log("stop charging ccc", data);
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "command",
      request: "startAndStopCharging",
      data,
    },
  });
};

export const bmsCMProtocolAction = data => (dispatch, getState) => {
  console.log("bmsCMProtocolAction", data);
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "command",
      request: "saveBmsConfig",
      data,
    },
  });
};

export const resetSPErrorsAction = data => (dispatch, getState) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "command",
      request: "resetSPErrors",
      data,
    },
  });
};

export const resetCMErrorsAction = data => (dispatch, getState) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "command",
      request: "resetCMErrors",
      data,
    },
  });
};

export const resetBattErrorsAction = data => (dispatch, getState) => {
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "command",
      request: "resetBattErrors",
      data,
    },
  });
};

export const getSharingPointsMapData = () => (dispatch, getState) => {
  const isFetching = getState().sharingPoints.fetchingMapData;
  if (isFetching) return;
  dispatch(sharingPointsMapDataFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAll",
      query: "sharing_point_map_data",
    },
  });
};

export const getSharingPointDetailData =
  sp_serial_number => (dispatch, getState) => {
    dispatch(sharingPointDetailDataFetching(true));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "standard",
        request: "getSharingPointDetailData",
        data: { sp_serial_number },
      },
    });
  };

export const getSharingPointsTableData = () => (dispatch, getState) => {
  const isFetching = getState().sharingPoints.fetchingTableData;
  if (isFetching) return;
  dispatch(sharingPointsTableDataFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAll",
      query: "sharingpoint_table",
    },
  });
  spTableTimeoutRef = setTimeout(() => {
    dispatch(sharingPointsTableDataFetching(false));
    dispatch(
      sharingPointsTableError({
        key: "ServerTimeout",
        displayMessage:
          "Server Timeout Error. Sharingpoint Table Request proceeded without response.",
      })
    );
    spTableTimeoutRef = undefined;
  }, timeoutMS);
};
// remove splited_org_size or message.count
// it is not sended anymore
export const gotSharingPointsTableData = message => (dispatch, getState) => {
  if (spTableTimeoutRef) clearTimeout(spTableTimeoutRef);
  spTableTimeoutRef = undefined;
  const state = getState();
  let total, newSharingPoints, loaded;

  if (message.splited) {
    total = message.splited_org_size || message.count;
    const existing = state.sharingPoints.sharingPointsTableData || [];
    newSharingPoints = [...existing, ...message.content];
    loaded = total === newSharingPoints.length;
    dispatch(sharingPointsTableDataFetching(!loaded));
  } else {
    console.log("got data");
    newSharingPoints = [...message.content];
    loaded = true;
    total = message.content.length;
    dispatch(sharingPointsTableDataFetching(false));
  }

  dispatch(
    sharingPointsTableData({
      locationsTableData: newSharingPoints,
      locationsTotal: total,
    })
  );
};

export const gotSharingPointDetailData = message => (dispatch, getState) => {
  const statusCode = message.statusCode;
  if (statusCode === 200 || statusCode === 204) {
    dispatch(sharingPointDetailData(message.content));
  } else {
    const { sp_serial_number } = message.request_parameters || {};
    dispatch(
      sharingPointDetailDataError({
        error: `Can't fetch detail data for sp ${sp_serial_number}`,
      })
    );
  }
};

// remove splited_org_size or message.count
// it is not sended anymore
export const gotSharingPointsMapData = message => (dispatch, getState) => {
  const state = getState();
  let total, newSharingPoints, loaded;

  if (message.splited) {
    total = message.splited_org_size || message.count;
    let existing = state.sharingPoints.sharingPointsMapData || [];
    newSharingPoints = [...existing, ...message.content];
    loaded = total === newSharingPoints.length;
  } else {
    total = message.content.length;
    newSharingPoints = [...message.content];
    loaded = total === newSharingPoints.length;
  }
  dispatch(
    sharingPointsMapData({
      locationsMapData: newSharingPoints,
      locationsTotal: total,
      fetching: !loaded,
    })
  );
};

export const saveSharingPoint = sharingPointProps => (dispatch, getState) => {
  dispatch(sharingPointIsSaving(true));
  let convertedProps = {};
  //convert pascalCase keys to underscore keys
  Object.entries(sharingPointProps).forEach(entry => {
    convertedProps[_.snakeCase(entry[0])] = entry[1];
  });
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createSharingPoint",
      data: convertedProps,
    },
  });
};
export const savedSharingPoint = sharingPointProps => (dispatch, getState) => {
  dispatch(sharingPointIsSaving(false));
};

/** settings specific actions */
export const fetchSettingsForSharingPoint =
  ({ spSerialNumber } = {}) =>
  (dispatch, getState) => {
    if (!spSerialNumber) return;
    dispatch(fetchSettings(true));

    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "standard",
        request: "getSharingPointSettings",
        data: { sp_serial_number: spSerialNumber },
      },
    });
  };

export const saveSharingPointSettings = data => (dispatch, getState) => {
  if (!data.spSerialNumber) return;
  const spSerialNumber = data.spSerialNumber;
  for (var key in data) {
    if (
      typeof data[key] !== "object" &&
      typeof data[key] !== "boolean" &&
      data[key] >= 0
    ) {
      data[key] = parseFloat(data[key]);
      console.log("item[key]", data[key]);
    }
  }
  delete data["spSerialNumber"];
  dispatch(isSavingSettings(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "putSharingPointSettings",
      data: {
        sp_serial_number: spSerialNumber,
        data,
      },
    },
  });
};
/** changed to new luc format of message. the message.request object was renamed to request_parameters */
export const gotSettingsForSharingPoint = message => (dispatch, getState) => {
  if (!message) {
  } else {
    const spSerialNumber = message.request_parameters.sp_serial_number;
    const content = message.content;
    dispatch(fetchSettings(false));
    const statusCode = message.statusCode;
    if (statusCode === 404) {
      let error = {};
      error = {
        key: "NoData",
        displayMessage: `There are no settings for Sharingpoint ${spSerialNumber}`,
        statusCode: message.statusCode,
      };
      dispatch(settingsError(error));
    } else if (statusCode === 200 || statusCode === 204) {
      // parse ignore_sensors object to array in needed format for react-hook-form fieldsArray
      let settings = (content && content.settings) || {};
      let ignore =
        content && content.settings && content.settings.ignore_sensors;
      let ignArray = [];
      if (ignore) {
        Object.keys(ignore).forEach(key => {
          let cmObject = { cmId: key, sensorIds: [] };
          Object.keys(ignore[key]).forEach(innerKey => {
            if (ignore[key][innerKey]) cmObject["sensorIds"].push(innerKey);
          });
          ignArray.push(cmObject);
        });
      }

      //let parsed = {...content.settings, ignore_sensors: }
      const newSettings = {
        spSerialNumber,
        content: {
          sharing_point_type: content.sharing_point_type,
          settings: {
            ...settings,
            spSerialNumber,
            ignore_sensors: ignArray,
          },
        },
      };
      dispatch(sharingPointSettings(newSettings));
    }
  }
};
export const clearSettings = () => (dispatch, getState) => {
  dispatch(sharingPointSettings(undefined));
};
/** history related async actions  */
export const fetchTempHistoryForSharingPoint =
  ({
    spSerialNumber,
    data = "Temp",
    endTime = end,
    initialValue = 1,
    startTime = start,
  } = {}) =>
  (dispatch, getState) => {
    if (!spSerialNumber) return;
    dispatch(fetchHistoryTempData(true));
    fetchHistoryData(
      dispatch,
      { spSerialNumber, data, endTime, initialValue, startTime },
      "getSharingPointTemperatureHistory"
    );
  };

export const fetchGSMHistoryForSharingPoint =
  ({
    spSerialNumber,
    data = "GSM",
    endTime = end,
    initialValue = 1,
    startTime = start,
  } = {}) =>
  (dispatch, getState) => {
    if (!spSerialNumber) return;
    dispatch(fetchHistoryGSMData(true));
    fetchHistoryData(
      dispatch,
      { spSerialNumber, data, endTime, initialValue, startTime },
      "getSharingPointGsmHistory"
    );
  };
export const fetchOnlineHistoryForSharingPoint =
  ({
    spSerialNumber,
    data = "Online",
    endTime = end,
    initialValue = 1,
    startTime = start,
  } = {}) =>
  (dispatch, getState) => {
    if (!spSerialNumber) return;
    dispatch(fetchHistoryOnlineData(true));
    fetchHistoryData(
      dispatch,
      { spSerialNumber, data, endTime, initialValue, startTime },
      "getSharingPointOnlineHistory"
    );
  };

export const getSharingPointGsmHistory = message => (dispatch, getState) => {
  const reqParams = message.request_parameters;
  const spSerialNumber = reqParams.sp_serial_number;
  const content = message.content;
  const first = content[0];

  if (first && first.timestamp < reqParams.start_time) {
    content[0] = { ...content[0], timestamp: reqParams.start_time };
  }
  //content[0] = { ...content[0], timestamp: reqParams.start_time };
  let last = content[content.length - 1];
  if (last && last.timestamp < reqParams.end_time) {
    content.push({ ...last, timestamp: reqParams.end_time });
  }
  dispatch(
    historyGSMData({
      spSerialNumber,
      content,
      requestParams: message.request_parameters,
    })
  );
};
export const getSharingPointTempHistory = message => (dispatch, getState) => {
  const histTempData = getState().sharingPoints.historyTempData;
  const reqParams = message.request_parameters;
  const spSerialNumber = reqParams.sp_serial_number;
  let content =
    message.message_count > 1
      ? histTempData
        ? [...histTempData.content, ...message.content]
        : message.content
      : message.content;
  /**  set first item timestamp to request start_time as it is not normalized */
  const first = content[0];
  if (first && first.timestamp < reqParams.start_time) {
    content[0] = { ...content[0], timestamp: reqParams.start_time };
  }
  //content[0] = { ...content[0], timestamp: reqParams.start_time };
  let last = content[content.length - 1];
  if (last && last.timestamp < reqParams.end_time) {
    content.push({ ...last, timestamp: reqParams.end_time });
  }
  dispatch(
    historyTempData({
      spSerialNumber,
      content,
      requestParams: message.request_parameters,
    })
  );
};
export const getSharingPointOnlineHistory = message => (dispatch, getState) => {
  const reqParams = message.request_parameters;

  const spSerialNumber = message.request_parameters.sp_serial_number;
  const content = message.content;
  const first = content[0];
  if (first && first.timestamp < reqParams.start_time) {
    content[0] = { ...content[0], timestamp: reqParams.start_time };
  }
  let last = content[content.length - 1];
  if (last && last.timestamp < reqParams.end_time) {
    content.push({ ...last, timestamp: reqParams.end_time });
  }
  const params = message.request_parameters;
  let calculated = getTimeline({ data: content, params });
  //content = sortBy(content, (item) => item.timestamp);
  dispatch(
    historyOnlineData({
      spSerialNumber,
      content: calculated,
      requestParams: params,
    })
  );
};

/** status related async actions */
export const fetchSharingPointStatus =
  ({ spSerialNumber } = {}) =>
  (dispatch, getState) => {
    if (!spSerialNumber) return;
    dispatch(isFetchingStatus(true));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "standard", // <-- the new standard?? watch the answer has the type.. answer
        request: "getSharingPointStatus",
        data: {
          sp_serial_number: spSerialNumber,
        },
      },
    });
  };
/** box related */
export const getSharingPointBoxes =
  ({ spSerialNumber }) =>
  dispatch => {
    dispatch(sharingPointBoxesError(null));
    dispatch(sharingPointBoxes(null));
    if (!spSerialNumber) return;
    dispatch(fetchSharingPointBoxes(true));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "standard", // <-- the new standard?? watch the answer has the type.. answer
        request: "getSharingPointBoxes",
        data: {
          sp_serial_number: spSerialNumber,
        },
      },
    });
  };
/** called when message UPDATE_INCRE is received
 *   checks if message is related to current sp
 *   if so it filters for the box_number and creates new state
 * */
export const updateSharingPointBox = message => (dispatch, getState) => {
  const sharingPoints = getState().sharingPoints;
  const boxes = sharingPoints.sharingPointBoxes;

  if (!boxes || !message.request_parameters) return;
  if (
    boxes[0]["sp_serial_number"] ===
    message.request_parameters["sp_serial_number"]
  ) {
    let content = message.content;
    if (!content) return;
    dispatch(updateBox(content));
    if (content.door_state && content.door_state === "open") {
      dispatch(openDoorSuccess(content));
    }
  }
};

export const gotSharingPointBoxes = message => dispatch => {
  if (message === null) {
    dispatch(sharingPointBoxes());
    return;
  }
  const spSerialNumber = message.request_parameters["sp_serial_number"];
  switch (message.statusCode) {
    case 200:
      dispatch(sharingPointBoxes(message.content));
      break;
    case 204:
      dispatch(
        sharingPointBoxesError({
          key: "NoData",
          displayMessage: `No Boxinformation for ${spSerialNumber}.`,
        })
      );
      break;

    case 403:
    case 404:
      dispatch(
        sharingPointBoxesError({
          key: "Authentication",
          displayMessage: `Authentication error: No right to retrieve subject information for ${spSerialNumber}.`,
        })
      );
      break;
    default:
      //  if (message.statusCode === 400 || message.statusCode === 500) {
      dispatch(
        sharingPointBoxesError({
          key: "Error",
          displayMessage: `Error when loading the sharingpoint boxes ${spSerialNumber}.`,
          statusCode: message.statusCode,
        })
      );
      break;
  }
  dispatch(fetchSharingPointBoxes(false));
};

export const gotSharingPointStatus = message => (dispatch, getState) => {
  const spSerialNumber = message.request_parameters["sp_serial_number"];
  let status;
  let error;
  dispatch(isFetchingStatus(false));

  switch (message.statusCode) {
    case 200:
      status = message.content[0];
      break;
    case 204:
      status = message.content || null;
      error = {
        key: "NoData",
        message: `No status data for Sharingpoint ${spSerialNumber}.`,
        statusCode: message.statusCode,
      };
      break;
    case 403:
    case 404:
      status = null;
      error = {
        key: "Authentication",
        message: `Authentificationerror: Status for Sharingpoint ${spSerialNumber} can't be queried.`,
        statusCode: message.statusCode,
      };

      break;
    default:
      status = null;
  }
  dispatch(sharingPointStatus({ status, error }));
};
/** updates from sharingpoint_status_table */
export const updateSharingPointStatuses = message => (dispatch, getState) => {
  const sharingPoints = getState().sharingPoints.sharingPointsTableData;
  const content = message.content;
  if (message.statusCode === 200) {
    const updateSharingPointName = content.sharing_point_name;
    if (sharingPoints && updateSharingPointName !== "UNKNOWN") {
      let changed = false;
      const newSharingPointsTableData = sharingPoints.map(sp => {
        if (sp.sharing_point_name === updateSharingPointName) {
          changed = true;
          return { ...sp, ...content };
        }
        return sp;
      });
      if (changed) {
        dispatch(
          sharingPointsTableData({
            locationsTableData: newSharingPointsTableData,
            locationsTotal: newSharingPointsTableData.length,
            fetchingTableData: false,
          })
        );
      } else {
        console.log("ERROR: sharing_point_status: the sp has name 'UNKNOWN'");
      }
    }
  }
};

/** updates sharing-point status in detail screen from sharingPointStatus*/
export const updateSharingPointStatus = message => (dispatch, getState) => {
  const currentStatus = getState().sharingPoints.status;
  if (!currentStatus) return;
  const content = message.content;
  if (currentStatus.sharing_point_name === content.sharing_point_name) {
    let newStatus = { ...currentStatus, ...message.content };
    dispatch(sharingPointStatus({ status: newStatus, error: undefined }));
  }
};

/** helper methods no need to export */
const fetchHistoryData = (dispatch, data, request) => {
  if (!dispatch || !request) return;
  const endTime = dayjs(data.endTime).endOf("day").valueOf();
  const startTime = dayjs(data.startTime).startOf("day").valueOf();
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard", // <-- the new standard?? watch the answer has the type.. answer
      request: request,
      data: {
        sp_serial_number: data.spSerialNumber,
        end_time: endTime,
        data: data.data,
        initial_value: data.initialValue,
        start_time: startTime,
      },
    },
  });
};

/* fetch swap actions for one sharingpoint*/
export const fetchSwapActions =
  ({ spSerialNumber }) =>
  (dispatch, getState) => {
    dispatch(resetSwapActions());
    if (!spSerialNumber) return;
    let isFetching = getState().sharingPoints["isFetchingSwapActions"];
    if (isFetching) return;
    dispatch(isFetchingSwapActions(true));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "getSwapActionsBySpId", // <-- the new standard?? watch the answer has the type.. answer
        data: {
          sp_serial_number: spSerialNumber,
        },
      },
    });
  };

export const gotSwapActions = message => (dispatch, getState) => {
  const spSerialNumber =
    message.request_parameters && message.request_parameters.sp_serial_number;
  switch (message.statusCode) {
    case 200:
      dispatch(swapActions(message));
      break;
    case 204:
      dispatch(swapActions({ content: [] }));
      dispatch(
        swapActionsError(
          `no swaps available for sharingpoint ${spSerialNumber}.`
        )
      );
      break;
    case 404:
      dispatch(swapActions({ content: [] }));
      dispatch(
        swapActionsError(
          `Authentificationerror: Swap actions for Sharingpoint ${spSerialNumber} can't be queried.`
          /*{
          key: "Authentication",
          message: `Authentificationerror: Swap actions for Sharingpoint ${spSerialNumber} can't be queried.`,
          statusCode: message.statusCode,
        }*/
        )
      );
      break;
    default:
      console.log("default handlerr for swapActions response");
  }
  dispatch(isFetchingSwapActions(false));
};

/* fetch swap actions for one sharingpoint*/
export const clearSharingPoints = () => (dispatch, getState) => {
  dispatch(spCleanUp());
};
