import { createAction } from "redux-starter-kit";
import { addContact } from "./contactActions";
import { addManufacturer } from "./manufacturerActions";
import { addOwner } from "./ownerActions";
import { addProvider } from "./providerActions";
/** async actions
 * https://github.com/reduxjs/redux-toolkit/issues/76
 */

export const companyByHashKey = createAction("COMPANY_BY_HASH_KEY");
export const companyByHashKeyIsFetching = createAction(
  "COMPANIE_BY_HASH_KEY_IS_FETCHING"
);

export const companyFetching = createAction("COMPANY_FETCHING");
export const companies = createAction("COMPANIES");
export const companiesError = createAction("COMPANIES_ERROR");
export const companyIsSaving = createAction("COMPANY_IS_SAVING");
export const addCompany = createAction("ADD_COMPANY");

export const getCompanies = () => (dispatch, getState) => {
  dispatch(companyFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllCompaniesFull",
    },
  });
};

export const gotCompanies = (message) => (dispatch, getState) => {
  dispatch(
    companies({
      companies: message.content,
      companiesTotal: message.content.length,
    })
  );
};

export const getCompanyByHashKey = (hash_key) => (dispatch, getState) => {
  dispatch(companyByHashKeyIsFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getCompanyByHashKey",
      data: { hash_key: hash_key },
    },
  });
};

export const gotCompanyByHashKey = (message) => (dispatch, getState) => {
  dispatch(companyByHashKeyIsFetching(false));
  dispatch(companyByHashKey(message.content));
};

export const saveCompany = (companyProps) => (dispatch, getState) => {
  dispatch(companyIsSaving(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createCompany",
      data: companyProps,
    },
  });
};
/** handles createCompany with ACK must be anebled for 3 cases
 * createCompany companyType='company' backend returns only the company as the return object
 * createCompany companyType='provider' backend returns object with the company and the provider
 * createCompany companyType='owner' backend returns object with the company and the owner
 * createCompany companyType='manufacturer' backend returns object with the company and the manufacturer
 */
export const savedCompany = (message) => (dispatch, getState) => {
  const state = getState();
  const { content } = message;
  dispatch(addCompany(content["company"]));
  if (content["company"]["contactPartner"]) {
    dispatch(addContact(content["company"]["contactPartner"]));
  }
  if (content["provider"]) dispatch(addProvider(content["provider"]));
  if (content["owner"]) dispatch(addOwner(content["owner"]));
  if (content["manufacturer"])
    dispatch(addManufacturer(content["manufacturer"]));
  dispatch(companyIsSaving(false));
};

export const companyChanged = (message) => (dispatch, getState) => {
  const state = getState();
  let company = message.content;
  let isNew = true;
  const companies = state.companies.companies.filter((comp) => {
    let found = comp.hash_key === company.hash_key;
    if (found) isNew = false;
    return !found;
  });
  let total = state.companies.total;
  if (isNew) total = total + 1;
  dispatch(
    companies({
      ...state,
      companies: [...companies, company],
      total: total,
    })
  );
};
