import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

//import { useTranslation } from "react-i18next";
dayjs.extend(utc);
dayjs.extend(timezone);
/** returns simpler address object from sharingPoint */
function getAddress(sp) {
  const street =
    sp.address_street && `${sp.address_street} ${sp.address_street_number}`;
  const city = sp.address_city && `${sp.address_zipcode}, ${sp.address_city}, `;
  const country = sp.address_country;
  return { street, city, country };
}
function getAddressString(sp) {
  const addressOb = getAddress(sp);
  return Object.values(addressOb).join(", ");
}

function getGsmColor(signal = 0) {
  let color = "#ccc";
  signal = +signal;
  if (signal < 7) {
    color = "red";
  }
  if (signal > 7) {
    color = "orange";
  }
  if (signal > 10) {
    color = "green";
  }
  return color;
}

/** used for css-order in battery-slots */
function getItemOrder(num) {
  switch (+num) {
    case 1:
      return 1;
    case 2:
      return 4;
    case 3:
      return 2;
    case 4:
      return 5;
    case 5:
      return 3;
    case 6:
      return 6;
    default:
      return 1;
  }
}
/** used in box item  */
function getDoorStateIcon(doorState) {
  let iconName = "question";
  let iconColor = "grey";
  switch (doorState) {
    case "locked":
    case "LOCKED":
      iconName = "lock";
      iconColor = "green";
      break;
    case "open":
    case "opened":
    case "OPEN":
      iconName = "lock open";
      iconColor = "red";
      break;
    case "door_jammed":
    case "doorJammed":
    case "DOOR_JAMMED":
    case "DOOR_ERROR":
      iconName = "unlock";
      iconColor = "orange";
      break;
    case "undefined":
    case "UNDEFINED":
      iconName = "question";
      break;
    default:
      iconName = "question";
      break;
  }
  return { iconName, iconColor };
}
/** general time format */
function formatTimestamp(timestamp = Date.now(), format = "DD.MM.YY HH:mm") {
  return dayjs(timestamp).tz("Europe/Berlin").format(format);
}

function slotIcon(slot) {
  const slotS = slotState(slot);
  return slotS === "ready"
    ? "battery full"
    : slotS === "charging"
    ? "battery half"
    : "close";
}
function newSlotIconName(slotState) {
  let iconName = "close";
  switch (slotState) {
    case "Ready":
      iconName = "battery full";
      break;
    case "Present":
      iconName = "battery half";
      break;
    default:
      break;
  }
  return iconName;
}

function slotState(slot) {
  return slot["SWAP_READY"] && slot["PRESENT"]
    ? "ready"
    : slot["PRESENT"]
    ? "charging"
    : "empty";
}
function newSlotState(slotState) {
  let newState = "empty";
  switch (slotState) {
    case "Present":
      newState = "charging";
      break;
    case "Ready":
      newState = "ready";
      break;
    default:
      break;
  }
  return newState;
}

/** helper method to generate timeline for history of online data for redux action */
function getTimeline({ data, params }) {
  let newProps = [];
  if (!data) return;
  data.forEach((item, index) => {
    const obj = {};
    let next = data[index + 1];
    obj["start_time"] = item.timestamp;
    obj["is_online"] = item.is_online;
    if (index === 0) {
      obj["start_time"] =
        item.timestamp < params.start_time ? params.start_time : item.timestamp;
      obj["end_time"] = item.timestamp;
    }
    if (next) {
      obj["end_time"] = next.timestamp;
    } else {
      obj["end_time"] = params.end_time;
    }
    newProps.push(obj);
  });
  newProps[newProps.length - 1] = {
    ...newProps[newProps.length - 1],
    end_time: params.end_time,
  };
  return newProps;
}
export {
  getAddress,
  getAddressString,
  getDoorStateIcon,
  getItemOrder,
  getGsmColor,
  formatTimestamp,
  slotIcon,
  slotState,
  newSlotIconName,
  newSlotState,
  getTimeline,
};
