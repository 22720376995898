import React from "react";
import ErrorsScreen from "./../../screens/ErrorsScreen";

export default () => {
  return (
    <div style={{ height: "auto" }}>
      <ErrorsScreen />
    </div>
  );
};
