import React from "react";
import "./style.scss";
import pjson from "../../../package.json";

export default () => {
  const versionNr = pjson.version;
  return (
    <div className="footer-container">
      <span className="footer-left-text">
        {!process.env.REACT_APP_NODE_ENV.includes("rel") &&
          process.env.REACT_APP_NODE_ENV + " - "}
        {versionNr}
      </span>
      <div className="footer-right-side">
        <a className="footer-text" href="https://swobbee.de/en/">
          © {new Date().getFullYear()}, Swobbee gmbh
        </a>

        <a className="footer-text" href="https://swobbee.de/en/privacy-policy/">
          Privacy
        </a>
        <a className="footer-text" href="https://swobbee.de/en/terms/">
          Terms
        </a>
      </div>
    </div>
  );
};
