import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  GridColumn,
  Segment,
  Input,
  Header,
  Label,
  Loader,
  Button,
  List,
  ListItem
} from "semantic-ui-react";
import { Link } from "@reach/router";
import _ from "lodash";
import { AxiosContext } from "../../context/AxiosContext";

export default () => {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState();
  const [loading, setLoading] = useState();

  let typingTimer;
  const { axiosInstance } = useContext(AxiosContext);
  const fetchSharingpoints = async search => {
    setLoading(true);
    const { data } = await axiosInstance.get("/sharingpoints", {
      params: {
        last_evaluated_key: lastEvaluatedKey?.sp_serial_number,
        search
      }
    });

    setLastEvaluatedKey(data.last_evaluated_key);
    setState({
      ...state,
      searchResults: state.searchResults
        ? _.sortBy([...state.searchResults, ...data.data], "sp_serial_number")
        : _.sortBy(data.data, "sp_serial_number")
    });
    setLoading(false);
  };

  const [state, setState] = useState({
    column: null,
    data: null,
    direction: null,
    searchResults: null,
    searchTerm: null
  });

  const { searchResults } = state;

  const handleChange = async event => {
    // setState({ ...state, searchTerm: event.target.value });
    let search = event.target.value;

    clearInterval(typingTimer);
    setLoading(true);
    typingTimer = setTimeout(async () => {
      const { data } = await axiosInstance.get("/sharingpoints", {
        params: {
          search
        }
      });
      setState({
        ...state,
        searchResults: data.data
      });
      setLoading(false);
    }, 1000);
  };

  // useEffect(() => {
  //   // if (!data) return;
  //   // const results = tableData.filter(item => {
  //   //   return item.sp_serial_number
  //   //     .toLowerCase()
  //   //     .includes(searchTerm.toLowerCase());
  //   // });
  //   // setState({ ...state, searchResults: results });
  //   alert()
  // }, [searchTerm]);

  useEffect(() => {
    window.scroll(0, 0);
    fetchSharingpoints();
  }, []);

  return (
    <Container style={{ marginTop: "100px", marginBottom: "40px" }}>
      {!searchResults && (
        <Loader
          style={{ position: "fixed", marginLeft: "45%", marginTop: "400px" }}
          inline
          active
          size="large"
        />
      )}
      {searchResults && (
        <Grid columns="1">
          <GridColumn>
            <Input
              fluid
              placeholder="Search..."
              icon="search"
              onChange={handleChange}
              loading={loading}
            />
          </GridColumn>

          <GridColumn>
            <Header>
              {searchResults.length} / {searchResults.length} Sharingpoints
            </Header>
          </GridColumn>
          <GridColumn>
            <List>
              {searchResults.map((item, index) => (
                <ListItem>
                  <Link to={"/" + item.sp_serial_number}>
                    <Segment fluid>
                      <Grid columns="1">
                        <GridColumn
                          style={{ padding: "14px 14px 0px 14px" }}
                          verticalAlign="middle"
                        >
                          <Link to={"/" + item.sp_serial_number}>
                            <Header
                              style={{ float: "left" }}
                              content={item.nick_name}
                            />
                            <Label
                              size="tiny"
                              circular
                              style={{ float: "right", verticalAlign: "middle" }}
                              content={item.sp_serial_number}
                              color="black"
                            />
                          </Link>

                          <Label
                            size="tiny"
                            circular
                            style={{ float: "right", verticalAlign: "middle" }}
                            content={item.is_online ? "online" : "offline"}
                            color={item.is_online ? "green" : "red"}
                          />
                          <Label
                            size="tiny"
                            circular
                            style={{ float: "right", verticalAlign: "middle" }}
                            content={item.sharing_point_type}
                            color="blue"
                          />
                        </GridColumn>
                        <GridColumn style={{ padding: "14px 14px 14px 14px" }}>
                          <span style={{ color: "black" }}>
                            {item.street} {item.street_number}, <br />
                            {item.zipcode} {item.city}
                          </span>
                        </GridColumn>
                        {item.box_status_check &&
                          Object.keys(item.box_status_check).map(
                            (key, index) => (
                              <GridColumn
                                style={{ padding: "0px 14px 14px 14px" }}
                                key={index}
                              >
                                <span style={{ color: "black" }}>
                                  {_.upperFirst(key).replaceAll("_", " ")} x{" "}
                                  {item.box_status_check[key]}{" "}
                                </span>
                              </GridColumn>
                            )
                          )}
                        <GridColumn>
                          <Button primary content="Detail" fluid circular />
                        </GridColumn>
                      </Grid>
                    </Segment>
                  </Link>
                </ListItem>
              ))}
            </List>
          </GridColumn>

          {lastEvaluatedKey && (
            <GridColumn textAlign="center">
              <Button
                color="orange"
                content="Load More"
                basic
                circular
                loading={loading}
                onClick={() => fetchSharingpoints()}
              />
            </GridColumn>
          )}
        </Grid>
      )}
    </Container>
  );
};
