import axios from "axios";
import awsconfig from "../config/awsconfig";

const instance = axios.create({
  baseURL: awsconfig.REACT_APP_PUBLIC_API
});

instance.defaults.headers.access_token = sessionStorage.getItem("token");
instance.defaults.headers.client_id = awsconfig.REACT_APP_REST_CLIENT_ID;

export default instance;
