/* global BigInt */
import { createAction } from "redux-starter-kit";

export const locationsMapDataFetching = createAction(
  "LOCATIONS_MAP_DATA_FETCHING"
);
export const locationsTableDataFetching = createAction(
  "LOCATIONS_TABLE_DATA_FETCHING"
);
export const locationsDetailDataFetching = createAction(
  "LOCATIONS_DETAIL_DATA_FETCHING"
);

export const locationsTableData = createAction("LOCATIONS_TABLE_DATA");
export const locationsMapData = createAction("LOCATIONS_MAP_DATA");
export const locationsDetailData = createAction("LOCATIONS_DETAIL_DATA");

export const locationsTableError = createAction("LOCATIONS_MAP_ERROR");
export const locationsMapError = createAction("LOCATIONS_MAP_ERROR");
export const locationsDetailError = createAction("LOCATIONS_DETAIL_ERROR");

export const locationStates = createAction("LOCATION_STATES");
export const locationStatesFetching = createAction("LOCATION_STATES_FETCHING");
export const locationStatesError = createAction("LOCATION_STATES_ERROR");

export const locationIsSaving = createAction("LOCATION_IS_SAVING");

export const getLocationsMapData = () => (dispatch, getState) => {
  dispatch(locationsMapDataFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAll",
      query: "locations_map_data",
    },
  });
};
//Detail Get
export const getLocationsDetailData = (hash_key) => (dispatch, getState) => {
  const state = getState();
  if (state.locations.isFetchingLocationsDetailData) return;
  if (
    state.locations.locationsDetailData &&
    state.locations.locationsDetailData.location_hash_key === hash_key
  )
    return;
  dispatch(locationsDetailDataFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getLocationDetailData",
      data: { hash_key },
    },
  });
};

export const getLocationsTableData = () => (dispatch, getState) => {
  dispatch(locationsTableDataFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAll",
      query: "locations_table",
    },
  });
};

export const gotLocationsTableData = (message) => (dispatch, getState) => {
  const state = getState();
  const total = message.splited_org_size;
  const existingLocations = state.locations.locationsTableData || [];
  const newLocations = [...existingLocations, ...message.content];
  const loaded = total === newLocations.length;

  dispatch(
    locationsTableData({
      locationsTableData: newLocations,
      locationsTotal: total,
      fetching: !loaded,
    })
  );
};

export const gotLocationsDetailData = (message) => (dispatch, getState) => {
  dispatch(locationsDetailDataFetching(false));
  dispatch(locationsDetailData(message.content));
};

export const gotLocationsMapData = (message) => (dispatch, getState) => {
  const state = getState();
  const total = message.splited_org_size;
  const existingLocations = state.locations.locationsMapData || [];
  const newLocations = [...existingLocations, ...message.content];
  const loaded = total === newLocations.length;
  dispatch(
    locationsMapData({
      locationsMapData: newLocations,
      locationsTotal: total,
      fetching: !loaded,
    })
  );
};

export const getLocationStates = () => (dispatch, getState) => {
  // dispatch(locationsDetailDataFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllLocationStates",
    },
  });
};

export const gotLocationStates = (message) => (dispatch, getState) => {
  dispatch(locationStatesFetching(false));
  dispatch(
    locationStates({
      locationStates: message.content,
    })
  );
};

export const createLocation = (locationData) => (dispatch, getState) => {
  let location = { ...locationData };
  if (location.openingTimes) {
    // du legst dein leeres dict an
    const dayObjects = {};
    try {
      // du iterierst über die Einträge im Array
      location.openingTimes.forEach((openTimeEntry) => {
        // du nimmst das day array
        const days = openTimeEntry.days;
        days.forEach((day) => {
          day = day.trim();
          // du legst für jeden day eintrag einen Key an
          dayObjects[day] = dayObjects[day] || [];
          // du iterierst über jeden Eintrag in den Zeiten und fügst ihn dem Array unter dem jeweiligen tages key hinzu
          openTimeEntry["times"].forEach((time) => {
            dayObjects[day].push(time);
          });
        });
      });
      // ACHTUNG hier wird der alte Eintrag in der camelCase Variante gelöscht
      // delete location.openingTimes;
      // und hier das umgeformte array als Object in under_score variante eingefügt
      // wenn es beim server noch nicht möglich ist auch camelCase attribute zu lesen
      // musst du sie noch umformen !!!
      location.openingTimes = dayObjects;
      dispatch(locationIsSaving(true));
      dispatch({
        type: "SEND_MESSAGE",
        payload: {
          message: "createLocation",
          data: location,
        },
      });
    } catch (error) {
      console.log("An Error occured", error);
    }
  }
};
