import React from "react";
import PropTypes from "prop-types";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";

import { openIcon } from "./MarkerMap";
import { Loader } from "semantic-ui-react";
import { useDispatch } from 'react-redux'


const propTypes = {
  latLng: PropTypes.array
};
delete L.Icon.Default.prototype._getIconUrl;

const minZoom = 4;
const maxZoom = 20;
const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const SimpleMap = ({ latLng }) => {
  return latLng ? (
    <MapContainer
      center={latLng}
      zoom={15}
      style={{ height: "100%", borderRadius: "5px" }}
      zoomControl={false}
    >
      <TileLayer
        attribution={attribution}
        url={tileUrl}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />

      <Marker icon={openIcon()} position={latLng}/>

      {/* {stations &&
        stations.map(item => {
          return (
            <Marker
              icon={openIcon()}
              position={[item.gps_latitude, item.gps_longitude]}
              eventHandlers={{
                click: async e => {
                  dispatch(getSharingPointBoxes(item.sp_serial_number))
                  // await navigate(
                  //   `/sharingpoints/${item.sp_serial_number}/overview`
                  // );
                 
                }
              }}
            />
          );
        })} */}
    </MapContainer>
  ) : (
    <Loader active inline size="large" />
  );
};
SimpleMap.propTypes = propTypes;
export { SimpleMap };
