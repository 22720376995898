import { sortBy, get } from "lodash";
import { useMemo } from "react";

/**
 * hook to generate Options Object for semantic-ui Dropdown
 * @param {Array} data array of data
 * @param {Object}
 *    @param {String} key objectKey for sorting *required
 *    @param {String|undefined} value objectKey for sorting
 *    @param {String|undefined} text text property
 *
 *    @returns {Array} array of options as the dropdown expects
 */
function useDropdownOptions(
  data,
  { key, value = key, text = key, sortKey = value }
) {
  if (!key)
    throw new Error("A key must be defined to generate dropdown options");

  const options = useMemo(() => {
    if (!data || !key) return;
    const sorted = sortBy(data, [(o) => get(o, sortKey).toLowerCase()]);
    return sorted.map((item) => ({
      key: get(item, key),
      value: get(item, value),
      text: get(item, text),
    }));
  }, [data, key]);
  return options;
}
export { useDropdownOptions };
