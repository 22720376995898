import React from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Message, Button, Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { confirmRegistration } from "./../../../../store/actions"; // store/actions";
import { RHFInputRow } from "./../../../helpers/forms/RFHInputRow";
import { RFHErrorMessages } from "./../../../helpers/forms/RFHErrorMessages";
import "./../LoginForm/index.scss";
import { Link } from "@reach/router";

const validationSchema = yup.object().shape({
  username: yup.string().required("Please enter your username."),
  verification: yup
    .string()
    .required("Please enter the verification code that we sent to your email."),
});

const inputs = [
  { name: "username", type: "text" },
  { name: "verification", type: "text" },
];

const ConfirmationForm = (props) => {
  const dispatch = useDispatch();
  const { error, loading, message } = useSelector(
    (state) => state.registration
  );

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    dispatch(confirmRegistration(data.username, data.verification));
  };

  return (
    <div className="form-wrapper form-wrapper--confirmation">
      <Message
        color="blue"
        icon="info"
        content="Finish your registration by entering your username and the verification code we sent to your email account."
      />
      <RFHErrorMessages errors={errors} title=" " />
      {error && (
        <Message error header="Verification failed" content={message} />
      )}
      <form
        className="ui form form--confirmation"
        onSubmit={handleSubmit(onSubmit)}
      >
        {inputs.map((inp) => {
          return (
            <div className="row-wrapper" key={inp.name}>
              <RHFInputRow
                register={register}
                label={inp.name}
                name={inp.name}
                type={inp.type}
                error={errors[inp.name]}
              />
            </div>
          );
        })}

        <Grid>
          <Grid.Column width="8" textAlign="left">
            <Link to="/">login</Link>
          </Grid.Column>
          <Grid.Column width="8" textAlign="right">
            <Button type="submit" disabled={loading}>
              Submit
            </Button>
          </Grid.Column>
        </Grid>
      </form>
    </div>
  );
};

ConfirmationForm.propTypes = {
  username: PropTypes.string,
};

export default ConfirmationForm;
