import { createAction } from "redux-starter-kit";

export const manufacturerFetching = createAction("MANUFACTURERS_FETCHING");
export const manufacturers = createAction("MANUFACTURERS");
export const manufacturersError = createAction("MANUFACTURERS_ERROR");
export const manufacturerIsSaving = createAction("MANUFACTURERS_IS_SAVING");
//export const addManufacturer = createAction("ADD_MANUFACTURERS");

export const getManufacturers = () => (dispatch, getState) => {
  dispatch(manufacturerFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllManufacturersFull",
    },
  });
};

export const gotManufacturers = (message) => (dispatch, getState) => {
  console.log("action got manufacturer", message);
  dispatch(
    manufacturers({
      manufacturers: message.content,
      manufacturersTotal: message.content.length,
    })
  );
};
export const addManufacturer = (manufacturer) => (dispatch, getState) => {
  const manufacturerState = getState().manufacturers;
  const exisitingManufacturers = manufacturerState.manufacturers;
  if (!exisitingManufacturers || !exisitingManufacturers.length) {
    dispatch(getManufacturers());
  } else {
    const newManufacturers = [...exisitingManufacturers, manufacturer];
    dispatch(
      manufacturers({
        manufacturers: newManufacturers,
        manufacturersTotal: newManufacturers.length,
      })
    );
  }
};
export const saveManufacturer = (manufacturerProps) => (dispatch, getState) => {
  console.log("action save manufacturer", manufacturerProps);
  dispatch(manufacturerIsSaving(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createManufacturer",
      data: manufacturerProps,
    },
  });
};

export const savedManufacturer = (message) => (dispatch, getState) => {
  const state = getState();
  dispatch(manufacturerIsSaving(false));
  console.log("action: saved manufacturer", message, state);
  //dispatch(addManufacturer(message.content));
};
