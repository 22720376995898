import { createReducer } from "redux-starter-kit";
import { setAppSettings, setSideBarState } from "../actions";

const initialState = {
  language: localStorage.getItem("i18n-language")? localStorage.getItem("i18n-language") : "de",
  sidebarIsCollapsed: true,
  perPage: 100,
};

const appSettingsReducer = createReducer(initialState, {
  [setAppSettings]: (state, action) => {
    return action.payload;
  },
  [setSideBarState]: (state, action) => {
    console.log("ReducersetSideBarState", action.payload);
    return {
      ...state,
      sidebarIsCollapsed: action.payload,
    };
  },
});

export default appSettingsReducer;
