import React from "react";
import {
  Header,
  Container,
  Button,
  Icon,
  Grid,
  Segment,
  GridColumn
} from "semantic-ui-react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log("didcatch error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          style={{
            color: "#444",
            width: "450px",
            height: "100%",
            verticalAlign: "middle"
          }}
          textAlign="center"
        >
          <Segment textAlign="center" style={{ marginTop: 200 }}>
            <Grid columns="1">
              <GridColumn style={{ padding: "30px 0 30px 0" }}>
                <Icon name="exclamation triangle" size="massive" color="grey" />
              </GridColumn>
              <GridColumn>
                <Header>Something went wrong!</Header>
              </GridColumn>
              <GridColumn>
                <p style={{ color: "grey", paddinTop: 0 }}>
                  Our software team is working on it. <br />
                  Please try again later.
                </p>
              </GridColumn>
              <GridColumn>
                <Button
                  fluid
                  onClick={() => (window.location = "/")}
                  primary
                  size="large"
                >
                  <Icon name="home" /> Home
                </Button>
              </GridColumn>
              <GridColumn>
                <Button
                  fluid
                  onClick={() => window.location.reload()}
                  basic
                  size="large"
                >
                  <Icon name="redo" /> Reload
                </Button>
              </GridColumn>
            </Grid>
          </Segment>
        </Container>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
