import React, { useEffect, useState } from "react";
import { Grid, Container, Header, Loader } from "semantic-ui-react";
import { Router } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";

import ProvidersTable from "../components/ProvidersTable";

import CreateProviderScreen from "./providers/create";

import { getProviders } from "../store/actions";


const ProvidersScreen = props => {
  // from redux
  const dispatch = useDispatch();
  // get needed data from redux-store
  // set when the websocket connection isopen
  const socket = useSelector(state => state.session.socket);
  // not used yet
  const providers = useSelector(state => state.providers.providers);
  const providersTotal = useSelector(state => state.providers.total);
  const providersFetching = useSelector(state => state.providers.fetching);


  useEffect(() => {
    if (socket) {
      if (!providers || providers.length === 0) {
        dispatch(getProviders());
      }
    }
  }, [socket, providers, dispatch]);

  return (
    <Container
      fluid
      data-testid="providers-screen"
      className="screen--providers screen"
      style={{ marginTop: "100px", marginBottom: "100px", width: "50%" }}
    >
      <Router>
        <CreateProviderScreen path="/create" />
      </Router>

      <Grid>
        <Grid.Column width={16}>
          <ProvidersTable
            lang={props.lang}
            providerData={providers}
            providersTotal={providersTotal}
            providersFetching={providersFetching}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default ProvidersScreen;
