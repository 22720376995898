import { createAction } from "redux-starter-kit";

export const regionsFetching = createAction("REGIONS_FETCHING");
export const regions = createAction("REGIONS");
export const regionsError = createAction("REGIONS_ERROR");
export const isSavingRegion = createAction("SAVE_REGION");
export const saveError = createAction("SAVE_ERROR");
export const saveSuccessMessage = createAction("SAVE_SUCCESS_MESSAGE");

export const getRegions = () => (dispatch, getState) => {
  dispatch(regionsFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllRegions",
    },
  });
};

export const gotRegions = (message) => (dispatch, getState) => {
  dispatch(regionsFetching(false));
  dispatch(
    regions({
      regions: message.content,
      total: message.count || message.splited_org_size,
    })
  );
};

export const createRegion = (data) => (dispatch, getState) => {
  dispatch(isSavingRegion(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createRegion",
      data,
    },
  });
};

/** uses an ack message */
export const savedRegion = (message) => (dispatch, getState) => {
  dispatch(isSavingRegion(false));
  dispatch(saveSuccessMessage(message["display_message"]));
  let existingRegions = getState().regions.regions;
  let newRegion = message.content;
  dispatch(
    regions({
      regions: [...existingRegions, newRegion],
      total: existingRegions.length + 1,
    })
  );
};
