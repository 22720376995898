/** @format */

import { createReducer } from "redux-starter-kit";
import {
  techCVFetching,
  techCVs,
  techCVsByBatterySN,
  techCVsByBatterySNFetching,
  techCVsByBatterySNError,
  lastTechCV,
} from "../actions";

const initialState = {
  techCVs: [],
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0,
  lastTechCV: false,
  techCVsByBatterySN: null,
  techCVsByBatterySNFetching: null,
  techCVsByBatterySNError: null,
};

const techCVsReducer = createReducer(initialState, {
  [techCVFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload,
    };
  },
  [techCVs]: (state, action) => {
    return {
      ...state,
      techCVs: action.payload.techCVs,
      total: action.payload.techCVsTotal,
      fetching: action.payload.fetching,
    };
  },
  [techCVsByBatterySN]: (state, action) => {
    let sorted = action.payload.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    return {
      ...state,
      techCVsByBatterySN: sorted,
    };
  },
  [techCVsByBatterySNFetching]: (state, action) => {
    return {
      ...state,
      techCVDetailFetching: action.payload,
    };
  },

  [techCVsByBatterySNError]: (state, action) => {
    return {
      ...state,
      techCVsByBatterySNError: action.payload,
    };
  },
  [lastTechCV]: (state, action) => {
    return {
      ...state,
      lastTechCV: action.payload,
    };
  },
});
export default techCVsReducer;
