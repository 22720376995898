import { createAction } from "redux-starter-kit";

export const ownerFetching = createAction("OWNERS_FETCHING");
export const owners = createAction("OWNERS");
export const ownersError = createAction("OWNERS_ERROR");
export const ownerIsSaving = createAction("OWNERS_IS_SAVING");
//export const addOwner = createAction("ADD_OWNERS");

export const getOwners = () => (dispatch, getState) => {
  dispatch(ownerFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllOwnersFull",
    },
  });
};

export const gotOwners = (message) => (dispatch, getState) => {
  dispatch(
    owners({
      owners: message.content,
      total: message.content.length, //message.splited_org_size
    })
  );
};
export const addOwner = (owner) => (dispatch, getState) => {
  const ownerState = getState().owners;
  const existingOwners = ownerState.owners;
  if (!existingOwners || !existingOwners) {
    dispatch(getOwners());
  } else {
    let newOwners = [...existingOwners, owner];
    dispatch(
      owners({
        owners: newOwners,
        total: newOwners.length,
      })
    );
  }
};

export const saveOwner = (ownerProps) => (dispatch, getState) => {
  dispatch(ownerIsSaving(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createOwner",
      data: ownerProps,
    },
  });
};

export const savedOwner = (message) => (dispatch /*, getState*/) => {
  console.log("action: saved owner", message);
  dispatch(ownerIsSaving(false));
  //dispatch(addOwner(message.content));
};
