/** @format */

import { createAction } from "redux-starter-kit";
import axios from "axios";
import awsconfig from "../../config/awsconfig";

/** sync actions */
export const techCVFetching = createAction("TECHCVS_FETCHING");
export const techCVs = createAction("TECHCVS");
export const techCVsError = createAction("TECHCVS_ERROR");

// used in battery detail/show screen
export const techCVsByBatterySNFetching = createAction(
  "TECHCVS_BY_BATTERY_SN_FETCHING"
);
export const techCVsByBatterySN = createAction("TECHCVS_BY_BATTERY_SN");
export const techCVsByBatterySNError = createAction(
  "TECHCVS_BY_BATTERY_SN_ERROR"
);

export const lastTechCV = createAction("LAST_TECHCV");

const axiosInstance = () => {
  let client_id = awsconfig.REACT_APP_REST_CLIENT_ID;
    let apiUrl = awsconfig.REACT_APP_PUBLIC_API;
    

    // console.log("access_token", accessToken)
    const instance = axios.create({
      baseURL: apiUrl, // Replace with your API base URL
      timeout: 60000, // Adjust the timeout as needed
      headers: {
        access_token: localStorage.getItem(`swobbee.portal.${client_id}.token`),
        client_id: client_id,
      },
    });
    return instance
}


export const getLastTechcv = newData => async dispatch => {
  let params = {};
  params["serial_number"] = newData.serial_number;
  params["battery_type"] = newData.battery_type;
  
  const { data } = await axiosInstance().get("/last_techcv", {
    params,
  });
  if (data.status_code === 200) {
    let date = new Date(parseInt(data.data.timestamp));
    data.data["timestamp_readable"] = date.toLocaleString();
    dispatch(lastTechCV(data.data));
  }
  if (data.status_code === 204) {
    data.data = { empty: true };
    dispatch(lastTechCV(data.data));
  }
};

export const gotTechcvUpdate = message => (dispatch, getState) => {
  const updateTechcv = message.content;
  const state = getState();
  const existingTechcv = state.techCVs.lastTechCV;
  if (
    (message.statusCode =
      200 &&
      existingTechcv.serial_number === updateTechcv.serial_number &&
      existingTechcv.timestamp <= updateTechcv.timestamp)
  ) {
    let date = new Date(parseInt(updateTechcv.timestamp));
    updateTechcv["timestamp_readable"] = date.toLocaleString();
    updateTechcv["creator"] = updateTechcv.creator.reading_sp;
    console.log("updateTechcv", updateTechcv);
    dispatch(lastTechCV(updateTechcv));
  }
};

/** send websocket message to get all techcvs */
export const getTechCVs = () => (dispatch, getState) => {
  console.log("fetch techcvs action");
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAll",
      query: "techcv_table",
    },
  });
  dispatch(techCVFetching(true));
};

/** receive websocket message to get all techcvs called by middleware*/
export const gotTechCVs = message => (dispatch, getState) => {
  const state = getState();
  const total = message.splited_org_size;
  const existing = state.techCVs.techCVs;
  const newTechCVs = [...existing, ...message.content];
  const loaded = total - 100 <= newTechCVs.length;
  console.log("action got techcvs", message, state.techCVs, loaded);
  dispatch(
    techCVs({
      techCVs: newTechCVs,
      techCVsTotal: total,
      fetching: !loaded,
    })
  );
};
/** since techcvs are immutable changedTechCV are simply addded */
export const techCVChanged = message => (dispatch, getState) => {
  const state = getState();
  const techCV = message.content;
  const techCVs = state.techCVs.techCVs;
  const total = state.techCVs.total;
  dispatch(
    techCVs({
      techCVs: [...techCVs, techCV],
      techCVsTotal: total + 1,
    })
  );
};

export const getTechCVsByBatterySN =
  ({ batteryId }) =>
  (dispatch, getState) => {
    if (!batteryId) return;
    dispatch(techCVsByBatterySNFetching(true));
    dispatch({
      type: "SEND_MESSAGE",
      payload: {
        message: "getTechCVFullByBatterySN",
        data: {
          battery_sn: batteryId,
        },
      },
    });
  };

export const gotTechCVsByBatterySN = message => (dispatch, getState) => {
  dispatch(techCVsByBatterySNFetching(false));
  dispatch(techCVsByBatterySN(message.content));
};

/** if no battery detail is open
  if (!state.batteryDataFull) {
    return { ...state, techCVs: newTechCVs };
  } else {
    let battery = state.batteryDataFull.values()[0];
    let serialNumber = battery.serialNumber;
    if (techCV.battery_sn === serialNumber) {
      let newTechCVDataFull = new Map([
        [techCV.hash_id, techCV],
        ...state.techCVDataFull
      ]);
      return {
        ...state,
        techCVList: newTechCVList,
        techCVDataFull: newTechCVDataFull
      };
    } else {
      return { ...state, techCVList: newTechCVList };
    }
  }
  */

export const updateTechCVTable = message => (dispatch, getState) => {
  let updatedTechCV = message.content;
  const state = getState();
  const existingTechCV = state.techCVs.techCVsByBatterySN;
  if (!existingTechCV) return;
  let upgradeTechCVs;
  let found = existingTechCV.find(
    techCV => techCV.timestamp === updatedTechCV.timestamp
  );
  if (found) {
    upgradeTechCVs = existingTechCV.map(techCV => {
      return techCV.timestamp === updatedTechCV.timestamp
        ? Object.assign({}, techCV, updatedTechCV)
        : techCV;
    });
  } else {
    return console.log("found false");
  }
  console.log("upgradeTechCVs", upgradeTechCVs);
  dispatch(techCVsByBatterySN(upgradeTechCVs));
};
