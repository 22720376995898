import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Container,
  Grid,
  Dropdown,
  Header,
  Loader,
  Segment,
  Modal,
  ModalHeader,
  ModalContent
} from "semantic-ui-react";
import * as yup from "yup";
import { Link, navigate } from "@reach/router";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDropdownOptions } from "../../hooks/use-dropdown-options";
import { RFHErrorMessages } from "../helpers/forms/RFHErrorMessages";

const validationSchema = yup.object().shape({
  company: yup.string().required("owners.company.validationMessage"),
  contact: yup.string().required("owners.contact.validationMessage")
});
const propTypes = {
  companies: PropTypes.array,
  contacts: PropTypes.array,
  submitOwner: PropTypes.func,
  isSaving: PropTypes.bool
};

const OwnerForm = ({
  companies,
  contacts,
  submitOwner,
  isSaving,
  owner = {}
}) => {
  /** generate dropdown options for company */
  const { t } = useTranslation();

  const companyOptions = useDropdownOptions(companies, {
    key: "hash_key",
    text: "name",
    value: "hash_key",
    sortKey: "name"
  });

  /** generate dropdown options for contact */
  const contactOptions = useMemo(() => {
    if (!contacts) return [];
    const sorted = sortBy(contacts, ["family_name", "first_name"]);
    return sorted.map(contact => {
      const { hash_key, first_name, family_name } = contact;
      return {
        key: hash_key,
        value: hash_key,
        text: `${family_name}, ${first_name}`
      };
    });
  }, [contacts]);

  const { handleSubmit, errors, control } = useForm({
    defaultValues: owner,
    resolver: yupResolver(validationSchema)
  });
  return (
    <Container
      style={{
        marginTop: 30,
        marginBottom: 20,
        textDecoration: "none"
      }}
      fluid
    >
      <Modal open={true} closeIcon onClose={() => navigate("/owners")}>
        <ModalHeader>Create Owner</ModalHeader>
        <ModalContent>
          <RFHErrorMessages title={t("errors")} errors={errors} />

          <Form onSubmit={handleSubmit(submitOwner)}>
            {
              <div>
                <Grid columns="2">
                  <Grid.Column>
                    <Header as="h4">{t("owners.company.label")}:</Header>
                  </Grid.Column>
                </Grid>
                <Controller
                  name="company"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <Dropdown
                        clearable
                        style={{ marginTop: 10, marginBottom: 10 }}
                        placeholder={t("owners.company.label")}
                        fluid
                        search
                        selection
                        options={companyOptions}
                        defaultValue={value}
                        onChange={(_, { value }) => {
                          onChange(value);
                        }}
                      />
                    );
                  }}
                />
                <Grid columns="2">
                  <Grid.Column>
                    <Header as="h5">{t("owners.contact.label")}</Header>
                  </Grid.Column>
                  {errors && errors.contacts && (
                    <Grid.Column className="error error--message">
                      {errors.contacts}
                    </Grid.Column>
                  )}
                </Grid>
                <Controller
                  name="contact"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <Dropdown
                        clearable
                        style={{ marginTop: 10 }}
                        fluid
                        search
                        selection
                        placeholder={t("owners.choose")}
                        options={contactOptions}
                        defaultValue={value}
                        onChange={(_, { value }) => {
                          onChange(value);
                        }}
                      />
                    );
                  }}
                />
              </div>
            }
            <Grid style={{ marginTop: 10 }}>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  {isSaving && (
                    <Loader active inline>
                      is saving
                    </Loader>
                  )}
                  <Button
                    as={Link}
                    to="/owners"
                    basic
                    content={t("button.cancel")}
                    icon="close"
                  />
                  <Button
                    loading={isSaving}
                    type="submit"
                    content={t("button.save")}
                    icon="save"
                    color="green"
                    disabled={Object.keys(errors).length > 0 || isSaving}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </ModalContent>
      </Modal>
    </Container>
  );
};

OwnerForm.propTypes = propTypes;

export default OwnerForm;
