/** @format */

import React, { useEffect } from "react";
import { Container, GridColumn, Breadcrumb, Segment } from "semantic-ui-react";
import { navigate } from "@reach/router";
import ErrorsHistoryTable from "./ErrorsHistoryTable";

const ErrorsHistory = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container
      className="statistic-container"
      style={{
        marginTop: "100px",
        marginBottom: "50px",
      }}
    >
      <GridColumn width="16" style={{ marginTop: 60 }}>
        <Breadcrumb>
          <Breadcrumb.Section link onClick={() => navigate("/")}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section style={{ color: "#444" }}>
            Errors
          </Breadcrumb.Section>
        </Breadcrumb>
      </GridColumn>
      <GridColumn width="16">
        <Segment style={{ height: "100% !important" }}>
          <ErrorsHistoryTable />
        </Segment>
      </GridColumn>
    </Container>
  );
};

export default ErrorsHistory;
