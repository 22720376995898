import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { AxiosContext } from "../context/AxiosContext";
export const useAxiosService = ({ backendRoute }) => {
  // loading state
  const [isLoading, setIsLoading] = useState();
  // fetched data response
  const [fetchedData, setFetchedData] = useState();
  // count of all item in table
  const [sum, setSum] = useState();
  // response page: increment to get next page
  const [page, setPage] = useState();
  // response limit
  const [limit, setLimit] = useState();

  const [exportedData, setExportedData] = useState();

  const [sortParams, setSortParams] = useState({
    order: "DESC",
    sorted_by: "usage",
  });

  const [pagination, setPagination] = useState(0);

  const { axiosInstance } = useContext(AxiosContext);

  const sortColumn = (key) => {
    if (sortParams && key === sortParams.sorted_by) {
      sortParams.order === "ASC"
        ? setSortParams({ ...sortParams, order: "DESC", sorted_by: key })
        : setSortParams({ ...sortParams, order: "ASC", sorted_by: key });
    } else {
      setSortParams({ ...sortParams, order: "DESC", sorted_by: key });
    }
  };

  const fetchData = async (loadMore, searchParams, is_export) => {
    try {
      setIsLoading(true);

      !is_export && setFetchedData(null);
      if (loadMore) {
        searchParams["page"] = +pagination + 1;
      }
      searchParams["export"] = is_export;
      const { data } = await axiosInstance.get(backendRoute, {
        params: searchParams,
      });

      if (loadMore) setPagination(+pagination + 1);
      setSum(data.sum);
      setPage(data.page);
      setLimit(data.limit);
      if (is_export) {
        setExportedData(data.data);
      } else {
        setFetchedData(loadMore ? [...fetchedData, ...data.data] : data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return {
    fetchData,
    setFetchedData,
    isLoading,
    fetchedData,
    sum,
    limit,
    page,
    sortParams,
    setSortParams,
    sortColumn,
    pagination,
    setPagination,
    exportedData,
  };
};
