import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Grid, Container } from "semantic-ui-react";

import Table from "../helpers/tables/FilterTable";

import { useTranslation } from "react-i18next";
import { CompanyCells, ContactCells } from "../helpers/tables/columns";

const CompaniesTable = ({
  companyData,
  setFilterCount,
  perPage = 10,
  setSettings,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      CompanyCells({
        type: "company",
        addressLabel: t("providers.company_address.label"),
        headerLabel: t("mainsidebar.companies.label"),
        companyLabel: t("mainsidebar.companies.label"),
      }),
      ContactCells({ label: t("providers.contact_person.label") }),
    ],
    [t]
  );
  const data = useMemo(() => {
    return companyData ? companyData : [];
  }, [companyData]);

  return (
    <Container fluid data-testid="page-wrapper" className="companies-table">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            {data && (
              <Table
                columns={columns}
                data={data}
                striped={true}
                fixed
                setFilterCount={setFilterCount}
                initialState={{
                  sortBy: [{ id: "name", desc: false }],
                  pageSize: perPage,
                }}
                setSettings={setSettings}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
CompaniesTable.propTypes = {
  companyData: PropTypes.array,
  setFilterCount: PropTypes.func,
  setSettings: PropTypes.func,
};

export default CompaniesTable;
