/** @format */

import { createAction } from "redux-starter-kit";

export const contactFetching = createAction("CONTACT_FETCHING");
export const contacts = createAction("CONTACTS");
export const contactsError = createAction("CONTACTS_ERROR");
export const contactIsSaving = createAction("CONTACT_IS_SAVING");

export const contactByHashKey = createAction("CONTACT_BY_HASH_KEY");
export const contactByHashKeyIsFetching = createAction(
  "CONTACT_BY_HASH_KEY_IS_FETCHING"
);

/** fetch contacts through websocket middleware */
export const getContacts = () => dispatch => {
  dispatch(contactFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "getAllContactsFull",
    },
  });
};

/** receive contacts through websocket middleware */
export const gotContacts = message => (dispatch /*, getState*/) => {
  dispatch(contactFetching(false));
  dispatch(
    contacts({
      contacts: message.content,
      contactsTotal: message.content.length, //message.count
    })
  );
};

export const getContactByHashKey = hash_key => dispatch => {
  dispatch(contactByHashKeyIsFetching(true));
  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "standard",
      request: "getContactByHashKey",
      data: { hash_key: hash_key },
    },
  });
};

export const gotContactByHashKey = message => dispatch => {
  dispatch(contactByHashKeyIsFetching(false));
  dispatch(contactByHashKey(message.content));
};

export const addContact = contact => (dispatch, getState) => {
  const contactsState = getState().contacts;
  const existingContacts = contactsState.owners;
  if (!existingContacts || existingContacts.length < 1) {
    dispatch(getContacts());
  } else {
    let newContacts = [...existingContacts, contact];
    dispatch(
      contacts({
        contacts: newContacts,
        contactsTotal: newContacts.length,
      })
    );
  }
};

/** not used no websocket listener */
export const saveContact = contactProps => (dispatch /*, getState*/) => {
  dispatch(contactIsSaving(true));
  const data = {
    title: contactProps.title,
    first_name: contactProps.first_name,
    family_name: contactProps.family_name,
    email: contactProps.email,
    phone_number: contactProps.phone_number,
    address: {
      street: contactProps.street,
      street_number: contactProps.street_number,
      zipcode: contactProps.zipcode,
      city: contactProps.city,
      additional: contactProps.street_additional,
      district: contactProps.district || "",
      state: contactProps.state || "",
      country: contactProps.country,
    },
  };

  dispatch({
    type: "SEND_MESSAGE",
    payload: {
      message: "createContact",
      data: data,
    },
  });
};

/** not used no websocket listener */
export const savedContact = message => (dispatch /*, getState*/) => {
  dispatch(contactIsSaving(false));
  //dispatch(addContact(message.content));
};
