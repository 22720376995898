import { Link, navigate } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { parse } from "query-string";

import {
  Breadcrumb,
  Button,
  Container,
  Dropdown,
  Form,
  FormField,
  FormGroup,
  Grid,
  GridColumn,
  Input,
  Label,
  Loader,
  Modal,
  ModalContent,
  ModalHeader,
  Popup,
  Segment,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { upperFirst } from "lodash";
import {
  BatteryTrackingSettingButton,
  BatteryTypeDropdownSearch,
  ExportDataAsCsv,
  GroupsDropdownSearch,
  SharingPointDropdownSearch,
  UsersDropdownSearch,
} from "../../components/UtilComponents";
import { useLocation } from "@reach/router";
import { useFormatTimestamp } from "../../hooks/use-format-timestamp";
import { AxiosContext } from "../../context/AxiosContext";

const stateColor = {
  OUT: "red",
  CHARGING: "blue",
  READY: "green",
};

const BatteryStateOptions = [
  {
    value: "READY",
    text: (
      <Label
        content={"READY"}
        color={stateColor["READY"]}
        style={{ width: 80 }}
        size="mini"
        circular
      />
    ),
    key: "READY",
  },
  {
    value: "CHARGING",
    text: (
      <Label
        content={"CHARGING"}
        color={stateColor["CHARGING"]}
        style={{ width: 80 }}
        size="mini"
        circular
      />
    ),
    key: "CHARGING",
  },
  {
    value: "OUT",
    text: (
      <Label
        content={"OUT"}
        color={stateColor["OUT"]}
        style={{ width: 80 }}
        size="mini"
        circular
      />
    ),
    key: "OUT",
  },
  {
    value: "ERROR",
    text: (
      <Label
        content={"ERROR"}
        color={stateColor["ERROR"]}
        style={{ width: 80 }}
        size="mini"
        circular
      />
    ),
    key: "ERROR",
  },
];

export default function BatteryCharging() {
  const [state, setstate] = useState({
    data: null,
    direction: "descending",
    count: null,
    pages: 0,
    column: null,
    page: 0,
  });

  const { t } = useTranslation("descriptions");
  const { timeSpans } = useFormatTimestamp();
  const [batteryChargingStatistic, setBatteryChargingStatistic] = useState();
  const [selectedUsername, setSelectedUsername] = useState();
  const [selectedUserGroupName, setSelectedUserGroupName] = useState();

  const [battery_id, setBatteryId] = useState();
  const [battery_state, setBatteryStateSearch] = useState();
  const [battery_type, setSelectedBatteryType] = useState();
  const [sp_serial_number, setSelectedStation] = useState();
  const [swap_order_id, setSwapOrderId] = useState();
  const [module_number, setModuleNumber] = useState();
  const [battery_in_soc, setSocIn] = useState();
  const [soc_value, setSoc] = useState();
  const [slot, setSlotNumber] = useState();
  const [limit, setLimit] = useState();
  const [isLoading, setIsLoading] = useState();
  const [exportData, setExportData] = useState();

  const [isLoadingLoadMore, setIsLoadingLoadMore] = useState();
  const [modalShown, setModalShown] = useState();

  const { axiosInstance } = useContext(AxiosContext);

  const fetchBatteryTracking = async (
    column,
    direction,
    battery_id_param,
    swap_order_id_param
  ) => {
    setIsLoading(true);
    setstate({ ...state, data: null, count: null });
    fetchBatteryTrackingStatistic(battery_id, swap_order_id);

    const params = {
      battery_id: battery_id ? battery_id : battery_id_param,
      swap_order_id: swap_order_id ? swap_order_id : swap_order_id_param,
      battery_state,
      created_by: selectedUsername,
      user_group_name: selectedUserGroupName,
      battery_type: battery_type ? battery_type.replace(".", "@") : null,
      sp_serial_number,
      slot,
      module_number,
      battery_in_soc,
      soc_value,
      limit,
    };
    if (!column) {
      const { data } = await axiosInstance.get("/batteries/tracking", {
        params,
      });
      setstate({
        ...state,
        data: data.data,
        count: data.count,
        pages: data.pages,
      });
      setIsLoading(false);
      setModalShown(false);
      return;
    }

    if (state.column === column) {
      const { data } = await axiosInstance.get("/batteries/tracking", {
        params: {
          ...params,
          sorted_by: column ? column : state.column,
          order: state.direction === "ascending" ? "DESC" : "ASC",
        },
      });
      setstate({
        ...state,
        data: data.data,
        count: data.count,
        pages: data.pages,
        direction: state.direction === "ascending" ? "descending" : "ascending",
      });
      setIsLoading(false);
      setModalShown(false);
      return;
    }
    const { data } = await axiosInstance.get("/batteries/tracking", {
      params: {
        ...params,
        sorted_by: column,
        order: state.direction === "ascending" ? "DESC" : "ASC",
      },
    });
    setstate({
      ...state,
      column: column ? column : state.column,
      data: data.data,
      count: data.count,
      pages: data.pages,
      direction: "ascending",
    });

    setIsLoading(false);
    setModalShown(false);
  };

  const resetSearchField = () => {
    setSelectedUsername(null);
    setSelectedUserGroupName(null);
    setBatteryId(null);
    setBatteryStateSearch(null);
    setSelectedBatteryType(null);
    setSelectedStation(null);
    setSwapOrderId(null);
    setModuleNumber(null);
    setSocIn(null);
    setSoc(null);
    setSlotNumber(null);
    setLimit(null);
    navigate("/battery-tracking");
  };

  const fetchBatteryTrackingStatistic = async (
    battery_id_param,
    swap_order_id_param
  ) => {
    const { data } = await axiosInstance.get("/batteries/statistic", {
      params: {
        battery_id: battery_id ? battery_id : battery_id_param,
        battery_state,
        created_by: selectedUsername,
        user_group_name: selectedUserGroupName,
        battery_type: battery_type ? battery_type.replace(".", "@") : null,
        sp_serial_number,
        swap_order_id: swap_order_id ? swap_order_id : swap_order_id_param,
        slot,
        module_number,
        battery_in_soc,
        soc_value,
      },
    });

    setBatteryChargingStatistic(data.data);
  };

  const loadMore = async () => {
    setIsLoadingLoadMore(true);
    const { data } = await axiosInstance.get("/batteries/tracking", {
      params: {
        battery_id,
        battery_state,
        created_by: selectedUsername,
        user_group_name: selectedUserGroupName,
        battery_type: battery_type && battery_type.toLowerCase(),
        sp_serial_number,
        swap_order_id,
        slot,
        module_number,
        battery_in_soc,
        soc_value,
        limit,
        page: state.page + 1,
      },
    });
    setstate({
      ...state,
      data: [...state.data, ...data.data],
      page: data.page,
    });
    setIsLoadingLoadMore(false);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.search) return;

    fetchBatteryTrackingStatistic();
    fetchBatteryTracking();
    return () => {};
  }, [location.search]);

  useEffect(() => {
    if (!location.search) return;
    const { battery_id, swap_order_id } = parse(location.search); // => {init: "true"}
    setBatteryId(battery_id);
    setSwapOrderId(swap_order_id);
    fetchBatteryTrackingStatistic(battery_id, swap_order_id);
    fetchBatteryTracking(null, null, battery_id, swap_order_id);
    return () => {};
  }, [location.search]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchBatteryTracking();
    }
  };

  function timeDifference(date1, date2 = 0) {
    var difference = new Date(+date1).getTime() - new Date(+date2).getTime();

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    var secondsDifference = Math.floor(difference / 1000);

    return `${hoursDifference}h${minutesDifference}m${secondsDifference}s`;
  }

  const sortColumn = async (column) => {
    fetchBatteryTracking(
      column,
      state.direction === "ascending" ? "descending" : "ascending"
    );
  };

  const fetchExportData = async () => {
    const { data } = await axiosInstance.get("/batteries/tracking", {
      params: {
        battery_id,
        battery_state,
        created_by: selectedUsername,
        user_group_name: selectedUserGroupName,
        battery_type: battery_type && battery_type.toLowerCase(),
        sp_serial_number,
        swap_order_id,
        slot,
        module_number,
        battery_in_soc,
        soc_value,
        export: true,
      },
    });
    setExportData(data.data);
  };

  const batteryTypeMapping = {
    "okai b": "Okai B",
    "okai a": "Okai A",
    greenpack: "Greenpack",
    "aes ebike akku 2.0 48v": "AES eBike Akku 2.0 48V",
    torrot: "MD-BJ1",
  };

  return (
    <Container style={{ marginTop: 70, width: "100%", padding: 14 }}>
      <Grid columns={1}>
        <GridColumn
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Breadcrumb>
            <Breadcrumb.Section link onClick={() => navigate("/")}>
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section style={{ color: "#444" }}>
              Battery-Tracking
            </Breadcrumb.Section>
          </Breadcrumb>
        </GridColumn>
        <GridColumn style={{ minHeight: 800 }}>
          <Segment style={{ minHeight: 800 }}>
            <Grid columns={1}>
              <GridColumn
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <ExportDataAsCsv
                    fetchData={() => fetchExportData()}
                    data={exportData}
                    filename="battery_tracking_csv"
                  />
                  <AdvancedSearch
                    {...{
                      resetSearchField,
                      battery_id,
                      battery_state,
                      battery_type,
                      sp_serial_number,
                      swap_order_id,
                      module_number,
                      battery_in_soc,
                      soc_value,
                      slot,
                      limit,
                      setIsLoading,
                      isLoading,
                      setSelectedStation,
                      setSelectedBatteryType,
                      selectedUsername,
                      setSelectedUsername,
                      setBatteryId,
                      selectedUserGroupName,
                      setSelectedUserGroupName,
                      setBatteryStateSearch,
                      setSwapOrderId,
                      handleKeyDown,
                      fetchBatteryTracking,
                      setModuleNumber,
                      setSlotNumber,
                      setSoc,
                      setSocIn,
                      modalShown,
                      setModalShown,
                      setLimit,
                    }}
                  />
                  <AverageTimeModal
                    {...{ batteryChargingStatistic, timeDifference }}
                  />
                  <Button
                    basic
                    size="small"
                    icon="close"
                    content="Reset search"
                    disabled={
                      !(
                        selectedUsername ||
                        selectedUserGroupName ||
                        battery_id ||
                        battery_state ||
                        battery_type ||
                        sp_serial_number ||
                        swap_order_id ||
                        module_number ||
                        battery_in_soc ||
                        soc_value ||
                        slot
                      )
                    }
                    onClick={() => resetSearchField()}
                  />
                </div>
              </GridColumn>

              <GridColumn
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span style={{ color: "#444", fontSize: 18 }}>
                  {state.data ? `${state.data.length} / ${state.count}` : ""}{" "}
                  Battery-Trackings
                </span>
              </GridColumn>

              <GridColumn style={{ paddingTop: 0 }} width="12">
                <span style={{ color: "#687078" }}>
                  {t("battery_tracking_view")}
                </span>
              </GridColumn>
              <GridColumn
                width="16"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  className="swap-table-filter-container"
                  style={{ width: "98%" }}
                >
                  <div className="filter-components">
                    <SharingPointDropdownSearch
                      {...{
                        setSelectedStation,
                        sp_serial_number,
                      }}
                    />
                  </div>
                  <div className="filter-components">
                    <BatteryTypeDropdownSearch
                      {...{
                        setSelectedBatteryType,
                        battery_type,
                      }}
                    />
                  </div>

                  <div className="filter-components">
                    <Dropdown
                      placeholder="Battery state"
                      fluid
                      clearable
                      selection
                      options={BatteryStateOptions}
                      defaultValue={battery_state}
                      value={battery_state}
                      onChange={(_, { value }) =>
                        setBatteryStateSearch(value ? value : null)
                      }
                    />
                  </div>

                  <div className="filter-components">
                    <Input
                      placeholder="Battery id"
                      onChange={(_, { value }) =>
                        setBatteryId(value ? value : null)
                      }
                      fluid
                      onKeyDown={handleKeyDown}
                      defaultValue={battery_id}
                    />
                  </div>

                  <div className="filter-components">
                    <Input
                      placeholder="Swap id"
                      fluid
                      onChange={(_, { value }) => setSwapOrderId(value)}
                      defaultValue={swap_order_id}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>

                <Button
                  style={{ width: "5%", margin: 5 }}
                  icon="search"
                  size="mini"
                  disabled={isLoading}
                  basic
                  onClick={() => fetchBatteryTracking()}
                />
              </GridColumn>
              <GridColumn>
                <div className="swobbee-battery-tracking-inner-container">
                  <Table sortable style={{ width: "100%" }}>
                    <TableHeader>
                      <TableRow>
                        <TableHeaderCell
                          className="left-header"
                          sorted={
                            state.column === "battery_id"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("battery_id")}
                        >
                          <Popup
                            trigger={<span>Battery ID</span>}
                            content="Battery identification"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "battery_type"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("battery_type")}
                        >
                          <Popup
                            trigger={<span>Type</span>}
                            content="Battery type name"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "swap_order_id"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("swap_order_id")}
                        >
                          <Popup
                            trigger={<span>Swap id in</span>}
                            content="Battery ID was inserted as part of this swap request ID"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "swap_order_id"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("swap_order_id_out")}
                        >
                          <Popup
                            trigger={<span>Swap id out</span>}
                            content="Battery ID was taken out as part of this swap request ID"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "sp_serial_number"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("sp_serial_number")}
                        >
                          <Popup
                            trigger={<span>Sharingpoint</span>}
                            content="Sharingpoint"
                          />
                        </TableHeaderCell>

                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "module_number"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("module_number")}
                        >
                          <Popup
                            trigger={<span>Module</span>}
                            content="Module number"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "slot" ? state.direction : null
                          }
                          onClick={() => sortColumn("slot")}
                        >
                          <Popup
                            trigger={<span>Slot</span>}
                            content="Slot number"
                          />
                        </TableHeaderCell>

                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "battery_in_soc"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("battery_in_soc")}
                        >
                          <Popup
                            trigger={<span>In soc</span>}
                            content="Initial state of charge when battery was inserted "
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "battery_in_at"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("battery_in_at")}
                        >
                          <Popup
                            trigger={<span>In at</span>}
                            content="Battery was inserted at"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "soc_value"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("soc_value")}
                        >
                          <Popup
                            trigger={<span>Soc</span>}
                            content="State of charge"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "battery_out_at"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("battery_out_at")}
                        >
                          <Popup
                            trigger={<span>Out at</span>}
                            content="Battery was taken out at"
                          />
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="inner-header"
                          sorted={
                            state.column === "battery_state"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("battery_state")}
                        >
                          <Popup
                            trigger={<span>State</span>}
                            content="Battery state"
                          />
                        </TableHeaderCell>

                        {window.innerWidth > 1440 && (
                          <>
                            <TableHeaderCell
                              className="inner-header"
                              sorted={
                                state.column === "battery_ready_at"
                                  ? state.direction
                                  : null
                              }
                              onClick={() => sortColumn("battery_ready_at")}
                            >
                              <Popup
                                trigger={<span>Ready at</span>}
                                content="Battery switched to ready state at"
                              />
                            </TableHeaderCell>

                            <TableHeaderCell
                              className={
                                window.innerWidth > 1440
                                  ? "inner-header"
                                  : "right-header"
                              }
                              sorted={
                                state.column === "charging_time_til_ready"
                                  ? state.direction
                                  : null
                              }
                              onClick={() =>
                                sortColumn("charging_time_til_ready")
                              }
                            >
                              <Popup
                                trigger={<span>Charging time</span>}
                                content="The time it took the battery to charge till 85%"
                              />
                            </TableHeaderCell>
                            <TableHeaderCell
                              className="inner-header"
                              sorted={
                                state.column === "created_by"
                                  ? state.direction
                                  : null
                              }
                              onClick={() => sortColumn("created_by")}
                            >
                              <Popup
                                trigger={<span>Inserted by</span>}
                                content="Battery inserted by username"
                              />
                            </TableHeaderCell>
                            <TableHeaderCell
                              className="inner-header"
                              sorted={
                                state.column === "updated_at"
                                  ? state.direction
                                  : null
                              }
                              onClick={() => sortColumn("updated_at")}
                            >
                              <Popup
                                trigger={<span>Updated at</span>}
                                content="Entry was updated at"
                              />
                            </TableHeaderCell>

                            <TableHeaderCell
                              className="inner-header"
                              sorted={
                                state.column === "username_out"
                                  ? state.direction
                                  : null
                              }
                              onClick={() => sortColumn("username_out")}
                            >
                              <Popup
                                trigger={<span>Took out by</span>}
                                content="Battery took out by username"
                              />
                            </TableHeaderCell>
                          </>
                        )}
                        <TableHeaderCell
                          className="right-header"
                          sorted={
                            state.column === "username_out"
                              ? state.direction
                              : null
                          }
                          onClick={() => sortColumn("username_out")}
                        >
                          Actions
                        </TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <Table.Body>
                      {state.data &&
                        state.data.map(
                          (
                            {
                              battery_id,
                              battery_in_at,
                              battery_in_soc,
                              battery_out_at,
                              battery_ready_at,
                              battery_state,
                              module_number,
                              slot,
                              sp_serial_number,
                              swap_order_id,
                              soc_value,
                              battery_type,
                              created_by,
                              username_out,
                              updated_at,
                              charging_time_til_ready,
                              created_at,
                              swap_order_id_out,
                            },
                            index
                          ) => (
                            <TableRow key={index}>
                              <Table.Cell
                                onClick={() => {
                                  navigate();
                                }}
                              >
                                <Link
                                  to={`/batteries/${battery_id}/battery-detail/?battery_type_name=${batteryTypeMapping[battery_type]}`}
                                >
                                  {battery_id}
                                </Link>
                              </Table.Cell>

                              <Table.Cell>
                                {upperFirst(battery_type)}
                              </Table.Cell>
                              <Table.Cell>
                                {swap_order_id &&
                                swap_order_id !== "not_found" ? (
                                  <Link to={`/swaps/${swap_order_id}`}>
                                    {`${swap_order_id.substr(0, 10)}...`}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {(swap_order_id_out && swap_order_id_out) !==
                                "not_found" ? (
                                  <Link to={`/swaps/${swap_order_id_out}`}>
                                    {swap_order_id_out &&
                                      `${swap_order_id_out.substr(0, 10)}...`}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                <Link to={`/sharingpoints/${sp_serial_number}`}>
                                  {sp_serial_number}
                                </Link>
                              </Table.Cell>

                              <Table.Cell>{`${module_number}`}</Table.Cell>
                              <Table.Cell>{slot}</Table.Cell>

                              <Table.Cell>
                                {battery_in_soc ? battery_in_soc + "%" : "-"}
                              </Table.Cell>

                              <Table.Cell>
                                {+battery_in_at
                                  ? timeSpans(+battery_in_at)
                                  : "-"}
                              </Table.Cell>

                              <Table.Cell>
                                {soc_value ? soc_value + "%" : "-"}
                              </Table.Cell>

                              <Table.Cell>
                                {+battery_out_at
                                  ? timeSpans(+battery_out_at)
                                  : "-"}
                              </Table.Cell>
                              <Table.Cell>
                                <Label
                                  content={battery_state}
                                  color={stateColor[battery_state]}
                                  style={{ width: 70 }}
                                  circular
                                  size="mini"
                                />
                              </Table.Cell>

                              {window.innerWidth > 1440 && (
                                <>
                                  <Table.Cell>
                                    {+battery_ready_at
                                      ? timeSpans(+battery_ready_at)
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {+charging_time_til_ready &&
                                    charging_time_til_ready > 0
                                      ? timeDifference(charging_time_til_ready)
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell>{created_by}</Table.Cell>
                                  <Table.Cell>
                                    {+updated_at ? timeSpans(+updated_at) : "-"}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {username_out ? username_out : "-"}
                                  </Table.Cell>
                                </>
                              )}
                              <Table.Cell style={{ overflow: "visible" }}>
                                <BatteryTrackingSettingButton
                                  battery_id={battery_id}
                                />
                              </Table.Cell>
                            </TableRow>
                          )
                        )}
                    </Table.Body>
                  </Table>
                </div>
              </GridColumn>
              <GridColumn textAlign="center" width="16">
                {isLoading ? (
                  <Loader inline active size="large" />
                ) : (
                  <span style={{ color: "#444" }}>
                    {(!state.data || state.data.length === 0) &&
                      "no data available"}
                  </span>
                )}
              </GridColumn>
              {state.data && state.data.length > 0 && (
                <GridColumn textAlign="center" width="16">
                  <Button
                    content={`Load More (${state.data.length}/${state.count})`}
                    basic
                    disabled={state.count === state.data.length}
                    circular
                    loading={isLoadingLoadMore}
                    onClick={() => {
                      loadMore();
                    }}
                  />
                </GridColumn>
              )}
            </Grid>
          </Segment>
        </GridColumn>
      </Grid>
    </Container>
  );
}

const AdvancedSearch = ({
  battery_id,
  battery_state,
  battery_type,
  sp_serial_number,
  swap_order_id,
  module_number,
  battery_in_soc,
  soc_value,
  slot,
  limit,
  isLoading,
  setSelectedStation,
  setSelectedBatteryType,
  selectedUsername,
  setSelectedUsername,
  setBatteryId,
  selectedUserGroupName,
  setSelectedUserGroupName,
  setBatteryStateSearch,
  setSwapOrderId,
  handleKeyDown,
  fetchBatteryTracking,
  setModuleNumber,
  setSlotNumber,
  setSoc,
  setSocIn,
  modalShown,
  setModalShown,
  setLimit,
  resetSearchField,
}) => {
  return (
    <>
      <Button
        icon="search"
        content="Advanced search"
        onClick={() => setModalShown(true)}
        basic
        size="small"
      />
      <Modal
        closeOnEscape
        open={modalShown}
        onClose={() => setModalShown(false)}
        size="mini"
        closeIcon
      >
        <ModalHeader>Advanced search</ModalHeader>
        <ModalContent>
          <Form>
            <FormField>
              <label>Station</label>
              <SharingPointDropdownSearch
                {...{ sp_serial_number, setSelectedStation, width: "100%" }}
              />
            </FormField>

            <FormField>
              <label>Swap id</label>
              <Input
                placeholder="Swap id"
                fluid
                onChange={(_, { value }) => setSwapOrderId(value)}
                defaultValue={swap_order_id}
                onKeyDown={handleKeyDown}
              />
            </FormField>
            <FormGroup widths={"equal"}>
              <FormField>
                <label>Module number</label>
                <Input
                  placeholder="Module number"
                  fluid
                  onChange={(_, { value }) => setModuleNumber(value)}
                  defaultValue={module_number}
                  onKeyDown={handleKeyDown}
                  type={"number"}
                />
              </FormField>

              <FormField>
                <label>Slot number</label>

                <Input
                  placeholder="Slot number"
                  fluid
                  onChange={(_, { value }) => setSlotNumber(value)}
                  defaultValue={slot}
                  onKeyDown={handleKeyDown}
                  type={"number"}
                />
              </FormField>
            </FormGroup>
            <FormGroup widths={"equal"}>
              <FormField>
                <label>Battery id</label>

                <Input
                  placeholder="Battery id"
                  fluid
                  onChange={(_, { value }) => setBatteryId(value)}
                  defaultValue={battery_id}
                  onKeyDown={handleKeyDown}
                />
              </FormField>
              <FormField>
                <label>Battery type</label>

                <BatteryTypeDropdownSearch
                  {...{
                    battery_type,
                    setSelectedBatteryType,
                    width: "100%",
                  }}
                />
              </FormField>
            </FormGroup>

            <FormGroup widths={"equal"}>
              <FormField>
                <label>Battery state</label>

                <Dropdown
                  style={{ marginRight: 14 }}
                  placeholder="Battery state"
                  fluid
                  clearable
                  selection
                  options={BatteryStateOptions}
                  defaultValue={battery_state}
                  value={battery_state}
                  onChange={(_, { value }) => setBatteryStateSearch(value)}
                />
              </FormField>

              <FormField>
                <label> Soc in</label>

                <Input
                  placeholder="Soc in"
                  fluid
                  onChange={(_, { value }) => setSocIn(value)}
                  defaultValue={battery_in_soc}
                  onKeyDown={handleKeyDown}
                  type="number"
                  max={100}
                />
              </FormField>
            </FormGroup>

            <FormGroup widths={"equal"}>
              <FormField>
                <label>Current soc</label>

                <Input
                  placeholder="Current soc"
                  fluid
                  type="number"
                  onChange={(_, { value }) => setSoc(value)}
                  defaultValue={soc_value}
                  onKeyDown={handleKeyDown}
                />
              </FormField>
            </FormGroup>
            <FormField>
              <label>User</label>

              <UsersDropdownSearch
                {...{
                  selectedUsername,
                  setSelectedUsername,
                  width: "100%",
                }}
              />
            </FormField>
            <FormField>
              <label>User group</label>
              <GroupsDropdownSearch
                {...{
                  selectedUserGroupName,
                  setSelectedUserGroupName,
                  width: "100%",
                }}
              />
            </FormField>

            <FormField>
              <label>Limit</label>

              <Input
                placeholder="Limit"
                fluid
                type="number"
                onChange={(_, { value }) => setLimit(value)}
                defaultValue={limit}
                onKeyDown={handleKeyDown}
              />
            </FormField>

            <FormField width="16">
              <Button
                fluid
                icon="search"
                content="search"
                primary
                size="large"
                onClick={() => fetchBatteryTracking()}
                loading={isLoading}
              />
            </FormField>
            <FormField width="16">
              <Button
                fluid
                size="large"
                content="reset"
                onClick={() => resetSearchField()}
              />
            </FormField>
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
};

const AverageTimeModal = ({ batteryChargingStatistic, timeDifference }) => {
  const [averageTimeModalShown, setAverageTimeModalShown] = useState();
  return (
    <>
      <Button
        icon="clock outline"
        content="Average charging time"
        onClick={() => setAverageTimeModalShown(true)}
        basic
        size="small"
      />
      <Modal
        closeIcon={true}
        closeOnEscape
        open={averageTimeModalShown}
        onClose={() => setAverageTimeModalShown(false)}
        dimmer={false}
        size="mini"
      >
        <ModalHeader>Average charging time</ModalHeader>
        <ModalContent style={{ padding: 5 }}>
          {!batteryChargingStatistic && <Loader active inline size="large" />}
          {batteryChargingStatistic && (
            <Table basic>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Battery</Table.HeaderCell>
                  <Table.HeaderCell>Charging time</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.keys(batteryChargingStatistic)
                  .sort()
                  .map((key) => (
                    <>
                      {batteryChargingStatistic[key].average_charging_time && (
                        <Table.Row>
                          <Table.Cell>{upperFirst(key)}</Table.Cell>
                          <Table.Cell>
                            {batteryChargingStatistic[key] &&
                            batteryChargingStatistic[key].average_charging_time
                              ? timeDifference(
                                  batteryChargingStatistic[key]
                                    .average_charging_time
                                )
                              : "-"}
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </>
                  ))}
              </Table.Body>
            </Table>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
