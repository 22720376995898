import { createReducer } from "redux-starter-kit";
import {
    filesByContextHashKey,
    gotUploadUrl,
    resetFiles,
    setContextHashKeyForFiles,
    filesError,
    filesIsFetching
} from "../actions";

const initialState = {
    files: null,
    filesIsFetching: false,
    uploadUrl: null,
    fileHashKey: null,
    contextHashKey: null,
    error: null
}

const filesReducer = createReducer(initialState, {
    [filesByContextHashKey]: (state, action) => {
        return {
            ...state,
            files: action.payload
        }
    },
    [gotUploadUrl]: (state, action) => {
        return {
            ...state,
            uploadUrl: action.payload.uploadUrl,
            fileHashKey: action.payload.fileHashKey
        }
    },
    [setContextHashKeyForFiles]: (state, action) => {
        return {
            ...state,
            contextHashKey: action.payload
        }
    },
    [resetFiles]: () => {
        return {
            ...initialState
        }
    },
    [filesError]: (state, action) => {
        return {
            ...state,
            error: action.payload,
            files: null
        }
    },
    [filesIsFetching]: (state, action) => {
        return {
            ...state,
            filesIsFetching: action.payload
        }
    },

})

export default filesReducer