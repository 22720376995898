import { createReducer } from "redux-starter-kit";
import {
  statisticFetching,
  statistics,
} from "../actions";

const initialState = {
  statistic: null,
  isSaving: false,
  saveSuccess: undefined,
  fetching: false,
  error: null,
  total: 0
};

const statisticsReducer = createReducer(initialState, {
  [statisticFetching]: (state, action) => {
    return {
      ...state,
      fetching: action.payload
    };
  },
  [statistics]: (state, action) => {
    return {
      ...state,
      statistic: action.payload.statistics,
      total: action.payload.statisticsTotal,
      fetching: false
    };
  }
});
export default statisticsReducer;
